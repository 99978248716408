import React from "react";

// Data
import config from "../../../data/config";

// Styles
import Style from "./Terms.module.css";

const Terms = () => {
  return (
    <div className={Style.terms}>
      <strong>Last updated: February 2024</strong>
      <p>
        This is the terms and conditions (“Terms”) between the user (“User”,
        “you” or “your”) and 2access (“2access”, “we”, “us”, or “our”). The
        services are available via Platform http://www.2access.io/ (“Platform”).
        The scope of these Terms is to set out the main terms and conditions of
        the 2access service (“Services”). In addition to the Terms, we have
        certain aspects of our services covered by separate terms and conditions
        that apply to our Users, such as the Privacy and Cookies Policy
        (collectively our “Policies”). These Policies are deemed to be integral
        part of this agreement.
      </p>
      <p>
        Prior to making the User Account available to you, we require you to
        understand and agree on these Terms by clicking “I accept 2access' Terms
        and Conditions” when prompted to do so on our Platform. By agreeing, you
        confirm that you have read, understood, and accepted the respective
        terms of the agreement. The Terms and the agreements within become
        effective from the moment we grant you access to the respective Services
        on the Platform.
      </p>
      <p>
        If you are unwilling to accept the Terms, or if your use of 2access’s
        Services would violate any domestic or international applicable laws,
        you are not authorized to utilize our Platform and the Services therein.
      </p>
      <p>
        2access reserves the right and sole discretion to amend, limit access
        to, or terminate any of these functionalities and Services on the
        Platform, without prior notice to the Users.
      </p>
      <p>
        Our Platform offers Users an option to create an account, which grants
        them access to third-party websites ("Third Party Partner Websites").
        You further acknowledge and understand that when utilizing these Third
        Party Partner Websites within our Platform, you are subject to the terms
        and conditions, privacy policies, and other applicable agreements of
        those Third Party Partner Websites. Your relationship with those Third
        Party Partner Websites is solely governed by their respective terms and
        conditions. We shall not be held responsible or liable for any actions,
        omissions, errors, or issues arising from your use of the Third Party
        Partner Websites’ services.{" "}
      </p>
      <p>
        These Terms may be made available in several languages, nonetheless, in
        the event of any inconsistency between the agreement and the translation
        version, the English version prevails.
      </p>

      <div>
        1. <h5>DEFINITIONS</h5>
      </div>
      <p>
        1.1. “2access” refers to an intermediary platform that operates as a
        unified single sign-on platform (SSO) enabling Users to access
        third-party partner services (“ Third Party Partner Websites”) offered
        on its platform, which encompasses platforms such as Third Party Partner
        Websites.{" "}
      </p>
      <p>
        1.2. “Account” refers to the account created on our 2access Platform.
      </p>
      <p>
        1.3. “Third Party Partner Websites” refers to the website with whom we
        collaborate, where you can utilize your 2access Account credentials to
        access our Services.{" "}
      </p>
      <p>
        1.4. “User” refers to any natural person who has attained the age of
        eighteen (18) with legal capacity and/or any legal entity who
        successfully enters the platform and/or Third Party Partner Websites.{" "}
      </p>

      <div>
        2. <h5>ELIGIBILITY</h5>
      </div>
      <p>
        2.1. User refers to either a natural person who is at least 18 years of
        age, possessing legal capacity and the requisite authority to agree to
        these Terms, or a legal entity with the necessary authorization to enter
        into these Terms.{" "}
      </p>
      <p>
        2.2. Please note that the eligibility requirements for accessing certain
        Third Party Partner Websites may vary, and it is important to be aware
        that some of these Third Party Partner Websites may allow minors to
        access their services with certain conditions to be fulfilled. Users
        should carefully review and comply with the specific terms and
        conditions, age restrictions, and guidelines of each Third Party Partner
        Website they intend to visit. It is the User's responsibility to
        ascertain their eligibility and adhere to any age-related restrictions
        or guidelines as specified by the respective Third-Party Partner
        Website. We do not assume liability for any disparities in eligibility
        criteria among Third Party Partner Websites, and Users are encouraged to
        contact the particular Third Party Partner Website directly for
        clarification on their user acceptance policies and internal regulatory
        policies. Your access to Third Party Partner Websites through our
        Platform is contingent upon your compliance with the respective Third
        Party Partner Website's terms and conditions, as well as any applicable
        laws and regulations.
      </p>
      <p>
        2.3. We reserve the right not to accept any and/or all individuals,
        organizations, groups, and/or countries that have established and/or
        possible connections to predicate offenses and/or sanctions imposed by
        the Office of Foreign Assets Control (OFAC), European Union (EU), United
        Nations Security Council (UNSC), the Organization for Security and
        Cooperation in Europe (OSCE) and other in-country local lists, including
        but not limited to sanctions lists and terrorist lists.
      </p>
      <p>
        2.4. If a User decides to use the platform or third-party websites,
        being geographically located in countries sanctioned by -the Office of
        Foreign Assets Control (OFAC), European Union (EU), United Nations
        Security Council (UNSC), the Organization for Security and Cooperation
        in Europe (OSCE) and other in-country local lists, the User
        automatically becomes obliged to follow all local and international
        regulations, subject to the type of sanctions provided for in the
        respective lists
      </p>

      <div>
        3. <h5>ACCOUNT SETUP</h5>
      </div>
      <p>3.1. Registration; </p>
      <p>
        3.1.1. To create a 2access Account, the registration process on the
        Platform is mandatory. By utilizing our Account services, you affirm and
        assure that you will employ the account strictly for your own use and/or
        for the use of your legal entity. You commit not to employ the Account
        on behalf of any third party unless explicit legal authorization has
        been granted by us beforehand.
      </p>
      <p>
        3.1.2. Upon registration with 2access, you will be conducted to a
        specified verification and validation process designed to facilitate all
        members internal onboarding procedures. As part of this process, you
        must create a robust password, preserve it from any unauthorized access,
        and consent to abide by these specified Terms, the Privacy Policy, and
        any additional regulations stipulated by the 2access.{" "}
      </p>
      <p>
        3.1.3. The User shall be eligible to open and maintain only one Account
        on our Platform. Duplicate or multiple Accounts created by the same User
        may be subject to suspension, termination, or other appropriate actions
        at our discretion, and any associated benefits or privileges may be
        forfeited. It is the User's responsibility to ensure the proper
        management and use of their single Account in accordance with our Terms.
      </p>
      <p>
        3.1.4. Multiple accounts are strictly prohibited. The creation and
        management of sub- accounts and account mergers are permitted under
        exceptional circumstances upon request and prior approval, such as in
        the case of minors and dependents related to the holder of a main
        account and duplicate accounts.
      </p>
      <p>
        3.1.5. The right to maintain any sub-account is subject to the terms and
        conditions described in the present document. Misuse of a sub-account
        can lead to the User losing access to the main account and all other
        sub-accounts and the misuse of the main account can lead to the user
        losing access to all the sub-accounts.
      </p>
      <p>
        3.1.6. A minor User holding a sub-account is allowed to hold a main
        account once she/he reaches the age of majority according to applicable
        laws and regulations where the minor resides. The transition from a
        sub-account to a main account is subject to a written request from the
        interested User, and validation of involving legal documents to prove
        the applicable fees at the moment of the transition from a sub-account
        to a main account.
      </p>
      <p>
        3.1.7. Users agree and acknowledge that a two-factor authentication
        (2FA) system is available on our Platform to enhance the security of
        User accounts. Users are encouraged to enable and use this 2FA feature
        to add an extra layer of protection to their accounts and personal
        information. By utilizing 2FA, Users can further safeguard their access
        to the Platform and reduce the risk of unauthorized access or security
        breaches.
      </p>
      <p>
        3.1.8. Users are required to create the 2access account using a referral
        link. As such, only one sponsor is allowed for each referee. Moreover,
        modifications or changes of sponsors are not allowed, which is to say
        that referees are not allowed to change their sponsors, otherwise they
        shall be at risk of losing earnings accumulated through the Third-Party
        Partner Websites.
      </p>
      <p>
        3.1.9. 2access has implemented a User onboarding and AML Screening
        system (“KYC Screening”) across different stages during the members
        engagement with the Platform and Third Party Partner Websites. All these
        systems are designed to facilitate the process of User registration,
        validations, and identity verification, while preserving Users and their
        assets from potential unauthorized access. In order to conduct identity
        verifications, the Platform may request Users to complete the following
        KYC process:<p>3.1.9.1.</p> Account Creation & Registration{" "}
        <p>3.1.9.2.</p> Document Verification <p>3.1.9.3.</p> Identity
        Verification <p>3.1.9.4.</p> Liveness check and Face Match{" "}
        <p>3.1.9.5.</p> Email Verification
      </p>
      <p>
        3.1.10. In providing us with the above-mentioned information, you
        represent and warrant that the information is accurate and authentic.
        Further, you agree to keep us updated if any of the information you
        provide changes. If there are any grounds for believing that any of the
        information you provided is incorrect, false, outdated, or incomplete,
        we reserve the right to send you a letter of demand for corrections,
        suspend, or terminate all/part of the Services we provide you..
      </p>
      <p>
        3.1.11. If 2access is unable to reach you with the contact information
        you provided, you shall be fully liable for any loss or expense caused
        to us during your use of our Services, and any disadvantage you could
        bear due to the impossibility to contact you through the provided
        contact information.
      </p>
      <p>
        3.1.12. By agreeing to these Terms, you provide consent for us to
        conduct inquiries, either directly or through third parties, as we may
        deem necessary to verify your identity or to safeguard both you and us
        against fraud and related crimes, which encompass identity theft, wire
        fraud, and forgery. Based on the outcomes of such inquiries, we reserve
        the right to take appropriate actions that we reasonably consider
        essential. Additionally, you explicitly acknowledge and consent that
        your personal information may be disclosed to governmental institutions
        or authorities if required.
      </p>
      <p>
        3.1.13. n order to ensure compliance with applicable anti-money
        laundering (AML) and counter-terrorism financing (CTF) regulations, and
        to effectively manage and mitigate potential risks associated with
        users, including but not limited to money laundering, terrorist
        financing, and adherence to sanctions and -Special Designated Nationals
        (SDN) lists, the User hereby acknowledges and agrees that the
        Third-Party Partner Websites reserve the right to conduct separate Know
        Your Customer (KYC) screenings.
      </p>
      <p>
        3.1.14. The User further acknowledges that any information collected and
        processed during the separate KYC screenings will be treated in
        accordance with the Third-Party Partner Websites' privacy policy and
        applicable data protection laws on them.
      </p>

      <div>
        4. <h5>USER OBLIGATIONS</h5>
      </div>
      <p>
        4.1. You agree to use our Services only for lawful purposes and in
        compliance with these Terms.
      </p>
      <p>
        4.2. You shall refrain from engaging in any activity that may disrupt or
        harm the Platform or its Users. This includes but does not limit to
        hacking, spamming, or engaging in any form of unauthorized access or
        use.{" "}
      </p>
      <p>
        4.3. You are obligated not to reproduce, modify, or distribute the
        materials and information available on the Platform.{" "}
      </p>
      <p>
        4.4. You further agree and acknowledge that you will be granted an SSO
        login credential, facilitating access to other Third-Party Partner
        Websites and your eligibility to engage in any services, activities, or
        offerings provided by the Third-Party Partner Websites is entirely and
        solely at the discretion of each respective Third-Party Partner Website.
        You acknowledge that the level of access, privileges, and services
        available on the Third-Party Partner Websites may vary and can be
        subject to additional terms, conditions, and criteria set forth by each
        individual Third-Party Partner Website. You further understand that any
        decisions related to granting or revoking access to services on the
        Partner Websites are beyond the control and authority of the 2access
        platform. You hereby agree to abide by the terms and conditions set
        forth by both 2access and each Third-Party Partner Website, and
        acknowledge that any disputes, concerns, or inquiries related to
        services provided by the Third-Party Partner Websites should be directed
        to the respective Third-Party Partner Website's customer support or
        designated point of contact. 2access shall not be held liable for any
        denial of service, restriction of access, or changes in services made by
        the Third-Party Partner Websites, as these actions are at the sole
        discretion of the respective Third-Party Partner Websites.{" "}
      </p>
      <p>
        4.5. The User hereby agrees and acknowledges that Third-Party Partner
        Websites may impose their own restrictions and requirements for
        onboarding Users in accordance with their internal policies. 2access
        will solely provide login credentials, and Third-Party Partner Websites
        retain the discretion to onboard Users and deliver services in
        accordance with their respective terms and conditions.
      </p>
      <p>
        4.6. The User hereby acknowledges and agrees that they shall not, under
        any circumstances, engage in the direct or indirect purchase of Accounts
        belonging to other Users on the Platform. Violation of this clause shall
        constitute a material breach of these Terms and may result in the
        immediate termination of the User's access to the Platform.
      </p>
      <p>
        4.7. By creating an Account with 2access, the User acknowledges and
        agrees that certain personal information provided during the Account
        creation process may be shared with Third-Party Partner Websites and
        third-party entities as deemed necessary by the 2access. The personal
        information shared with Third-Party Partner Websites and third-party
        entities may be utilized for various purposes, including but not limited
        to (i) User Experience Enhancement, (ii) Marketing and Promotions, (iii)
        Security and Verification, (iv) Compliance, (v) Business Operations. By
        creating an Account with the 2access, Users explicitly agree to the
        sharing of their personal information with Third-Party Partner Websites
        and third-party entities as described in this clause.
      </p>

      <div>
        5. <h5>RISK DISCLOSURE</h5>
      </div>
      <p>
        5.1. Third-party Services: The Website acts as an intermediary, offering
        for various services provided by third-party partners (“Third-Party
        Partner Websites”). We do not directly control the services offered by
        these Third-Party Partner Websites, and any issues or risks related to
        the services must be resolved with the respective partners.
      </p>
      <p>
        5.2. Service Availability: The availability of specific services offered
        through is subject to change based on the discretion of the Third-Party
        Partner Websites. 2access has no control over and cannot guarantee the
        availability of specific services provided by the Partner Websites.
      </p>
      <p>
        5.3. Data Security and Privacy: Sharing login credentials across
        different Third-Party Partner Websites involves transmitting your
        authentication details from 2access to the respective Third-Party
        Partner Websites. Although we implement security measures to protect
        your information, using shared credentials introduces a potential risk
        of unauthorized access if there is a security breach or vulnerability in
        any of the interconnected systems.
      </p>
      <p>
        5.4. User Behavior Monitoring: The shared login functionality enables
        Third-Party Partner Websites to track your activity across multiple
        platforms using the same credentials. While this can streamline your
        experience, it also means that your behavior and interactions across
        different Websites can be correlated, potentially impacting your
        privacy.
      </p>
      <p>
        5.5. Third-Party Policies: Each Third-Party Partner Website may have its
        own terms of use, privacy policy, and security practices. By using
        shared login credentials, you acknowledge that you are subject to the
        policies and practices of both 2access and the Third-Party Partner
        Websites. It's essential to review and understand these policies before
        using the shared login feature.
      </p>
      <p>
        5.6. Account Vulnerability: If your 2access credentials are compromised,
        it could potentially grant unauthorized access to all the Third-Party
        Partner Websites connected to your account. This emphasizes the
        importance of safeguarding your login information and regularly updating
        your password. Users are recommended that you take appropriate
        precautions and make informed decisions based on your understanding of
        the shared login functionality.
      </p>
      <p>
        5.7. Our Services are not intended to provide financial, investment or
        legal advice. The information presented in our Platform Terms and
        Conditions, Privacy & Cookie Policy and this Disclosure should not be
        considered a financial or investment recommendation to use any
        Third-Party Partner Website available on our Platform. Users shall
        consult with qualified professionals before making any decisions based
        on the information received through our Platform.
      </p>
      <p>
        5.8. By accessing or using our Platform, You are voluntarily choosing to
        avail of our Services. Nonetheless, certain issues and risks are
        unavoidable, and if such issues or problems arise in connection with
        your use of our Platform, including technical difficulties which may
        take days, weeks, or months to resolve, and some issues may not be
        resolved at all.
      </p>
      <p>
        5.9. We disclaim any liability for damage of any kind caused by data
        losses on computer servers, hacking, cyber security breaches and/or any
        other types of losses. You further acknowledge that we are not
        responsible for any of these variables or risks, and cannot be held
        liable for any resulting losses that you experience while accessing or
        using the Website. Accordingly, You understand and agree to assume full
        responsibility for all of the risks of accessing and using our Website.
      </p>

      <div>
        6. <h5>INTELLECTUAL PROPERTY</h5>
      </div>
      <p>
        6.1. 2access' Intellectual Property: All intellectual property (IP)
        rights, including but not limited to patents, copyrights, trademarks,
        trade secrets, and any other proprietary rights, associated with the
        2access platform, registered or not registered, its technology,
        software, content, designs, logos, and any related materials
        (collectively referred to as "2access IP"), are and shall remain the
        sole and exclusive property of 2access. Users acknowledge and agree that
        their use of the 2access platform does not grant them any rights,
        ownership, or license to the 2access IP. Users shall refrain from
        reproducing, distributing, modifying, or creating derivative works based
        on the 2access IP without explicit written consent from 2access.
      </p>
      <p>
        6.2. Third-Party Partner Websites' Intellectual Property: Each
        Third-Party Partner Website connected through the 2access platform
        operates under its own terms and conditions, including its own
        intellectual property policies. Users acknowledge and agree that any
        content, technology, trademarks, logos, and other intellectual property
        associated with the Third-Party Partner Websites ("Partner IP") are
        owned by their respective Third-Party Partner Websites..
      </p>
      <p>
        6.3. Users shall adhere to the intellectual property policies and terms
        of use of each Third-Party Partner Website when interacting with their
        content and services. Any use of Partner IP outside the scope of these
        terms may result in action being taken by the respective Third- Party
        Partner Websites.
      </p>
      <p>
        6.4. Users shall not use, reproduce, distribute, modify, create
        derivative works from, or engage in any activity that infringes upon the
        intellectual property rights of 2access, its Third-Party Partner
        Websites, or any third parties. Unauthorized use of intellectual
        property is strictly prohibited and may lead to legal action.
      </p>
      <p>
        6.5. User-Generated Content: Any content or materials uploaded,
        submitted, or generated by Users on our platform are subject to these
        Terms. By submitting such content, Users grant 2access a non-exclusive,
        royalty-free, worldwide, perpetual, and irrevocable license to use,
        reproduce, modify, adapt, publish, translate, create derivative works
        from, distribute, and display such content in any form, media, or
        technology.
      </p>

      <div>
        7. <h5>INDEMNIFICATION</h5>
      </div>
      <p>
        7.1. Users ("Indemnifying Party") of the 2access platform agree to
        indemnify, defend, and hold harmless 2access and its officers,
        directors, employees, agents, subsidiaries, affiliates, and partners
        ("Indemnified Parties") from and against any claims, demands, actions,
        liabilities, damages, costs, and expenses, including but not limited to
        reasonable attorneys' fees, arising out of or related to:
      </p>
      <p>
        7.1.1. Breach of Terms: Any violation of these Terms, including but not
        limited to the intellectual property clause, by the Indemnifying Party.
      </p>
      <p>
        7.1.2. Intellectual Property Claims: Any claims or actions brought
        against the Indemnified Parties by third parties alleging infringement
        of their intellectual property rights arising from the content
        submitted, uploaded, or generated by the Indemnifying Party on the
        2access platform.
      </p>
      <p>
        7.1.3. Unauthorized Use: Any unauthorized use of 2access IP and Partner
        IP by the Indemnifying Party.
      </p>
      <p>
        7.1.4. User-Generated Content: Any claims or disputes arising from the
        content, materials, or information submitted, uploaded, or generated by
        the Indemnifying Party on our platform.
      </p>
      <p>
        7.1.5. Negligence or Misconduct: Any negligent or willful misconduct,
        violation of law, or breach of any rights by the Indemnifying Party in
        connection with their use of our Platform and Services.
      </p>
      <p>
        7.2. This indemnification clause shall survive the termination of the
        User's account and the use of the 2access Platform. The Indemnifying
        Party acknowledges and accepts that their obligation to indemnify the
        Indemnified Parties is a material condition of their use of the
        Platform.
      </p>

      <div>
        8. <h5>LIMITATION OF LIABILITY</h5>
      </div>
      <p>
        8.1. Non-Guarantee: 2access does not warrant or guarantee the accuracy,
        reliability, or completeness of the services offered by the Third-Party
        Partner Websites. Users acknowledge that they access Third-Party Partner
        Websites at their own risk using 2access Account.
      </p>
      <p>
        8.2. Service Quality: 2access does not assume liability for the quality
        of services provided by the Third-Party Partner Websites. Any disputes
        or issues arising from the services must be addressed directly with the
        respective partners.
      </p>
      <p>
        8.3. Third-Party Websites: The Platform may contain links to third-party
        websites. We are not responsible for the content, privacy policies, or
        practices of these external sites, and Users access them at their own
        risk.
      </p>
      <p>
        8.4. Technical Issues: While 2access strives to maintain a secure and
        error-free Platform, we cannot guarantee that the Platform will be
        continuously available, free from bugs, or completely secure. Users
        acknowledge that they use the Platform at their own risk.
      </p>
      <p>
        8.5. Indirect Damages: The 2access and its affiliates shall not be
        liable for any indirect, consequential, or incidental damages arising
        from the use or inability to use the Platform, even if informed of the
        possibility of such damages.
      </p>

      <div>
        9. <h5>MODIFICATIONS</h5>
      </div>
      <p>
        9.1. We are continuously working to develop and enhance our Platform and
        Services.{" "}
      </p>
      <p>
        9.2. We reserve ourselves the discretion at any time without notice to
        amend, limit access to, or terminate any and/or all of the
        functionalities and Services on the Platform.
      </p>
      <p>
        9.3. We reserve the right to unilaterally, at any time without notice,
        amend or update these Terms. Your continued use of the Services after
        any such modifications shall constitute your acceptance of the modified
        Terms.
      </p>

      <div>
        10. <h5>TERMINATION</h5>
      </div>
      <p>
        10.1. We may terminate or suspend your access to our Services at any
        time, with or without cause, without prior notice or liability.
      </p>

      <div>
        11. <h5>NO THIRD-PARTY RIGHTS</h5>
      </div>
      <p>
        11.1. The Services provided by our Platform are intended solely for the
        benefit of the Users. No third parties, including but not limited to
        individuals, organizations, or entities, have any rights or claims to
        the Services, unless expressly stated. Users are strictly prohibited
        from transferring or assigning their rights to any third party without
        prior written consent from us. Any unauthorized attempt to confer
        third-party rights to the Services may result in the immediate
        suspension or termination of your {config.PLATFORM_NAME} account and may
        lead to legal actions as per applicable laws and regulations.
      </p>

      <div>
        12. <h5>FORCE MAJEURE</h5>
      </div>
      <p>
        12.1. We are not liable to you if we fail to comply with these Terms
        because of circumstances beyond our reasonable control including but not
        limited to the provisions set forth in under this clause and the clauses
        established in connection with it.
      </p>
      <p>
        12.2. In no event shall 2access be liable for any inaccuracy, error,
        delay or omission, failure in performances or interruption of Services
        of any transmission or delivery of information as in required by these
        Terms or caused by, directly or indirectly, forces beyond its reasonable
        control of 2access including, action of government, legal developments,
        act of terrorists, war, fire, interruptions in telecommunications or
        internet services or network provider services, software malfunctions,
        forks and any network-wide compromises, hacking, strikes, work
        stoppages, accidents, civil or military disturbances or other
        catastrophe or any other causes beyond 2access’ reasonable control.{" "}
      </p>

      <div>
        13. <h5>DISPUTE RESOLUTION AND GOVERNING LAW</h5>
      </div>
      <p>
        13.1. Parties to these terms agree to resolve any disputes promptly and
        in an efficient manner within thirty (30) days after the dispute arises.
        In case of failure to reach a solution in an amicable manner, a dispute
        resolution will be exclusively governed under the laws of British Virgin
        Islands (BVI) and the relevant Terms and Conditions in this document.{" "}
      </p>

      <div>
        14. <h5>CONTACT US </h5>
      </div>
      <p>
        14.1. In the event of any comments, questions, inquiries or complaints
        regarding the Platform or these Terms, Users can submit them in a
        written form to{" "}
        <a href="mailto:support@2access.io.">support@2access.io.</a>
      </p>
    </div>
  );
};

export default Terms;
