import { createContext } from "react";

const emptyDictProxy = new Proxy(
    {},
    {
        get: () => emptyDictProxy,
    }
);

export const RoutesContext = createContext({
    paths: emptyDictProxy,
    sidebarRoutes: emptyDictProxy,
});
