import React, { useEffect, useContext } from 'react';

// Components
// todo: loader and create loadre for allo pages

// Libraries
import { useNavigate } from 'react-router-dom';
import { PermissionRoute } from '@hybris-software/use-auth';

// Utils
import dispatcher from '../../utils/dispatcher';

// Hooks
import useText from '../../hooks/useText';

// Data
import config from '../../data/config';

// Contexts
import { RoutesContext } from '../../contexts/RoutesContext';

const Redirect = () => {
	// Hooks
	const navigate = useNavigate();
	// Contexts
	const { paths } = useContext(RoutesContext);

	return (
		<PermissionRoute
			// loader={<LoaderAuth />}
			forLoggedUser={true}
			unAuthorizedAction={() => {
				navigate(paths.auth.login);
			}}
			permissionController={(response) => {
				dispatcher(response.data, navigate, paths);
				return true;
			}}
		>
			<RedirectHome />
		</PermissionRoute>
	);
};

const RedirectHome = () => {
	// Hooks
	const texts = useText('redirect');
	const navigate = useNavigate();
	useEffect(() => {
		document.title = `${config.PLATFORM_NAME} | ${texts?.pageTitle}`;
		navigate('/');
		//eslint-disable-next-line
	}, []);
	return <></>;
};
export default Redirect;
