import React from 'react'

// Components
import LogoutModal from '../logoutModal/LogoutModal'

// Images
import Exit from '../../../assets/images/exit.svg'

// Styles
import Style from './Logout.module.css'

const Logout = ({ logoutRef, type }) => {

    return (
        <div className={type === "mobile" ? Style.logoutMobile : Style.logout}>
            <div className={Style.iconBox} onClick={() => logoutRef?.current?.updateBody(
                <LogoutModal modalRef={logoutRef} />
            )}>
                <img as="image" rel="preload" src={Exit} alt="" />
            </div>
        </div>
    )
}


export default Logout
