import React, { useEffect, useState, useContext } from "react";

// Libraries
import { Button } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";

// Icons
import { IoCloseSharp } from "react-icons/io5";
import { MdOutlineMoreTime } from "react-icons/md";
import { TbReload } from "react-icons/tb";

// Hooks
import useTypeNotification from "../../../hooks/useTypeNotification";
import useText from "../../../hooks/useText";

// Data
import endpoints from "../../../data/endpoints";

// Contexts
import NotificationContext from "../../../contexts/NotificationContext";

// Styles
import Style from "./NotificationsList.module.css";
import LoaderElement from "../../ui/loaderElement/LoaderElement";

const NotificationsList = ({
  openMobile,
  openNotification,
  setOpenNotification,
  allNotifications,
  setUrl,
  links,
  loading,
  setAllNotificationsList,
}) => {
  // Hooks
  const texts = useText("notificationsList");
  // States
  const [menuOpen, setMenuOpen] = useState("new");
  // Contexts
  const [notificationsList, setNotificationsList] =
    useContext(NotificationContext);

  const getNotificationsApi = useQuery({
    url: endpoints.notifications.GETNOTIFICATIONS,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      setUrl(endpoints.notifications.GETNOTIFICATIONS);
      setAllNotificationsList(response.data.results);
    },
  });

  useEffect(() => {
    if (openNotification || openMobile) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "overlay";
  }, [openNotification, openMobile]);

  return (
    <div className={openNotification ? Style.listBox : Style.listBoxClosed}>
      <div className={Style.header}>
        <div>
          <h5 className={Style.title}>{texts?.title}</h5>
        </div>
        <div
          className={Style.close}
          onClick={() => {
            setMenuOpen("new");
            setOpenNotification(false);
          }}
        >
          <IoCloseSharp />
        </div>
      </div>

      <div className={Style.menu}>
        <div
          onClick={() => {
            setMenuOpen("new");
          }}
          className={menuOpen === "new" ? Style.selected : undefined}
        >
          {texts?.newNotifications}
        </div>
        <div
          onClick={() => {
            setMenuOpen("all");
            getNotificationsApi.executeQuery();
          }}
          className={menuOpen === "all" ? Style.selected : undefined}
        >
          {texts?.allNotifications}
        </div>
      </div>

      <div className={Style.list}>
        {getNotificationsApi.isLoading ? (
          <div className={Style.loader}>
            <LoaderElement />
          </div>
        ) : (
          <>
            {menuOpen === "new" ? (
              notificationsList.length > 0 ? (
                notificationsList?.map((item, index) => {
                  return (
                    <NotificationItem
                      key={index}
                      {...item}
                      deleting={true}
                      setNotificationsList={setNotificationsList}
                    />
                  );
                })
              ) : (
                <div className={Style.empty}>{texts?.empty}</div>
              )
            ) : allNotifications.length > 0 ? (
              allNotifications?.map((item, index) => {
                return <NotificationItem key={index} {...item} />;
              })
            ) : (
              <div className={Style.empty}>{texts?.empty}</div>
            )}
          </>
        )}
      </div>
      {menuOpen === "all" && (
        <div className={Style.buttons}>
          <Button
            className={Style.loadMore}
            isLoading={loading}
            onClick={() => {
              if (links?.next)
                setUrl(links?.next.replace("http://", "https://"));
            }}
          >
            {links?.next && (
              <>
                <MdOutlineMoreTime />
                {texts?.loadMore}
              </>
            )}
          </Button>
          <Button
            className={Style.reload}
            isLoading={getNotificationsApi.isLoading}
            onClick={() => {
              getNotificationsApi.executeQuery();
            }}
          >
            <TbReload />
            {texts?.reload}
          </Button>
        </div>
      )}
    </div>
  );
};

const NotificationItem = ({
  type,
  createdAt,
  created_at,
  deleting,
  id,
  setNotificationsList,
}) => {
  const texts = useText("notificationsList");
  const { notificationTypes, notificationTypesTexts, notificationTime } =
    useTypeNotification();

  const deleteNotification = useQuery({
    url: `${endpoints.notifications.GETNOTIFICATIONS}${id}/read/`,
    method: "POST",
  });

  return (
    <div className={Style.item} id={id}>
      <div className={Style.type}>
        <div className={Style.icon}>
          <img
            as="image"
            rel="preload"
            src={notificationTypes(type)}
            alt=""
            width={35}
          />
        </div>
      </div>
      <div className={Style.text}>
        {notificationTypesTexts(type)}
        <div className={Style.time}>
          {notificationTime(createdAt || created_at)}
          {deleting && (
            <div
              className={Style.delete}
              onClick={(e) => {
                deleteNotification.executeQuery();
                document.getElementById(id).classList.add(Style.deleting);
                setTimeout(() => {
                  document.getElementById(id).classList.remove(Style.deleting);
                }, 150);
                setTimeout(() => {
                  setNotificationsList((old) => {
                    return old.filter((el) => id !== el.id);
                  });
                }, 150);
              }}
            >
              {texts?.markAsRead}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationsList;
