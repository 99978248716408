import PasswordValidator from "../components/advanced/passwordValidator/PasswordValidator";

const validatePassword = (password) => {

    let values = {
        upper: false,
        lower: false,
        number: false,
        special: false,
    };

    if (password.length > 7 && password.match(/[a-z]/i)) values.lower = true;
    if (password.match(/[A-Z]/)) values.upper = true;
    if (password.match(/[0-9]/)) values.number = true;
    if (password.match(/[^a-zA-Z0-9]/g)) values.special = true;


    if (password.length <= 7 || !password.match(/[a-z]/i)) {
        return [
            false,
            {
                message: <PasswordValidator status={values} />,
            },
        ];
    } else if (!password.match(/[0-9]/)) {
        return [
            false,
            {
                message: <PasswordValidator status={values} />,
            },
        ];
    } else if (!password.match(/[A-Z]/)) {
        return [
            false,
            {
                message: <PasswordValidator status={values} />,
            },
        ];
    } else if (!password.match(/[^a-zA-Z0-9]/g)) {
        return [
            false,
            {
                message: <PasswordValidator status={values} />,
            },
        ];
    } else {
        return [
            true,
            {
                message: <PasswordValidator status={values} />,
            },
        ];
    }
};

export default validatePassword;
