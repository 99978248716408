import React from 'react';

// Icon
import { FaRegCopy } from 'react-icons/fa';

// Utils
import copyToClipboard from '../../../utils/copyToClipboard';

// Styles
import Style from './Tooltip.module.css';

/**
 * @param {string} valueToCopy - Value to copy
 * @param {string} className - Class name for the main position
 * @param {string} copyIcon - Icon to show
 * @param {string} iconClassName - Class name for the icon div
 * @param {string} tooltipClassName - Class name for the tooltip div (Add position: absolute and ::after if you want a tooltip with arrow)
 * @param {string} textCopy - Text to show when copy
 * @param {number} timeForHide - Time to hide the tooltip
 */

const Tooltip = ({
	valueToCopy = 'Pass valueToCopy props to copy',
	className = Style.box,
	copyIcon = <FaRegCopy />,
	iconClassName = Style.icon,
	tooltipClassName = Style.tooltip,
	textCopy = 'Copied',
	timeForHide = 1000,
}) => {
	return (
		<div className={className}>
			<div
				style={{ position: 'relative' }}
				className={iconClassName}
				title='Copy'
				onClick={(e) => {
					copyToClipboard(valueToCopy);
					const el = e.currentTarget.children[1];
					el.style.opacity = 1;
					el.style.visibility = 'visible';
					setTimeout(() => {
						el.style.opacity = 0;
						el.style.visibility = 'hidden';
					}, timeForHide);
				}}
			>
				{copyIcon}
				<div className={tooltipClassName}>{textCopy}</div>
			</div>
		</div>
	);
};

export default Tooltip;
