import React, { useState, useEffect } from 'react';

// Components
import SendCodeAgain from '../sendCodeAgain/SendCodeAgain';
import StatusResponse from '../statusResponse/StatusResponse';
import ErrorModal from '../errorModal/ErrorModal';

// Libraries
import { Button } from '@hybris-software/ui-kit';
import AuthCode from 'react-auth-code-input';
import useQuery from '@hybris-software/use-query';
import { useOutletContext } from 'react-router-dom';
import { useUser } from '@hybris-software/use-auth';

// Images
import Email from '../../../assets/images/email.png';
import Authenticor from '../../../assets/images/authenticor.svg';
import Success from '../../../assets/images/success.webp';

// Data
import endpoints from '../../../data/endpoints';

// Hooks
import useText from '../../../hooks/useText';

// Styles
import Style from './DoubleTwoFactor.module.css';

const DoubleTwoFactor = ({
	otpMethod,
	modalRef,
	errorCall,
	newEmail,
	setEditable,
	onClose = () => {},
}) => {
	// Hooks
	const texts = useText('doubleTwoFactor');
	const successTexts = useText('success');
	const { refreshUserInfo } = useUser();

	// States
	const [emailCode, setEmailCode] = useState('');
	const [phoneCode, setPhoneCode] = useState('');

	// Contexts
	const { errorModalRef, unauthorizedModalRef } = useOutletContext();

	const confirmChangeEmailApi = useQuery({
		url: endpoints.profile.CONFIRMEMAILCHANGE,
		method: 'POST',
		onSuccess: (response) => {
			setEditable(false);
			onClose();
			modalRef.current.updateBody(
				<StatusResponse
					icon={Success}
					title={successTexts?.emailTitle}
					description={successTexts?.emailSubtitle}
				>
					<Button
						style={{
							borderRadius: '30px',
							width: '100%',
							maxWidth: '200px',
							marginBottom: '10px',
						}}
						onClick={() => {
							refreshUserInfo();
							modalRef.current.destroy();
						}}
					>
						{successTexts?.buttonClose}
					</Button>
				</StatusResponse>,
				{
					onCloseIconClick: () => {
						refreshUserInfo();
						setEditable(false);
					},
				}
			);
		},
		onError: (error) => {
			if (error.response?.status === 422) {
				return;
			} else {
				errorModalRef.current.updateBody(
					<ErrorModal error={error} modalRef={errorModalRef} />
				);
			}
		},

		onUnauthorized: () => {
			unauthorizedModalRef.current.open();
		},
	});

	const resendOtpApi = useQuery({
		url: endpoints.otp.RESENDOTP,
		method: 'POST',
	});

	const apiCalls = {
		changeEmail: confirmChangeEmailApi,
	};

	useEffect(() => {
		if (phoneCode.length === 6 && emailCode.length === 6) {
			apiCalls[errorCall].executeQuery({
				otpOldEmail: phoneCode,
				otpNewEmail: emailCode,
			});
		}
		// eslint-disable-next-line
	}, [phoneCode, emailCode]);
	return (
		<div className={Style.doubleFactor}>
			<div className={Style.email}>
				<img
					as='image'
					rel='preload'
					src={otpMethod === 'EMAIL' ? Email : Authenticor}
					alt=''
				/>
				<h5 className={Style.title}>{texts?.verifyIdentity}</h5>
				<p className={Style.info}>
					{texts?.checkEmail} {newEmail}
				</p>

				<div className={Style.form}>
					<AuthCode
						allowedCharacters='numeric'
						onChange={(e) => {
							setEmailCode(e);
						}}
					/>
					<div className={Style.authCodeErrorMessage}>
						{apiCalls[errorCall].isError &&
							apiCalls[errorCall].error?.response?.status ===
								422 &&
							apiCalls[errorCall].error?.response?.data
								?.otpNewEmail && (
								<span>
									{
										apiCalls[errorCall].error?.response
											?.data?.otpNewEmail
									}
								</span>
							)}
					</div>
				</div>
				<SendCodeAgain
					counter={15}
					onClick={() =>
						resendOtpApi.executeQuery({ otpAction: 'change_email' })
					}
				/>
			</div>

			<div className={Style.authenticator}>
				{otpMethod === 'EMAIL' ? (
					<></>
				) : (
					<>
						<p className={Style.info}>
							{texts?.verifyAuth}{' '}
							<strong>{texts?.nameAuth}</strong>{' '}
							{texts?.verifyAuthCode}
						</p>
						<div className={Style.form}>
							<AuthCode
								autoFocus={false}
								allowedCharacters='numeric'
								onChange={(e) => {
									setPhoneCode(e);
								}}
							/>
							<div className={Style.authCodeErrorMessage}>
								{apiCalls[errorCall].isError &&
									apiCalls[errorCall].error?.response
										?.status === 422 &&
									apiCalls[errorCall].error?.response?.data
										?.otpOldEmail && (
										<span>
											{
												apiCalls[errorCall].error
													?.response?.data
													?.otpOldEmail
											}
										</span>
									)}
							</div>
						</div>
					</>
				)}
			</div>

			<Button
				style={
					otpMethod === 'EMAIL'
						? { marginTop: '30px' }
						: { marginTop: '0' }
				}
				className={Style.verify}
				disabled={emailCode.length !== 6 || phoneCode.length !== 6}
				isLoading={apiCalls[errorCall].isLoading}
				onClick={() => {
					apiCalls[errorCall].executeQuery({
						otpOldEmail: emailCode,
						otpNewEmail: phoneCode,
					});
				}}
			>
				{texts?.button}
			</Button>
		</div>
	);
};

export default DoubleTwoFactor;
