import React from 'react'

const Copy = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <g clipPath="url(#clip0_182_238)">
                <path d="M10.6666 1.1665H2.66659C1.92992 1.1665 1.33325 1.76317 1.33325 2.49984V11.8332H2.66659V2.49984H10.6666V1.1665ZM12.6666 3.83317H5.33325C4.59659 3.83317 3.99992 4.42984 3.99992 5.1665V14.4998C3.99992 15.2365 4.59659 15.8332 5.33325 15.8332H12.6666C13.4033 15.8332 13.9999 15.2365 13.9999 14.4998V5.1665C13.9999 4.42984 13.4033 3.83317 12.6666 3.83317ZM12.6666 14.4998H5.33325V5.1665H12.6666V14.4998Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_182_238">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Copy