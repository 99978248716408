import { useCallback, useState, useEffect, useRef } from "react";

function useInterval(callback, delay) {
    const savedCallback = useRef(callback);

    // Remember the latest callback if it changes.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        // Don't schedule if no delay is specified.
        // Note: 0 is a valid value for delay.
        if (!delay && delay !== 0) {
            return;
        }

        const id = setInterval(() => savedCallback.current(), delay);

        return () => clearInterval(id);
    }, [delay]);
}

const useCountdown = (initialCount) => {
    const [count, setCount] = useState(initialCount);

    const resetCountdown = () => {
        setCount(initialCount);
    };

    const setCounter = (newCount) => {
        setCount(newCount);
    };

    const countdownCallback = useCallback(() => {
        setCount((count) => count - 1);
        // eslint-disable-next-line
    }, [count]);

    useInterval(countdownCallback, count > 0 ? 1000 : null);

    return { count, resetCountdown, setCounter };
};

export default useCountdown;
