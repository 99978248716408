function profileCompletation(userInfo) {
  const steps = [
    userInfo.securityCode !== null,
    userInfo.profilePicture !== null,
    !!userInfo.kycApproved,
    userInfo.address !== null,
    userInfo.otpMethod === "TOTP",
  ];
  let percentage = 0;
  const increase = 100 / steps.length;

  let verified = steps.filter((step) => step === true).length;
  percentage = verified * increase;

  return percentage;
}

export default profileCompletation;
