import React, { useRef, useContext } from "react";

// Components
import AuthSlider from "../../components/advanced/authSlider/AuthSlider";
import LogoutModal from "../../components/advanced/logoutModal/LogoutModal";
import UnauthorizedModal from "../../components/advanced/unauthorizedModal/UnauthorizedModal";

// Libraries
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { MagicModal } from "@hybris-software/ui-kit";

// Hooks
import useText from "../../hooks/useText";

// Data
import config from "../../data/config";

// Contexts
import { RoutesContext } from "../../contexts/RoutesContext";

// Icons
import { BiSupport } from "react-icons/bi";

// Styles
import Style from "./AuthLayout.module.css";

const AuthLayout = () => {
  // Hooks
  const texts = useText("authLayout");
  const location = useLocation();
  const navigate = useNavigate();
  // Refs
  const unauthorizedModalRef = useRef(null);
  const errorModalRef = useRef(null);
  const logoutRef = useRef(null);
  // Contexts
  const { paths } = useContext(RoutesContext);

  const pathsLogout = [
    "/auth/terms-and-conditions",
    "/auth/enroll",
    "/auth/account-type",
    "/auth/referral-code",
    "/auth/chang-otp",
  ];

  return (
    <div className={Style.authentication}>
      <MagicModal
        ref={unauthorizedModalRef}
        body={<UnauthorizedModal errorModalRef={errorModalRef} />}
        showCloseIcon={false}
      />

      <MagicModal
        modalStyle={
          config.DEBUG_MODE
            ? {
                minWidth: "200px",
                maxWidth: "unset",
                width: "unset",
              }
            : null
        }
        ref={errorModalRef}
      />

      <MagicModal ref={logoutRef} />

      <header className={Style.header}>
        <aside
          className={Style.logo}
          onClick={() => {
            navigate(paths.auth.login);
          }}
        >
          <img as="image" rel="preload" src={config.LOGO_BLACK} alt="" />
        </aside>
        <aside className={Style.langNavigation}>
          <div className={Style.reminder}>
            {pathsLogout.includes(location?.pathname) ? (
              <>
                <span
                  className={Style.cta}
                  onClick={() => {
                    logoutRef.current.updateBody(
                      <LogoutModal modalRef={logoutRef} />
                    );
                  }}
                >
                  {texts?.logout}
                </span>
              </>
            ) : location?.pathname === "/auth/login" ? (
              <>
                <span className={Style.resp}>{texts?.headerCtaSignUp} </span>
                <span
                  className={Style.cta}
                  onClick={() => {
                    navigate(paths.auth.register);
                  }}
                >
                  {texts?.headerSignup}
                </span>
              </>
            ) : (
              <>
                <span className={Style.resp}>{texts?.headerCta} </span>
                <span
                  className={Style.cta}
                  onClick={() => {
                    navigate(paths.auth.login);
                  }}
                >
                  {texts?.headerSignin}
                </span>
              </>
            )}
          </div>
          {/* <Languages /> */}
        </aside>
      </header>
      <main className={Style.main}>
        <aside className={Style.render}>
          <div className={Style.centerContent}>
            <Outlet context={{ unauthorizedModalRef, errorModalRef }} />
          </div>
        </aside>
        <aside className={Style.slider}>
          <AuthSlider />
        </aside>
      </main>
      <footer className={Style.footer}>
        <a
          href={config.SUPPORT_LINK}
          target="_blank"
          rel="noopener noreferrer"
          className={Style.supportBtn}
        >
          <BiSupport />
          Support
        </a>
      </footer>
    </div>
  );
};

export default AuthLayout;
