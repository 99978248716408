import React from 'react';

// Components
import StatusResponse from '../statusResponse/StatusResponse';
import SelectComponent from '../SelectComponent/SelectComponent';
import UnauthorizedModal from '../unauthorizedModal/UnauthorizedModal';

// Libraries
import useForm from '@hybris-software/use-ful-form';
import { Button } from '@hybris-software/ui-kit';

import { useUser } from '@hybris-software/use-auth';
import useQuery from '@hybris-software/use-query';

// Hooks
import useText from '../../../hooks/useText';

// Images
import Success from '../../../assets/images/success.webp';

// Utils
import countryList from '../../../utils/countryList';

// Data
import endpoints from '../../../data/endpoints';

// Styles
import Style from './CountryModal.module.css';
import ErrorModal from '../errorModal/ErrorModal';

const CountryModal = ({ modalRef, countries }) => {
	// Hooks
	const texts = useText('profileView')?.personalInformation;
	const successTexts = useText('success');

	const { refreshUserInfo } = useUser();

	const form = useForm({
		inputs: {
			country: {
				required: true,
			},
		},
	});

	const updateCountryApi = useQuery({
		url: endpoints.profile.UPDATE_COUNTRY,
		method: 'POST',
		onSuccess: (response) => {
			refreshUserInfo();
			modalRef?.current?.updateBody(
				<StatusResponse
					icon={Success}
					title={successTexts?.informationTitle}
					description={successTexts?.informationSubtitle}
				>
					<Button
						onClick={() => {
							modalRef?.current?.destroy();
						}}
						style={{
							borderRadius: '30px',
							width: '100%',
							maxWidth: '200px',
							marginBottom: '10px',
						}}
					>
						{successTexts?.buttonClose}
					</Button>
				</StatusResponse>
			);
		},
		onError: (error) => {
			if (error.response?.status === 401) {
				modalRef?.current?.updateBody(<UnauthorizedModal />);
			} else {
				modalRef?.current?.updateBody(<ErrorModal />);
			}
		},
	});

	const countryError = form.getInputProps('country').errorDetails;

	return (
		<div className={Style.personal}>
			<div className={Style.residential}>
				<h5>Update your country</h5>

				<div className={Style.country}>
					<div className={Style.label}>{texts?.labelCountry}</div>
					<SelectComponent
						className={countryError ? Style.selectError : ''}
						maxHeightOpened={235}
						items={countries?.filter((country) => {
							return country?.disabled === false;
						})}
						searchKey='name'
						placeholder='Select a country'
						renderOption={(item, fullItem) => (
							<div className={Style.option}>
								<span className={Style.flag}>
									<img
										alt=''
										style={{
											height: '20px',
											width: '20px',
										}}
										src={`data:image/svg+xml;utf8,${encodeURIComponent(
											countryList?.find(
												(item) =>
													item.code === fullItem.code
											)?.flag
										)}`}
									/>
								</span>

								{fullItem.name}
							</div>
						)}
						value={countries?.find(
							(item) => item.code === form.values['country']
						)}
						setValue={(value) => {
							form.getInputProps('country').setValue(value?.code);
						}}
					/>
					{countryError && (
						<div className={Style.errorMessageClassName}>
							{countryError}
						</div>
					)}
				</div>

				<p className={Style.para}>
					We would like to inform you that there are currently no
					matches associated with the country in your profile. To
					ensure that we provide you with the best experience and
					relevant information, please take a moment to update your
					profile, select a country and save this information in your
					profile.
				</p>

				<Button
					disabled={!form.isValid()}
					isLoading={updateCountryApi.isLoading}
					onClick={() => {
						updateCountryApi.executeQuery(form.getApiBody());
					}}
					className={Style.button}
				>
					Save
				</Button>
			</div>
		</div>
	);
};

export default CountryModal;
