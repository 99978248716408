import React from "react";

// Components
import ErrorModal from "../../../../../components/advanced/errorModal/ErrorModal";
import StatusResponse from "../../../../../components/advanced/statusResponse/StatusResponse";

// Libraries
import { Button } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";
import SumsubWebSdk from "@sumsub/websdk-react";
import { useOutletContext } from "react-router-dom";
import { useUser } from "@hybris-software/use-auth";

// Hooks
import useText from "../../../../../hooks/useText";

// Data
import endpoints from "../../../../../data/endpoints";
import { accountType } from "../../../../../data/constants";

//Icons
import bell from "../../../../../assets/images/error.webp";

// Styles
import Style from "./Kyc.module.css";

const Kyc = ({ getKYCStatusAPI }) => {
  // console.log(getKYCStatusAPI?.response?.data);
  // Hooks
  const { userInfo, refreshUserInfo } = useUser();
  const kycTextKey =
    userInfo?.accountType === accountType.PERSONAL ? "startKyc" : "startKyb";
  const texts = useText("kycView")[kycTextKey];

  // Contexts
  const { errorModalRef, unauthorizedModalRef } = useOutletContext();

  // Queries
  const startKycApi = useQuery({
    url: endpoints.kyc.CHECKANDSTART,
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      if (
        response.data.kycApproved === true ||
        response.data.verified === true
      ) {
        refreshUserInfo();
      }
    },
    onError: (error) => {
      if (error.response?.status === 400) {
        errorModalRef?.current?.updateBody(
          <StatusResponse
            icon={bell}
            title={"Failed !"}
            description={texts?.badRequestMessage}
          />
        );
      }
      errorModalRef.current.updateBody(
        <ErrorModal error={error} modalRef={errorModalRef} />
      );
    },
    onUnauthorized: () => {
      unauthorizedModalRef.current.open();
    },
  });

  return (
    <div className={Style.kyc}>
      {getKYCStatusAPI.isSuccess ? (
        <div className={Style.box}>
          <h5>{texts?.title}</h5>

          {getKYCStatusAPI?.response?.data?.verified === true ? (
            <div className={Style.infoApproved}>
              {texts?.kycApproved}
              <div className={Style.date}>
                {userInfo?.kycApprovalDate
                  ? new Date(userInfo?.kycApprovalDate).toLocaleDateString(
                      "en-US",
                      {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      }
                    )
                  : "--"}
              </div>
            </div>
          ) : !startKycApi.response?.data?.kycToken ? (
            getKYCStatusAPI?.response?.data?.kycEnabled === true &&
            getKYCStatusAPI?.response?.data?.purchaseCompleted === true ? (
              <>
                <div className={Style.info}>{texts?.subtitle}</div>
                <Button
                  className={Style.button}
                  disabled={startKycApi.isLoading}
                  isLoading={startKycApi.isLoading}
                  onClick={() => {
                    startKycApi.executeQuery();
                  }}
                >
                  {texts?.buttonStart}
                </Button>
              </>
            ) : getKYCStatusAPI?.response?.data.kycEnabled === true ? (
              <>
                <div className={Style.info}>{texts?.subtitle}</div>
                {texts?.purchaseNotCompleted}
              </>
            ) : (
              <>
                <div className={Style.info}>{texts?.subtitle}</div>
                {texts?.kycDisabled}
              </>
            )
          ) : (
            <SumsubWebSdk
              config={{
                uiConf: {
                  scrollIntoView: false,
                },
              }}
              accessToken={startKycApi.response?.data?.kycToken}
              expirationHandler={() => {}}
              // onMessage={messageHandler}
              onError={() => {}}
            />
          )}

          {/* {userInfo?.kycApproved ? (
						<>
							<div className={Style.infoApproved}>
								{texts?.kycApproved}
								<div className={Style.date}>
									{userInfo?.kycApprovalDate
										? new Date(
												userInfo?.kycApprovalDate
										  ).toLocaleDateString('en-US', {
												day: 'numeric',
												month: 'short',
												year: 'numeric',
												hour: 'numeric',
												minute: 'numeric',
										  })
										: '--'}
								</div>
							</div>
						</>
					) : startKycApi.response?.data?.kycToken ? (
						<SumsubWebSdk
							config={{
								uiConf: {
									scrollIntoView: false,
								},
							}}
							accessToken={startKycApi.response?.data?.kycToken}
							expirationHandler={() => {}}
							// onMessage={messageHandler}
							onError={() => {}}
						/>
					) : (
						<>
							<div className={Style.info}>{texts?.subtitle}</div>

							<div className={Style.start}>
								{getKYCStatusAPI?.response?.data?.kycEnabled &&
								getKYCStatusAPI?.response?.data
									?.purchaseCompleted ? (
									<Button
										className={Style.button}
										disabled={startKycApi.isLoading}
										isLoading={startKycApi.isLoading}
										onClick={() => {
											startKycApi.executeQuery();
										}}
									>
										{texts?.buttonStart}
									</Button>
								) : (
									<div className={Style.infoApproved}>
										{getKYCStatusAPI?.response?.data
											.kycEnabled
											? texts?.purchaseNotCompleted
											: texts?.kycDisabled}
									</div>
								)}
							</div>
						</>
					)} */}
        </div>
      ) : getKYCStatusAPI.isError &&
        getKYCStatusAPI?.response?.status === 400 ? (
        <div className={Style.box}>
          <h5>{texts?.title}</h5>
          <div className={Style.info}>{getKYCStatusAPI?.response?.message}</div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Kyc;
