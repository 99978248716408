import React, { useRef, useState, useEffect, useContext } from 'react';

// Components
import MobileMenu from '../../components/advanced/mobileMenu/MobileMenu';
import Navbar from '../../components/ui/navbar/Navbar';
import NotificationsList from '../../components/advanced/notificationsList/NotificationsList';
import UnauthorizedModal from '../../components/advanced/unauthorizedModal/UnauthorizedModal';

// Libraries
import useQuery from '@hybris-software/use-query';
import { Outlet, useNavigate } from 'react-router-dom';
import { MagicModal } from '@hybris-software/ui-kit';
import useWebSocket from 'react-use-websocket';
import { PermissionRoute } from '@hybris-software/use-auth';

// Contexts
import NotificationContext from '../../contexts/NotificationContext';
import { RoutesContext } from '../../contexts/RoutesContext';

// Data
import endpoints from '../../data/endpoints';
import config from '../../data/config';

// Utils
import dispatcher from '../../utils/dispatcher';

// Styles
import Style from './MainLayout.module.css';
import CountryModal from '../../components/advanced/countryModal/CountryModal';

const MainLayout = () => {
	// Refs
	const unauthorizedModalRef = useRef(null);
	const errorModalRef = useRef(null);
	const logoutRef = useRef(null);
	const forceData = useRef(null);
	const businessCardRef = useRef(null);
	// States
	const [openMobile, setOpenMobile] = useState(false);
	const [openNotification, setOpenNotification] = useState(false);
	const [notificationsList, setNotificationsList] = useState([]);
	const [url, setUrl] = useState(null);
	const [allNotificationsList, setAllNotificationsList] = useState();
	// Contexts
	const { paths } = useContext(RoutesContext);
	// Hooks
	const navigate = useNavigate();

	// WebSocket notification
	const { sendMessage } = useWebSocket(config.WEBSOCKET_NOTIFICATION_URL, {
		onOpen: () => {
			sendMessage(localStorage.getItem('token'));
		},
		onMessage: (event) => {
			const response = JSON.parse(event?.data);
			if (
				response?.event === 'OLD_NOTIFICATIONS' &&
				response?.data?.length > 0
			) {
				setNotificationsList(response?.data);
			}
			if (
				response?.event === 'NOTIFICATION' &&
				response?.data?.length > 0
			) {
				setNotificationsList((prev) => [...response?.data, ...prev]);
			}
		},
		shouldReconnect: (closeEvent) => true,
	});

	const getNotificationsApi = useQuery({
		url: url,
		method: 'GET',
		executeImmediately: false,
		onSuccess: (response) => {
			if (response.data.page === 1)
				setAllNotificationsList(response.data.results);
			else
				setAllNotificationsList(
					allNotificationsList.concat(response.data.results)
				);
		},
	});

	const countriesApi = useQuery({
		url: endpoints.auth.COUNTRIES,
		method: 'GET',
		executeImmediately: false,
		onSuccess: (response) => {
			setTimeout(() => {
				forceData?.current?.updateBody(
					<CountryModal
						modalRef={forceData}
						countries={response?.data}
					/>
				);
			}, 2000);
		},
	});

	const authUserApi = useQuery({
		url: endpoints.auth.USERINFO,
		method: 'GET',
		executeImmediately: false,
		onSuccess: (response) => {
			if (!response?.data?.country) {
				countriesApi.executeQuery();
			}
		},
	});

	useEffect(() => {
		setUrl(endpoints.notifications.GETNOTIFICATIONS);
	}, []);

	useEffect(() => {
		if (url) getNotificationsApi.executeQuery();
		// eslint-disable-next-line
	}, [url]);

	useEffect(() => {
		authUserApi.executeQuery();
		// eslint-disable-next-line
	}, []);

	return (
		<PermissionRoute
			// loader={<Loader />}
			minimumLoadingTime={0}
			forLoggedUser={true}
			firstApiLoading={getNotificationsApi.isLoading}
			unAuthorizedAction={() => {
				navigate(paths.auth.login);
			}}
			permissionController={(response) => {
				dispatcher(response.data, navigate, paths);
				return true;
			}}
		>
			<NotificationContext.Provider
				value={[notificationsList, setNotificationsList]}
			>
				{/* //+  Modal for api call error to updateBody*/}
				<MagicModal
					modalStyle={
						config.DEBUG_MODE
							? {
									minWidth: '200px',
									maxWidth: 'unset',
									width: 'unset',
							  }
							: null
					}
					ref={errorModalRef}
				/>

				{/* //+ Displayed on error api (onUnauthorized) when the user losted authorization */}
				<MagicModal
					ref={unauthorizedModalRef}
					body={<UnauthorizedModal errorModalRef={errorModalRef} />}
					onModalOpen={() => {
						document.body.style.overflow = 'hidden';
					}}
					onBodyUpdate={() => {
						document.body.style.overflow = 'hidden';
					}}
					onModalDestroy={() => {
						document.body.style.overflow = 'overlay';
					}}
					showCloseIcon={false}
				/>

				<MagicModal ref={forceData} showCloseIcon={false} />

				{sessionStorage.getItem('inspectorTool') === 'true' && (
					<>
						<div className={Style.topBar}>
							<div className={Style.mode}>SPY MODE</div>
						</div>
						<div className={Style.bottomBar}>
							<div className={Style.mode}>SPY MODE</div>
						</div>
						<div
							className={Style.borderWarnSide}
							style={{ top: 0, left: 0 }}
						/>
						<div
							className={Style.borderWarnSide}
							style={{ top: 0, right: 0 }}
						/>
					</>
				)}

				<MagicModal ref={logoutRef} />
				<Navbar
					logoutRef={logoutRef}
					openMobile={openMobile}
					setOpenMobile={setOpenMobile}
					openNotify={openNotification}
					setOpenNotify={setOpenNotification}
				/>
				<MobileMenu
					logoutRef={logoutRef}
					openMobile={openMobile}
					setOpenMobile={setOpenMobile}
					openNotify={openNotification}
					setOpenNotify={setOpenNotification}
				/>
				<NotificationsList
					openMobile={openMobile}
					openNotification={openNotification}
					setOpenNotification={setOpenNotification}
					allNotifications={allNotificationsList}
					setAllNotificationsList={setAllNotificationsList}
					setUrl={setUrl}
					links={getNotificationsApi?.response?.data?.links}
					loading={getNotificationsApi?.isLoading}
				/>

        {/* A modal to show list of countries */}

        <MagicModal ref={forceData} showCloseIcon={false} />

        {/* A modal to show logout  */}
        <MagicModal ref={logoutRef} />
        {/* A modal to show business card  */}
        <MagicModal
          ref={businessCardRef}
          contentStyle={{ padding: "0px" }}
          closeIconStyle={{ margin: "10px 10px 0px 0px" }}
        />

        {sessionStorage.getItem("inspectorTool") === "true" && (
          <>
            <div className={Style.topBar}>
              <div className={Style.mode}>SPY MODE</div>
            </div>
            <div className={Style.bottomBar}>
              <div className={Style.mode}>SPY MODE</div>
            </div>
            <div className={Style.borderWarnSide} style={{ top: 0, left: 0 }} />
            <div
              className={Style.borderWarnSide}
              style={{ top: 0, right: 0 }}
            />
          </>
        )}

        <Navbar
          logoutRef={logoutRef}
          openMobile={openMobile}
          setOpenMobile={setOpenMobile}
          openNotify={openNotification}
          setOpenNotify={setOpenNotification}
        />
        <MobileMenu
          logoutRef={logoutRef}
          openMobile={openMobile}
          setOpenMobile={setOpenMobile}
          openNotify={openNotification}
          setOpenNotify={setOpenNotification}
        />
        <NotificationsList
          openMobile={openMobile}
          openNotification={openNotification}
          setOpenNotification={setOpenNotification}
          allNotifications={allNotificationsList}
          setAllNotificationsList={setAllNotificationsList}
          setUrl={setUrl}
          links={getNotificationsApi?.response?.data?.links}
          loading={getNotificationsApi?.isLoading}
        />

        <Outlet
          context={{ unauthorizedModalRef, errorModalRef, businessCardRef }}
        />
      </NotificationContext.Provider>
    </PermissionRoute>
  );
};

export default MainLayout;
