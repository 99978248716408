import React, { useEffect, useContext } from 'react';

// Components
import Checkbox from '../../../components/advanced/checkbox/Checkbox';
import ErrorModal from '../../../components/advanced/errorModal/ErrorModal';
import StatusResponse from './../../../components/advanced/statusResponse/StatusResponse';

// Libraries
import useForm from '@hybris-software/use-ful-form';
import { InputField, Button } from '@hybris-software/ui-kit';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { PermissionRoute } from '@hybris-software/use-auth';
import useQuery from '@hybris-software/use-query';

// Icons
import { HiChevronLeft } from 'react-icons/hi';
import errorIcon from '../../../assets/images/error.svg';

// Hooks
import useText from '../../../hooks/useText';

// Contexts
import { RoutesContext } from '../../../contexts/RoutesContext';

// Utils
import validatePassword from '../../../utils/passwordValidator';

// Data
import endpoints from '../../../data/endpoints';
import config from '../../../data/config';

// Styles
import Style from './ResetPasswordView.module.css';
import updateFormError from '../../../utils/updateFormError';

const ResetPasswordView = () => {
	// Hooks
	const texts = useText('resetPasswordView');
	const inputTexts = useText('inputs');
	const navigate = useNavigate();
	// Contexts
	const { errorModalRef } = useOutletContext();
	const { paths } = useContext(RoutesContext);
	// Search params
	const searchParams = new URLSearchParams(window.location.search);
	const token = searchParams.get('token');

	const form = useForm({
		inputs: {
			password: {
				required: true,
				nature: 'password',
				validator: validatePassword,
				errorOnEveryChange: true,
				sendToApi: false,
			},
			newPassword: {
				nature: 'confirmPassword',
				validator: (value, values) => {
					if (!value || value === '')
						return [false, inputTexts?.mandatory];
					if (value !== values['password'])
						return [false, inputTexts?.notMatch];
					else return [true, ''];
				},
			},
			token: {
				required: true,
				value: token,
			},
			logout: {
				nature: 'checkbox',
			},
		},
	});

	const resetPasswordApi = useQuery({
		url: endpoints.auth.RESETPASSWORDCONFIRM,
		method: 'POST',
		onSuccess: (response) => {},
		onError: (error) => {
			if (error?.response?.status === 422) {
				if (error?.response?.data.token) {
					errorModalRef.current.updateBody(
						<StatusResponse
							icon={errorIcon}
							title={'Failed !'}
							description={texts?.invalidToken}
						/>
					);
				} else {
					updateFormError(error.response?.data, form);
				}
			} else {
				errorModalRef.current.updateBody(
					<ErrorModal error={error} modalRef={errorModalRef} />
				);
			}
		},
	});

	useEffect(() => {
		document.title = `${config.PLATFORM_NAME} | ${texts?.pageTitle}`;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<PermissionRoute
			// loader={<LoaderAuth />}
			minimumLoadingTime={500}
			forLoggedUser={false}
			unAuthorizedAction={() => navigate(paths.auth.login)}
			permissionController={() => {
				if (token) return true;
				else navigate(paths.auth.authRedirect);
			}}
		>
			<div className={Style.card}>
				{resetPasswordApi.isSuccess ? (
					<div className={Style.resetted}>
						<h4 className={Style.title}>{texts?.titleResetted}</h4>
						<div className={Style.reminder}>
							{texts?.subtitleResetted}
						</div>
					</div>
				) : (
					<>
						<h4 className={Style.title}>{texts?.title}</h4>
						<div className={Style.reminder}>{texts?.subtitle}</div>
						<InputField
							label={texts?.labelPassword}
							placeholder={texts?.placeholderPassword}
							type='password'
							showPasswordIconAlwaysMobile
							{...form.getInputProps('password')}
						/>

						<InputField
							label={texts?.labelConfirmPassword}
							placeholder={texts?.placeholderPassword}
							type='password'
							showPasswordIconVisibility
							onPaste={false}
							{...form.getInputProps('newPassword')}
						/>

						<div className={Style.check}>
							{texts?.logout}
							<Checkbox
								checked={form.values['logout']}
								onChange={(e) =>
									form
										?.getInputProps('logout')
										.setValue(e.target.checked)
								}
							/>
						</div>

						<Button
							className={Style.button}
							disabled={!form.isValid()}
							isLoading={resetPasswordApi.isLoading}
							onClick={() => {
								resetPasswordApi.executeQuery(
									form.getApiBody()
								);
							}}
						>
							{texts?.button}
						</Button>
					</>
				)}

				<div
					className={Style.goBack}
					onClick={() => {
						navigate(paths?.auth?.login);
					}}
				>
					<HiChevronLeft /> {texts?.goBack}
				</div>
			</div>
		</PermissionRoute>
	);
};

export default ResetPasswordView;
