import React from 'react';

// Libraries
import useQuery from '@hybris-software/use-query';
import { useNavigate } from 'react-router';

// Images
import Maintenance from '../../assets/images/maintenacne-image.png';

// Data
import endpoints from '../../data/endpoints';
import { paths } from '../../data/routes';
import config from '../../data/config';

// Styles
import Style from './MaintenanceView.module.css';

const MaintenanceView = () => {
	// Hooks
	const navigate = useNavigate();
	// Queries
	useQuery({
		url: endpoints.PUBLIC_PLATFORM_CONFIG,
		method: 'GET',
		executeImmediately: true,
		onSuccess: (response) => {
			if (response?.data?.maintainanceMode === false) {
				navigate(paths.main.dashboard);
			}
		},
	});
	return (
		<div>
			<div className={Style.bg} />

			<div className={Style.center}>
				<img rel='preload' as='image' src={config.LOGO_BLACK} alt='' />
				<div className={Style.flexContainer}>
					<div className={Style.left}>
						<h1 className={Style.title}>Maintenance Mode</h1>
						<p className={Style.info}>
							We apologize for the inconvenience. We will be back
							shortly. Thanks for your cooperation.
						</p>
					</div>
					<div className={Style.right}>
						<img src={Maintenance} alt='' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default MaintenanceView;
