import React, { useEffect, useContext, useRef } from "react";

// Components
import ErrorBanner from "../../../components/advanced/errorBanner/ErrorBanner";
import ErrorModal from "../../../components/advanced/errorModal/ErrorModal";

// Libraries
import useForm from "@hybris-software/use-ful-form";
import { InputField, Button, useWindowSize } from "@hybris-software/ui-kit";
import { useNavigate, useOutletContext } from "react-router-dom";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import useQuery from "@hybris-software/use-query";
import { AuthRoute } from "@hybris-software/use-auth";

// Icons
import { FiAlertTriangle } from "react-icons/fi";

// Hooks
import useText from "../../../hooks/useText";

// Data
import endpoints from "../../../data/endpoints";
import config from "../../../data/config";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";
import { LanguageContext } from "../../../contexts/LanguageContext";

// Utils
import updateFormError from "../../../utils/updateFormError";
import {
  getUrlParams,
  navigateWithRedirect,
} from "../../../utils/utilityFunctions";

// Styles
import Style from "./LoginView.module.css";

const LoginView = () => {
  // Hooks
  const texts = useText("loginView");
  const inputTexts = useText("inputs");
  const navigate = useNavigate();
  // Ref
  const captchaRef = useRef(null);
  // Contexts
  const { paths } = useContext(RoutesContext);
  const { errorModalRef } = useOutletContext();
  const { language } = useContext(LanguageContext);
  const { width } = useWindowSize();
  // Variables
  const urlParams = getUrlParams();
  // Form
  const form = useForm({
    inputs: {
      username: {
        validator: (value) => {
          if (!value || value === "") return [false, inputTexts?.mandatory];
          return [true, ""];
        },
        formatter: (value) => {
          return value.toLowerCase();
        },
      },
      password: {
        validator: (value) => {
          if (!value || value === "") return [false, inputTexts?.mandatory];
          return [true, ""];
        },
      },
      captchaToken: {
        required: true,
      },
      rememberMe: {
        value: localStorage.getItem("username") ? true : false,
        nature: "checkbox",
        sendToApi: false,
      },
    },
  });

  const platformConfigApi = useQuery({
    url: endpoints.auth.PLATFORMCONFIG,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      if (response?.data?.maintainanceMode === true) {
        navigate(paths.generic.maintenance);
      }
    },
  });

  const publicPlatformConfigApi = useQuery({
    url: endpoints.PUBLIC_PLATFORM_CONFIG,
    method: "GET",
    executeImmediately: true,
  });

  const loginApi = useQuery({
    url: endpoints.auth.LOGIN,
    method: "POST",
    onSuccess: (response) => {
      localStorage.setItem("loginToken", response.data.loginToken);
      localStorage.setItem("otpMethod", response.data.otpMethod);
      localStorage.setItem("email", response.data.email);

      form.values["rememberMe"]
        ? localStorage.setItem("username", form.values["username"])
        : localStorage.removeItem("username");

      if (urlParams[config.REDIRECT_URL_LABEL]) {
        navigateWithRedirect(
          navigate,
          paths.auth.twoFactor,
          urlParams[config.REDIRECT_URL_LABEL]
        );
      } else {
        navigate(paths.auth.twoFactor);
      }
    },
    onError: (error) => {
      platformConfigApi.executeQuery();
      captchaRef.current.resetCaptcha();
      if (error?.response?.status === 422) {
        updateFormError(error?.response?.data, form);
      } else if (
        error?.response?.status !== 400 &&
        error?.response?.status !== 422 &&
        error?.response?.status !== 401
      ) {
        errorModalRef.current.updateBody(
          <ErrorModal error={error} modalRef={errorModalRef} />
        );
      }
    },
    onUnauthorized: () => {
      captchaRef.current.resetCaptcha();
    },
  });

  useEffect(() => {
    document.title = `${config.PLATFORM_NAME} | ${texts?.pageTitle}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthRoute
      // loader={<LoaderAuth />}
      minimumLoadingTime={500}
      forLoggedUser={false}
      firstApiLoading={
        platformConfigApi.isLoading || publicPlatformConfigApi.isLoading
      }
      action={() => {
        navigate(paths.auth.authRedirect);
      }}
    >
      <div className={Style.card}>
        <h4 className={Style.title}>{texts?.title}</h4>
        <div className={Style.reminder}>
          {texts?.headerCta}{" "}
          <span
            className={Style.cta}
            onClick={() => {
              navigate(paths.auth.register);
            }}
          >
            {texts?.headerSignup}
          </span>
        </div>

        {platformConfigApi.response?.data?.loginEnabled === false && (
          <div className={Style.errorBox}>{texts?.loginDisabled}</div>
        )}

        <ErrorBanner response={loginApi.error?.response} call="login" />

        <InputField
          label={texts?.labelEmail}
          placeholder={texts?.placeholderEmail}
          errorIconVisibility
          errorIcon={<FiAlertTriangle color="var(--error)" />}
          {...form.getInputProps("username")}
        />

        <InputField
          label={texts?.labelPassword}
          placeholder={texts?.placeholderPassword}
          type="password"
          showPasswordIconVisibility
          showPasswordIconAlwaysMobile
          {...form.getInputProps("password")}
        />

        <div className={Style.remember}>
          <input
            type="checkbox"
            id="remember"
            checked={form.values["rememberMe"]}
            onChange={(e) =>
              form.getInputProps("rememberMe").setValue(e.target.checked)
            }
          />
          <label htmlFor="remember">{texts?.rememberLabel}</label>
        </div>

        <div className={Style.captcha}>
          <HCaptcha
            size={width > 575 ? "normal" : "compact"}
            sitekey={config.CAPTCHA_SITE_KEY}
            languageOverride={language.toLowerCase() || "en"}
            onVerify={(value) => {
              form.getInputProps("captchaToken").setValue(value);
            }}
            onExpire={() => form.getInputProps("captchaToken").setValue(null)}
            ref={captchaRef}
          />
        </div>

        <Button
          className={Style.button}
          disabled={!form.isValid()}
          isLoading={loginApi.isLoading}
          onClick={() => {
            loginApi.executeQuery(form.getApiBody());
            form.getInputProps("captchaToken").setValue(null);
          }}
        >
          {texts?.button}
        </Button>

        <div
          className={Style.forgot}
          onClick={() => {
            navigate(paths?.auth?.forgotPassword);
          }}
        >
          {texts?.forgotPassword}
        </div>
      </div>
    </AuthRoute>
  );
};

export default LoginView;
