import React from 'react';

// Styles
import Style from './Hero.module.css';

const Hero = ({ children }) => {
	return (
		<div
			className={Style.hero}
			style={
				sessionStorage.getItem('inspectorTool') === 'true'
					? { paddingTop: 120 }
					: {}
			}
		>
			{children}
		</div>
	);
};

export default Hero;
