import React, { useState } from 'react';

// Components
import copyToClipboard from '../../../../../utils/copyToClipboard';

// Libraries
import { Button } from '@hybris-software/ui-kit';
import { useUser } from '@hybris-software/use-auth';

// Hooks
import useText from '../../../../../hooks/useText';

// Styles
import Style from './KeyGenerator.module.css';

const KeyGenerator = () => {
	// Hooks
	const texts = useText('securityView')?.keyGenerator;
	// States
	const [copied, setCopied] = useState('Copy');

	const { userInfo } = useUser();

	return (
		<div className={Style.security}>
			<div className={Style.box}>
				<h5>{texts?.title}</h5>
				<p className={Style.subtitle}>{texts?.subheading}</p>
				<div className={Style.btnBox}>
					<div className={Style.key}>{userInfo?.lfiKey ?? '-'}</div>
					{userInfo?.lfiKey && (
						<Button
							className={Style.button}
							onClick={(e) => {
								copyToClipboard(userInfo?.lfiKey);
								setCopied('Copied');
								setTimeout(() => {
									setCopied('Copy');
								}, 1000);
							}}
						>
							{copied}
						</Button>
					)}
				</div>
			</div>
		</div>
	);
};

export default KeyGenerator;
