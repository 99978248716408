import React from "react";

// Components
import TwofactorCode from "../../../../../components/advanced/twoFactorCode/TwofactorCode";
import ErrorModal from "../../../../../components/advanced/errorModal/ErrorModal";

// Libraries
import useForm from "@hybris-software/use-ful-form";
import { InputField, Button } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";
import { useOutletContext } from "react-router-dom";

// Utils
import validatePassword from "../../../../../utils/passwordValidator";
import updateFormError from "../../../../../utils/updateFormError";

// Hooks
import useText from "../../../../../hooks/useText";

// Data
import endpoints from "../../../../../data/endpoints";

// Styles
import Style from "./Password.module.css";

const Password = ({ modalRef }) => {
  // Hooks
  const texts = useText("passwordView")?.changePassword;
  const inputTexts = useText("inputs");
  // Contexts
  const { errorModalRef, unauthorizedModalRef } = useOutletContext();

  const changePasswordApi = useQuery({
    url: endpoints.profile.CHANGEPASSWORD,
    method: "POST",
    onSuccess: (response) => {
      form.reset();
      modalRef?.current?.updateBody(
        <TwofactorCode
          otpMethod={response?.data?.otpMethod}
          errorCall="changePassword"
          modalRef={modalRef}
        />
      );
    },
    onError: (error) => {
      if (error.response?.status === 422) {
        updateFormError(error.response?.data, form);
      } else {
        errorModalRef.current.updateBody(
          <ErrorModal error={error} modalRef={errorModalRef} />
        );
      }
    },
    onUnauthorized: () => {
      unauthorizedModalRef.current.open();
    },
  });

  const form = useForm({
    inputs: {
      oldPassword: {
        required: true,
      },
      password: {
        sendToApi: false,
        required: true,
        nature: "password",
        errorOnEveryChange: true,
        validator: validatePassword,
      },
      newPassword: {
        errorOnEveryChange: true,
        nature: "confirmPassword",
        validator: (value, values) => {
          if (!value || value === "") return [false, inputTexts?.mandatory];
          if (value !== values["password"])
            return [false, inputTexts?.notMatch];
          else return [true, ""];
        },
      },
    },
  });

  return (
    <div className={Style.password}>
      <div className={Style.box}>
        <h5>{texts?.title}</h5>
        <p className={Style.subtitle}>{texts?.subtitle}</p>
        <div className={Style.inputs}>
          <div className={Style.label}>{texts?.placeholderPassword}</div>
          <InputField
            className={Style.input}
            placeholder={texts?.placeholderPassword}
            type="password"
            showPasswordIconAlwaysMobile
            {...form.getInputProps("oldPassword")}
          />
          <div className={Style.label}>{texts?.labelNewPassword}</div>
          <InputField
            className={Style.input}
            placeholder={texts?.placeholderNewPassword}
            type="password"
            showPasswordIconAlwaysMobile
            {...form.getInputProps("password")}
          />
          <div className={Style.label}>{texts?.labelConfirmPassword}</div>
          <InputField
            className={Style.input}
            placeholder={texts?.placeholderConfirmPassword}
            type="password"
            showPasswordIconAlwaysMobile
            {...form.getInputProps("newPassword")}
          />
        </div>

        <div className={Style.buttons}>
          <Button
            className={Style.save}
            disabled={!form.isValid()}
            isLoading={changePasswordApi.isLoading}
            onClick={() => {
              changePasswordApi.executeQuery(form.getApiBody());
            }}
          >
            {texts?.saveButton}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Password;
