import React, { useState, useEffect, useContext } from 'react';

// Components
import ErrorModal from '../../../components/advanced/errorModal/ErrorModal';

// Libraries
import { Button } from '@hybris-software/ui-kit';
import useQuery from '@hybris-software/use-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { PermissionRoute } from '@hybris-software/use-auth';

// Hooks
import useText from '../../../hooks/useText';

// Contexts
import { RoutesContext } from '../../../contexts/RoutesContext';

// Data
import endpoints from '../../../data/endpoints';
import config from '../../../data/config';

// Utils
import dispatcher from '../../../utils/dispatcher';
import { navigateShape } from '../../../utils/utilityFunctions';

// Styles
import Style from './TermsAndConditionsView.module.css';
import Terms from '../../../components/advanced/terms/Terms';

const TermsAndConditionsView = () => {
	// Hooks
	const texts = useText('termsAndConditionsView');
	const navigate = useNavigate();
	// Contexts
	const { paths } = useContext(RoutesContext);
	const { errorModalRef, unauthorizedModalRef } = useOutletContext();
	// States
	const [terms, setTerms] = useState({
		reachedBottom: false,
		isChecked: false,
	});

	function handleScroll(e) {
		const bottom =
			e.target.scrollHeight - (e.target.scrollTop + 50) <
			e.target.clientHeight;
		if (bottom)
			setTerms((prevState) => ({ ...prevState, reachedBottom: true }));
	}

	const acceptTermsApi = useQuery({
		url: endpoints.auth.TERMSANDCONDITIONS,
		method: 'POST',
		onSuccess: () => {
			navigateShape(navigate, paths.auth.authRedirect);
		},
		onError: (error) => {
			errorModalRef.current.updateBody(
				<ErrorModal error={error} modalRef={errorModalRef} />
			);
		},
		onUnauthorized: () => {
			unauthorizedModalRef.current.open();
		},
	});

	useEffect(() => {
		document.title = `${config.PLATFORM_NAME} | ${texts?.pageTitle}`;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<PermissionRoute
			// loader={<LoaderAuth />}
			forLoggedUser={true}
			minimumLoadingTime={500}
			unAuthorizedAction={() => {
				navigateShape(navigate, paths.auth.login);
			}}
			permissionController={(response) => {
				dispatcher(response.data, navigate, paths, [0, 1]);
				if (response.data.termsAccepted === false) {
					return true;
				} else {
					navigateShape(navigate, paths.auth.authRedirect);
					return;
				}
			}}
		>
			<div className={Style.card}>
				<h4 className={Style.title}>{texts?.title}</h4>

				<div className={Style.content} onScroll={handleScroll}>
					<Terms />
				</div>

				<div
					className={Style.accept}
					style={{
						opacity: terms.reachedBottom ? 1 : 0.5,
						cursor: terms.reachedBottom ? 'pointer' : 'not-allowed',
					}}
				>
					<input
						id='terms'
						style={{
							cursor: terms.reachedBottom
								? 'pointer'
								: 'not-allowed',
						}}
						disabled={!terms.reachedBottom}
						type='checkbox'
						onChange={() =>
							setTerms((prevState) => ({
								...prevState,
								isChecked: !prevState.isChecked,
							}))
						}
					/>
					<label htmlFor='terms'>{texts?.checkbox}</label>
				</div>

				<Button
					className={Style.button}
					disabled={!terms.isChecked}
					isLoading={acceptTermsApi.isLoading}
					onClick={() => {
						acceptTermsApi.executeQuery();
					}}
				>
					{texts?.button}
				</Button>
			</div>
		</PermissionRoute>
	);
};

export default TermsAndConditionsView;
