import React, { useRef } from 'react'

// Libraries
import { Outlet } from 'react-router-dom'
import { MagicModal } from '@hybris-software/ui-kit'

// Data
import config from '../../data/config'

// Styles
import Style from './SsoLayout.module.css'

const SsoLayout = () => {

    // Refs
    const errorModalRef = useRef(null)

    return (
        <>
            <MagicModal
                modalStyle={
                    config.DEBUG_MODE
                        ? { minWidth: "200px", maxWidth: "unset", width: "unset" }
                        : null
                }
                ref={errorModalRef}
            />

            <div className={Style.sso}>
                <Outlet context={{ errorModalRef }} />
            </div>
        </>
    )
}

export default SsoLayout