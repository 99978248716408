import React, { useRef, useState } from 'react';

// Libraries
import { Button } from '@hybris-software/ui-kit';

// Data
import config from '../../../data/config';

// Images
import Error from '../../../assets/images/error.webp';

// Utils
import downloadFile from '../../../utils/downloadFile';

// Hooks
import useText from '../../../hooks/useText';

// Styles
import Style from './ErrorModal.module.css';

const ErrorModal = ({
	onClickButton = () => {},
	error,
	modalRef,
	title,
	description,
}) => {
	// Hooks
	const texts = useText('generalError');

	const testRef = useRef(null);
	const [showHtml, setShowHtml] = useState(false);

	return (
		<>
			<div className={Style.error}>
				<img
					as='image'
					rel='preload'
					className={Style.icon}
					src={Error}
					alt=''
					draggable='false'
				/>
				<h5>{title || texts?.title}</h5>
				<p>{description || texts?.subTitle}</p>

				<div className={Style.button}>
					<Button
						className={Style.close}
						onClick={() => {
							onClickButton();
							modalRef.current.destroy();
						}}
					>
						{texts?.button}
					</Button>
				</div>

				{config.DEBUG_MODE && (
					<div className={Style.dataInfo}>
						<div className={Style.debugMode}>
							<div className={Style.label}>Debug mode only</div>
							<div className={Style.message}>
								Error Message: {error?.message}
							</div>

							<div className={Style.showHtml} ref={testRef}></div>

							{showHtml ? (
								<div
									className={Style.show}
									onClick={() => {
										setShowHtml(false);
										testRef.current.innerHTML = '';
									}}
								>
									Hide Error
								</div>
							) : (
								<div
									className={Style.show}
									onClick={() => {
										setShowHtml(true);
										testRef.current.innerHTML =
											error?.response?.data;
									}}
								>
									Show Error in HTML
								</div>
							)}

							<Button
								className={Style.download}
								onClick={() => {
									downloadFile(
										'error.html',
										error?.response?.data
									);
								}}
							>
								Download error
							</Button>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default ErrorModal;
