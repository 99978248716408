import React, { useContext } from 'react';

// Components
import Logout from '../logout/Logout';
import Notification from '../notification/Notification';

// Libraries
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '@hybris-software/use-auth';

// Context
import { RoutesContext } from '../../../contexts/RoutesContext';

// Hooks
import useText from '../../../hooks/useText';

// Data
import { accountType } from '../../../data/constants';
import config from '../../../data/config';

// Styles
import Style from './MobileMenu.module.css';

const MobileMenu = ({
	openMobile,
	setOpenMobile,
	setOpenNotify,
	openNotify,
	logoutRef,
}) => {
	const texts = useText('menu');

	const navigate = useNavigate();
	const location = useLocation();
	const { paths } = useContext(RoutesContext);
	const { userInfo } = useUser();

	const menuRoutes = [
		{
			name: texts?.dashboard,
			path: paths?.main?.dashboard,
		},
		{
			name: texts?.profile,
			path: paths?.main?.profile,
		},
		{
			name: texts?.email,
			path: paths?.main?.email,
		},
		{
			name: texts?.password,
			path: paths?.main?.password,
		},
		{
			name:
				userInfo?.accountType === accountType.PERSONAL
					? texts?.kyc
					: texts?.kyb,
			path: paths?.main?.kyc,
		},
		{
			name: texts?.security,
			path: paths?.main?.security,
		},
		{
			name: texts?.notifications,
			path: paths?.main?.notification,
		},
		{
			name: texts?.devices,
			path: paths?.main?.devices,
		},
		{
			name: texts?.support,
		},
	];

	return (
		<div
			className={openMobile ? Style.opened : Style.menu}
			style={
				sessionStorage.getItem('inspectorTool') === 'true'
					? { top: 120 }
					: {}
			}
		>
			<div className={Style.main}>
				<div className={Style.notifications}>
					<Notification
						open={openNotify}
						setOpen={setOpenNotify}
						type='mobile'
					/>
					<Logout logoutRef={logoutRef} type='mobile' />
				</div>
				<div className={Style.dropdown}>
					<ul className={Style.responsiveList}>
						{menuRoutes.map((route, index) => {
							return (
								<li
									key={index}
									className={
										route.path === location.pathname
											? Style.active
											: undefined
									}
									onClick={() => {
										setOpenMobile(false);
										if (route.path) {
											navigate(route.path);
										} else {
											window.open(
												config.SUPPORT_LINK,
												'_blank'
											);
										}
									}}
								>
									{route.name}
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default MobileMenu;
