import React, { useContext } from "react";

// Components
import Checkbox from "../../../components/advanced/checkbox/Checkbox";
import ErrorModal from "../errorModal/ErrorModal";

// Libraries
import { useNavigate } from "react-router-dom";
import useQuery from "@hybris-software/use-query";
import useForm from "@hybris-software/use-ful-form";
import { Button } from "@hybris-software/ui-kit";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Data
import endPoints from "../../../data/endpoints";

// Hooks
import useText from "../../../hooks/useText";

// Styles
import Style from "./LogoutModal.module.css";
import UnauthorizedModal from "../unauthorizedModal/UnauthorizedModal";

const LogoutModal = ({ modalRef }) => {
  // Hooks
  const texts = useText("logout");
  const navigate = useNavigate();

  // Contexts
  const { paths } = useContext(RoutesContext);

  const form = useForm({
    inputs: {
      logoutAll: {
        nature: "checkbox",
        value: false,
      },
    },
  });

  const logoutApi = useQuery({
    url: endPoints.logout.LOGOUT,
    method: "POST",
    onSuccess: () => {
      localStorage.removeItem("token");
      sessionStorage.clear();
      modalRef.current.destroy();
      navigate(paths.auth.login);
    },
    onError: (error) => {
      if (error.response?.status === 422 || error.response?.status === 401) {
        return;
      } else {
        modalRef.current.updateBody(
          <ErrorModal error={error} modalRef={modalRef} />
        );
      }
    },
    onUnauthorized: () => {
      modalRef.current.updateBody(
        <UnauthorizedModal errorModalRef={modalRef} />, {
            showCloseIcon: false,
          }
      );
    },
  });

  const logoutAllApi = useQuery({
    url: endPoints.logout.LOGOUTALL,
    method: "POST",
    onSuccess: () => {
      localStorage.removeItem("token");
      sessionStorage.clear();
      modalRef.current.destroy();
      navigate(paths.auth.login);
    },
    onError: (error) => {
      if (error.response?.status === 422 || error.response?.status === 401) {
        return;
      } else {
        modalRef.current.updateBody(
          <ErrorModal error={error} modalRef={modalRef} />
        );
      }
    },
    onUnauthorized: () => {
      modalRef.current.updateBody(
        <UnauthorizedModal errorModalRef={modalRef} />, {
            showCloseIcon: false,
          }
      );
    },
  });

  return (
    <div className={Style.logoutModal}>
      <h5 className={Style.title}>{texts?.title}</h5>
      <p className={Style.info}>{texts?.subtitle}</p>

      <div className={Style.logoutAll}>
        <Checkbox
          checked={form.values["logoutAll"]}
          onChange={(e) =>
            form?.getInputProps("logoutAll").setValue(e.target.checked)
          }
        />
        {texts?.label}
      </div>
      <Button
        className={Style.logoutButton}
        isLoading={logoutAllApi.isLoading || logoutApi.isLoading}
        onClick={() => {
          if (form.values["logoutAll"]) logoutAllApi.executeQuery();
          else logoutApi.executeQuery();
        }}
      >
        {texts?.button}
      </Button>
    </div>
  );
};

export default LogoutModal;
