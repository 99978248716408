import React from "react";

// Hooks
import useText from "../../../hooks/useText";

// Styles
import Style from "./ErrorBanner.module.css";

const ErrorBanner = ({ response, call }) => {
  const texts = useText("errorCodes");

  const errorCodes = {
    login: {
      400: {
        FEATURE_DISABLED: texts?.login?.FEATURE_DISABLED,
        USER_BLOCKED: texts?.login?.USER_BLOCKED,
      },
      401: {
        INVALID_CREDENTIALS: texts?.login?.INVALID_CREDENTIALS,
      },
    },
    loginConfirm: {
      400: {
        FEATURE_DISABLED: texts?.loginConfirm?.FEATURE_DISABLED,
        USER_BLOCKED: texts?.loginConfirm?.USER_BLOCKED,
      },
    },
    register: {
      400: {
        FEATURE_DISABLED: texts?.register?.FEATURE_DISABLED,
        REFERRAL_CODE_ALREADY_SUBMITTED:
          texts?.register?.REFERRAL_CODE_ALREADY_SUBMITTED,
        INVALID_REFERRAL: texts?.register?.INVALID_REFERRAL,
      },
    },
    submitReferral: {
      400: {
        REFERRAL_CODE_ALREADY_SUBMITTED:
          texts?.submitReferral?.REFERRAL_CODE_ALREADY_SUBMITTED,
        INVALID_REFERRAL: texts?.submitReferral?.INVALID_REFERRAL,
      },
    },
    changeMethod: {
      400: {
        OTP_ALREADY_ENABLED:
          texts?.changeMethod?.OTP_ALREADY_ENABLED,
      },
    },
    accountType: {
      400: {
        ACCOUNT_TYPE_ALREADY_SET: texts?.accountType?.ACCOUNT_TYPE_ALREADY_SET,
      },
    },
    changePassword: {
      400: {
        PASSWORD_CHANGE_REQUEST_NOT_FOUND:
          texts?.changePassword?.PASSWORD_CHANGE_REQUEST_NOT_FOUND,
      },
    },
  };

  return (
    response &&
    (response?.status === 400 || response?.status === 401) && (
      <div className={Style.errorBox}>
        {errorCodes[call][response?.status][response?.data?.code]}
      </div>
    )
  );
};

export default ErrorBanner;
