function speedIncrease(num1, num2, num3, duration) {

    let increseNum1 = Math.ceil(1000 / (num1 / duration));
    let increseNum2 = Math.ceil(1000 / (num2 / duration));
    let increseNum3 = Math.ceil(1000 / (num3 / duration));

    return {
        increseNum1,
        increseNum2,
        increseNum3,
    }
}

export default speedIncrease;