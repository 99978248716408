import React from 'react';

// Data
import config from '../../data/config';

// Styles
import Style from './NotFound.module.css';

const NotFound = () => {
	return (
		<div className={Style.center}>
			<img rel='preload' as='image' src={config.LOGO_WHITE} alt='' />
			404
			<div className={Style.info}>Page not Found</div>
		</div>
	);
};

export default NotFound;
