import React, { useContext } from 'react';

// Images
import Bell from '../../../assets/images/bell.svg';

// Contexts
import NotificationContext from '../../../contexts/NotificationContext';

// Styles
import Style from './Notification.module.css';

const Notification = ({ open, setOpen, type }) => {
	const [notificationsList] = useContext(NotificationContext);

	return (
		<div
			className={
				type === 'mobile'
					? Style.notificationMobile
					: Style.notification
			}
		>
			<div className={Style.iconBox} onClick={() => setOpen(true)}>
				{notificationsList.length > 0 && <div className={Style.new} />}
				<img as='image' rel='preload' src={Bell} alt='' />
			</div>
		</div>
	);
};

export default Notification;
