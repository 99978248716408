//Libraries
import { Link } from "react-router-dom";

//Icons
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

// Utils
import classNames from '../../../utils/classNames'

//Styles
import Style from "./StatusResponse.module.css";

const StatusResponse = ({
    icon,
    title,
    description,
    cta = { isRight: true, text: "", to: "" },
    children,
    onClick,
}) => {
    return (
        <div className={Style.successForm}>
            {icon &&
                <div
                    className={Style.iconSuccess}
                    style={{ backgroundImage: `url(${icon})` }}
                />
            }
            <h5>{title}</h5>
            <div className={Style.description}>{description}</div>

            {cta.text && (
                <div
                    className={classNames(
                        Style.goBack,
                        cta.isRight && Style.goBackReverse
                    )}
                >
                    <Link to={cta.to} onClick={onClick}>
                        {cta.isRight ? <BiChevronRight /> : <BiChevronLeft />}
                        {cta.text}
                    </Link>
                </div>
            )}
            {children}
        </div>
    );
};
export default StatusResponse;
