import React from 'react'

// Styles
import Style from './Checkbox.module.css'

const Checkbox = (props) => {
    return (
        <label className={Style.checkboxForm}>
            <input type="checkbox" {...props} />
            <i></i>
        </label>
    )
}

export default Checkbox