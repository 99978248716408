import React, { useState, useEffect } from 'react';

// Components
import SendCodeAgain from '../sendCodeAgain/SendCodeAgain';
import ErrorBanner from '../../../components/advanced/errorBanner/ErrorBanner';
import StatusResponse from '../statusResponse/StatusResponse';

// Libraries
import { Button } from '@hybris-software/ui-kit';
import AuthCode from 'react-auth-code-input';
import useQuery from '@hybris-software/use-query';
import { useOutletContext } from 'react-router-dom';
import { useUser } from '@hybris-software/use-auth';

// Images
import Email from '../../../assets/images/email.png';
import Success from '../../../assets/images/success.webp';
import Autenticor from '../../../assets/images/authenticor.svg';

// Data
import endpoints from '../../../data/endpoints';

// Hooks
import useText from '../../../hooks/useText';

// Styles
import Style from './TwofactorCode.module.css';

const TwofactorCode = ({ otpMethod, errorCall, modalRef }) => {
	// Hooks
	const texts = useText('twoFactor');
	const successTexts = useText('success');
	const { userInfo } = useUser();
	// States
	const [code, setCode] = useState('');
	// Contexts
	const { unauthorizedModalRef } = useOutletContext();

	const confirmChangePasswordApi = useQuery({
		url: endpoints.profile.CONFIRMPASSWORDCHANGE,
		method: 'POST',
		onSuccess: (response) => {
			modalRef.current.updateBody(
				<StatusResponse
					icon={Success}
					title={successTexts?.passwordTitle}
					description={successTexts?.passwordSubtitle}
				>
					<Button
						style={{
							borderRadius: '30px',
							width: '100%',
							maxWidth: '200px',
							marginBottom: '10px',
						}}
						onClick={() => {
							modalRef.current.destroy();
						}}
					>
						{successTexts?.buttonClose}
					</Button>
				</StatusResponse>
			);
		},
		onUnauthorized: () => {
			unauthorizedModalRef.current.open();
		},
	});

	const keyGeneratorConfirmationApi = useQuery({
		url: endpoints.profile.PRIVATE_KEY,
		method: 'POST',
		onSuccess: (response) => {
			modalRef.current.updateBody(
				<StatusResponse
					icon={Success}
					title={successTexts?.keyGeneratorTitle}
					description={successTexts?.keyGeneratorSubtitle}
				>
					<Button
						style={{
							borderRadius: '30px',
							width: '100%',
							maxWidth: '200px',
							marginBottom: '10px',
						}}
						onClick={() => {
							modalRef.current.destroy();
						}}
					>
						{successTexts?.buttonClose}
					</Button>
				</StatusResponse>
			);
		},
		onUnauthorized: () => {
			unauthorizedModalRef.current.open();
		},
	});

	const resendOtpApi = useQuery({
		url: endpoints.otp.RESENDOTP,
		method: 'POST',
	});

	const resendCode = {
		changePassword: 'change_password',
	};

	const apiCalls = {
		changePassword: confirmChangePasswordApi,
		keyGenerator: keyGeneratorConfirmationApi,
	};

	useEffect(() => {
		if (code.length === 6)
			apiCalls[errorCall].executeQuery({
				otp: code,
			});
		//eslint-disable-next-line
	}, [code]);

	return (
		<div className={Style.twofactor}>
			{otpMethod === 'EMAIL' ? (
				<div className={Style.email}>
					<img as='image' rel='preload' src={Email} alt='' />
					<h5 className={Style.title}>{texts?.verifyIdentity}</h5>
					<p className={Style.info}>
						{texts?.checkEmail} {userInfo?.email}
					</p>
					<div className={Style.form}>
						<AuthCode
							allowedCharacters='numeric'
							onChange={(e) => {
								setCode(e);
							}}
						/>
					</div>
					<SendCodeAgain
						counter={15}
						onClick={() =>
							resendOtpApi.executeQuery({
								otpAction: resendCode[errorCall],
							})
						}
					/>
				</div>
			) : (
				<div className={Style.email}>
					<img as='image' rel='preload' src={Autenticor} alt='' />
					<h5 className={Style.title}>{texts?.verifyIdentity}</h5>
					<p className={Style.info}>
						{texts?.verifyAuth} <strong>{texts?.nameAuth}</strong>{' '}
						{texts?.verifyAuthCode}
					</p>
					<div className={Style.form}>
						<AuthCode
							allowedCharacters='numeric'
							onChange={(e) => {
								setCode(e);
							}}
						/>
					</div>
				</div>
			)}

			<ErrorBanner
				response={apiCalls[errorCall].error?.response}
				call={errorCall}
			/>

			{apiCalls[errorCall].isError &&
				apiCalls[errorCall].error?.response?.status === 422 && (
					<div className={Style.errorBox}>
						{apiCalls[errorCall].error?.response?.data?.otp}
					</div>
				)}

			<Button
				style={
					otpMethod === 'EMAIL'
						? { marginTop: '20px' }
						: { marginTop: '0' }
				}
				className={Style.verify}
				disabled={code?.length !== 6}
				isLoading={apiCalls[errorCall].isLoading}
				onClick={() => {
					apiCalls[errorCall].executeQuery({
						otp: code,
					});
				}}
			>
				{texts?.button}
			</Button>
		</div>
	);
};

export default TwofactorCode;
