import React from 'react'

// Hooks
import useText from '../../../../../hooks/useText'
import useTypeNotification from '../../../../../hooks/useTypeNotification'

// Styles
import Style from './Activities.module.css'

const Activities = ({ response }) => {

    const texts = useText("dashboardView")?.activities

    return (
        <div className={Style.card}>
            <h5 className={Style.title}>{texts?.title}</h5>
            <div className={Style.list}>
                {response?.results?.length > 0
                    ? response?.results?.map((item, i) => {
                        if (i > 9) return null
                        return (
                            <SingleNotification key={i} {...item} />
                        )
                    })
                    : <div className={Style.empty}>{texts?.empty}</div>
                }
            </div>
        </div>
    )
}

const SingleNotification = ({ type, createdAt }) => {

    const { notificationTypes, notificationTypesTexts, notificationTime } = useTypeNotification()

    return (
        <div className={Style.item}>
            <div className={Style.type}>
                <div className={Style.icon}>
                    <img as="image" rel="preload" src={notificationTypes(type)} alt="" height={24} />
                </div>
            </div>
            <div className={Style.text}>
                {notificationTypesTexts(type)}
                <div className={Style.time}>{notificationTime(createdAt)}</div>
            </div>
        </div>
    )
}

export default Activities