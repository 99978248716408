import { useContext } from "react";

import texts from "../data/texts";

import { LanguageContext } from "../contexts/LanguageContext";

const useText = (viewName) => {
    const { language } = useContext(LanguageContext);

    if (viewName) {
        return texts[language][viewName];
    } else {
        return texts[language];
    }
};

export default useText;

