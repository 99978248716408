import React from "react";

// Components
import NotFound from "./components/notFound/NotFound";

// Libraries
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Data
import { routeComponents, paths, sidebarRoutes } from "./data/routes";

// Contexts
import { RoutesContext } from "./contexts/RoutesContext";
import { LanguageProvider } from "./contexts/LanguageContext";

function App() {
  return (
    <LanguageProvider>
      <RoutesContext.Provider value={{ paths, sidebarRoutes }}>
        <BrowserRouter>
          <Routes>
            {routeComponents}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </RoutesContext.Provider>
    </LanguageProvider>
  );
}

export default App;
