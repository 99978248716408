import React, { useEffect, useContext, useState } from 'react';

// Components
import ErrorModal from '../../../components/advanced/errorModal/ErrorModal';
import LoaderElement from '../../../components/ui/loaderElement/LoaderElement';
import Tooltip from '../../../components/advanced/tooltip/Tooltip';

// Libraries
import { Button } from '@hybris-software/ui-kit';
import { useNavigate, useOutletContext } from 'react-router-dom';
import useQuery from '@hybris-software/use-query';
import { PermissionRoute } from '@hybris-software/use-auth';
import QRCode from 'react-qr-code';
import OtpInput from 'react18-input-otp';

// Hooks
import useText from '../../../hooks/useText';

// Svgs
import Copy from '../../../assets/svgs/Copy';

// Data
import { otpMethods, steps } from '../../../data/constants';
import endpoints from '../../../data/endpoints';
import config from '../../../data/config';

// Contexts
import { RoutesContext } from '../../../contexts/RoutesContext';

// Utils
import dispatcher from '../../../utils/dispatcher';
import {
	getUrlParams,
	navigateShape,
	navigateWithQueryParams,
} from '../../../utils/utilityFunctions';

// Styles
import Style from './ChangeOTPMethod.module.css';
import Theme from '../../../assets/css/theme.module.css';
import ErrorBanner from '../../../components/advanced/errorBanner/ErrorBanner';

const ChangeOTPMethod = () => {
	// States
	const [otp, setOtp] = useState(0);
	// Handlers & Utils
	const handleOtpInputChange = (otp) => setOtp(otp);
	const urlParams = getUrlParams();

	// Context
	const { errorModalRef } = useOutletContext();

	// Queries
	const getOTPCode = useQuery({
		url: endpoints.otp.OTP_SETTINGS,
		method: 'GET',
		executeImmediately: true,
	});

	const setOTPMethodAPI = useQuery({
		url: endpoints.otp.OTP_SETTINGS,
		method: 'POST',
		executeImmediately: false,
		onSuccess: (response) => {
			if (urlParams[config.LOGIN_URL_PARAMS]) {
				navigateWithQueryParams(
					navigate,
					paths.auth.authRedirect,
					urlParams
				);
			} else {
				navigate(paths.auth.authRedirect);
			}
		},
		onError: (error) => {
			if (error.response.status === 422) {
				return true;
			} else if (
				error.response?.status !== 400 &&
				error.response?.status !== 422 &&
				error.response?.status !== 401
			) {
				errorModalRef.current.updateBody(
					<ErrorModal error={error} modalRef={errorModalRef} />
				);
			}
		},
	});

	// Variables
	const otpErrorMessage = setOTPMethodAPI.isError &&
		setOTPMethodAPI.error.response.status === 422 && {
			googleAuthOtp: (
				<div className={Style.otpErrorMessage}>
					{(setOTPMethodAPI.error.response.data['totpOtp'] && (
						<>Invalid Google Authenticator OTP Code</>
					)) ||
						''}
				</div>
			),
		};

	// Hooks
	const texts = useText('changeOTPMethod');
	const copy = useText('copyTooltip');
	const navigate = useNavigate();

	// Contexts
	const { paths } = useContext(RoutesContext);

	useEffect(() => {
		document.title = `${config.PLATFORM_NAME} | ${texts?.pageTitle}`;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (otp.length === 6) {
			setOTPMethodAPI.executeQuery({ totpOtp: otp });
		}
		// eslint-disable-next-line
	}, [otp]);

	return (
		<PermissionRoute
			forLoggedUser={true}
			minimumLoadingTime={500}
			loader={<LoaderElement />}
			apiLoading={getOTPCode.isLoading}
			unAuthorizedAction={() => {
				navigateShape(navigate, paths.auth.login);
			}}
			permissionController={(response) => {
				dispatcher(response.data, navigate, paths, [
					steps.REFERRAL_CODE,
					steps.ACCOUNT_TYPE,
					steps.TERMS,
				]);
				if (response.data.otpMethod === otpMethods.EMAIL) {
					return true;
				} else {
					navigateShape(navigate, paths.auth.authRedirect);
					return;
				}
			}}
		>
			<div className={Style.card}>
				<div className={Style.section}>
					<div>
						<h4 className={Style.title}>{texts?.title}</h4>
						<p className={Style.desc}>{texts?.desc}</p>
						<ol class={Style.steps}>
							<li className={Style.step}>{texts?.stepOne}</li>
							<li className={Style.step}>{texts?.stepTwo}</li>
							<li className={Style.step}>{texts?.stepThree}</li>
						</ol>
					</div>
				</div>
				<div className={Style.section}>
					<ErrorBanner
						response={setOTPMethodAPI.error?.response}
						call='changeMethod'
					/>
				</div>

				<div className={Style.section}>
					<div>
						{getOTPCode?.response?.data?.url && (
							<QRCode
								className={Style.qrCode}
								value={getOTPCode?.response?.data?.url}
							/>
						)}
					</div>
				</div>
				<div>
					<div className={Style.copySecret}>
						{texts?.secret}
						<Tooltip
							valueToCopy={getOTPCode?.response?.data?.secret}
							copyIcon={<Copy />}
							textCopy={copy?.copied}
						/>
					</div>
					<div className={Style.section}>
						<div>
							<OtpInput
								value={otp}
								onChange={handleOtpInputChange}
								containerStyle={Theme.otpContainer}
								inputStyle={Theme.otpInputStyle}
								focusStyle={Theme.otpFocusStyle}
								errorStyle={Theme.otpErrorStyle}
								numInputs={6}
								isInputNum
								shouldAutoFocus={true}
							/>
							<p>{otpErrorMessage.googleAuthOtp}</p>
						</div>
					</div>
				</div>
				<div className={Style.section}>
					<Button
						isLoading={setOTPMethodAPI.isLoading}
						className={Style.button}
						disabled={
							!(otp?.length === 6) || setOTPMethodAPI.isLoading
						}
						onClick={() => {
							setOTPMethodAPI.executeQuery({ totpOtp: otp });
						}}
					>
						{texts?.action}
					</Button>
				</div>
			</div>
		</PermissionRoute>
	);
};

export default ChangeOTPMethod;
