import React, { useEffect, useContext, useRef } from "react";

// Components
import ErrorBanner from "../../../components/advanced/errorBanner/ErrorBanner";
import ErrorModal from "../../../components/advanced/errorModal/ErrorModal";
import SelectComponent from "../../../components/advanced/SelectComponent/SelectComponent";
// Libraries
import useForm from "@hybris-software/use-ful-form";
import { PermissionRoute } from "@hybris-software/use-auth";
import { InputField, Button, useWindowSize } from "@hybris-software/ui-kit";
import { useNavigate, useOutletContext } from "react-router-dom";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import useQuery from "@hybris-software/use-query";

// Hooks
import useText from "../../../hooks/useText";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Utils
import validatePassword from "../../../utils/passwordValidator";
import updateFormError from "../../../utils/updateFormError";
import countryList from "../../../utils/countryList";

// Data
import config from "../../../data/config";
import endpoints from "../../../data/endpoints";

// Icons
import { FiAlertTriangle } from "react-icons/fi";

// Styles
import Style from "./RegisterView.module.css";

const RegisterView = () => {
  // Variables
  const params = new URLSearchParams(window.location.search);
  const refCode = params.get("refcode");

  // Hooks
  const texts = useText("registerView");
  const inputTexts = useText("inputs");
  const { width } = useWindowSize();
  const navigate = useNavigate();
  // Ref
  const captchaRef = useRef(null);
  const { errorModalRef } = useOutletContext();

  // Contexts
  const { paths } = useContext(RoutesContext);

  const validateConfirmEmail = (value, values) => {
    if (value !== values["email"]) {
      return [false, "Email do not match"];
    } else {
      return [true, null];
    }
  };

  const form = useForm({
    inputs: {
      username: {
        required: true,
        validator: (value) => {
          if (
            value === null ||
            value === undefined ||
            value.trim() === "" ||
            value === ""
          )
            return [false, inputTexts?.mandatory];
          return [true, ""];
        },
        formatter: (value) => {
          return value.replace(/[^a-zA-Z0-9.+_-]/g, "")?.toLowerCase();
        },
      },
      email: {
        required: true,
        nature: "email",
        errorOnEveryChange: true,
        validator: (value) => {
          const re =
            //eslint-disable-next-line
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!value || value === "") return [false, inputTexts?.mandatory];
          if (!re.test(String(value).toLowerCase()))
            return [false, inputTexts?.invalidEmail];
          return [true, ""];
        },
      },
      emailConfirm: {
        required: true,
        nature: "email",
        validator: validateConfirmEmail,
        sendToApi: false,
      },
      referralUpline: {
        required: true,
        value: refCode !== null ? refCode : "",
      },
      country: {
        required: true,
      },
      password: {
        nature: "password",
        errorOnEveryChange: true,
        validator: validatePassword,
      },
      confirmPassword: {
        nature: "confirmPassword",
        validator: (value, values) => {
          if (!value || value === "") return [false, inputTexts?.mandatory];
          if (value !== values["password"])
            return [false, inputTexts?.notMatch];
          else return [true, ""];
        },
      },
      check: {
        required: true,
        nature: "checkbox",
      },
      captchaToken: {
        required: true,
      },
    },
  });

  const platformConfigApi = useQuery({
    url: endpoints.auth.PLATFORMCONFIG,
    method: "GET",
    executeImmediately: true,
  });

  const registerApi = useQuery({
    url: endpoints?.auth?.REGISTER,
    method: "POST",
    onError: (error) => {
      captchaRef.current.resetCaptcha();

      if (error.response?.status === 422) {
        updateFormError(error.response?.data, form);
      } else if (
        error.response?.status !== 400 &&
        error.response?.status !== 422 &&
        error.response?.status !== 401
      ) {
        errorModalRef.current.updateBody(
          <ErrorModal error={error} modalRef={errorModalRef} />
        );
      }
    },
  });

  const countriesApi = useQuery({
    url: endpoints.auth.COUNTRIES,
    method: "GET",
    executeImmediately: true,
  });

  useEffect(() => {
    document.title = `${config.PLATFORM_NAME} | ${texts?.pageTitle}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PermissionRoute
      // loader={<LoaderAuth />}
      forLoggedUser={false}
      minimumLoadingTime={500}
      apiLoading={platformConfigApi.isLoading || countriesApi.isLoading}
      unAuthorizedAction={() => {
        navigate(paths.auth.authRedirect);
      }}
      permissionController={(response) => {
        return true;
      }}
    >
      {registerApi.isSuccess ? (
        <div className={Style.card}>
          <div className={Style.success}>
            <h4 className={Style.title}>{texts?.registrationDone}</h4>
            <div className={Style.reminder}>
              {texts?.registrationDoneSubtitle}
            </div>
            <Button
              className={Style.buttonDone}
              onClick={() => {
                navigate(paths?.auth?.login);
              }}
            >
              {texts?.registrationDoneButton}
            </Button>
          </div>
        </div>
      ) : (
        <div className={Style.card}>
          <h4 className={Style.title}>{texts?.title}</h4>
          <div className={Style.reminder}>{texts?.subtitle}</div>

          {platformConfigApi.response?.data?.registrationEnabled === false && (
            <div className={Style.errorBox}>{texts?.registrationDisabled}</div>
          )}

          <ErrorBanner response={registerApi.error?.response} call="register" />

          <div className={Style.line}>
            <InputField
              label={texts?.labelUsername}
              placeholder={texts?.placeholderUsername}
              errorIconVisibility
              errorIcon={<FiAlertTriangle color="var(--error)" />}
              {...form.getInputProps("username")}
            />

            <InputField
              label={texts?.labelEmail}
              placeholder={texts?.placeholderEmail}
              errorIconVisibility
              onPaste={false}
              errorIcon={<FiAlertTriangle color="var(--error)" />}
              {...form.getInputProps("email")}
            />
          </div>

          <InputField
            label={texts?.labelEmailConfirm}
            placeholder={texts?.placeholderEmailConfirm}
            onCopy={false}
            onPaste={false}
            errorIconVisibility
            errorIcon={<FiAlertTriangle color="var(--error)" />}
            {...form.getInputProps("emailConfirm")}
          />

          <div className={Style.country}>
            <div className={Style.label}>{texts?.labelCountry}</div>

            <SelectComponent
              placeholder={texts?.placeholderCountry}
              maxHeightOpened={220}
              items={countriesApi?.response?.data?.filter((country) => {
                return country?.disabled === false;
              })}
              searchKey="name"
              renderOption={(item, fullItem) => (
                <div className={Style.option}>
                  <span className={Style.flag}>
                    <img
                      alt=""
                      style={{
                        height: "20px",
                        width: "20px",
                      }}
                      src={`data:image/svg+xml;utf8,${encodeURIComponent(
                        countryList?.find((item) => item.code === fullItem.code)
                          ?.flag
                      )}`}
                    />
                  </span>
                  {fullItem.name}
                </div>
              )}
              value={countriesApi?.response?.data?.find(
                (item) => item.code === form.values["country"]
              )}
              setValue={(value) => {
                form.getInputProps("country").setValue(value?.code);
              }}
            />
          </div>

          <InputField
            label={texts?.labelReferral}
            placeholder={texts?.placeholderReferral}
            {...form.getInputProps("referralUpline")}
          />

          <InputField
            label={texts?.labelPassword}
            placeholder={texts?.placeholderPassword}
            type="password"
            showPasswordIconAlwaysMobile
            {...form.getInputProps("password")}
          />

          <InputField
            label={texts?.labelConfirmPassword}
            placeholder={texts?.placeholderPassword}
            type="password"
            showPasswordIconAlwaysMobile
            {...form.getInputProps("confirmPassword")}
          />

          <div className={Style.remember}>
            <input
              type="checkbox"
              id="remember"
              value={form.values["check"]}
              onChange={(e) =>
                form.getInputProps("check").setValue(e.target.checked)
              }
            />
            <div>
              <label htmlFor="remember">{texts?.rememberLabel}</label>
              <a
                href="https://docs.2access.io/"
                rel="noopener noreferrer"
                target="_blank"
              >
                {texts?.termsLabel}
              </a>
            </div>
          </div>

          <div className={Style.captcha}>
            <HCaptcha
              size={width > 575 ? "normal" : "compact"}
              sitekey={config.CAPTCHA_SITE_KEY}
              languageOverride="en"
              onVerify={(value) => {
                form.getInputProps("captchaToken").setValue(value);
              }}
              onExpire={() => form.getInputProps("captchaToken").setValue(null)}
              ref={captchaRef}
            />
          </div>

          <Button
            className={Style.button}
            disabled={!form.isValid()}
            isLoading={registerApi.isLoading}
            onClick={() => {
              registerApi.executeQuery(form.getApiBody());
              form.getInputProps("captchaToken").setValue(null);
            }}
          >
            {texts?.button}
          </Button>

          <div
            className={Style.forgot}
            onClick={() => {
              navigate(paths?.auth?.forgotPassword);
            }}
          >
            {texts?.forgotPassword}
          </div>
        </div>
      )}
    </PermissionRoute>
  );
};

export default RegisterView;
