import React, { useEffect, useRef, useState } from 'react';

// Components
import Checkbox from '../../../../../components/advanced/checkbox/Checkbox';
import ErrorModal from '../../../../../components/advanced/errorModal/ErrorModal';
import StatusResponse from '../../../../../components/advanced/statusResponse/StatusResponse';

// Libraries
import useForm from '@hybris-software/use-ful-form';
import { Button, InputField, useWindowSize } from '@hybris-software/ui-kit';
import { useUser } from '@hybris-software/use-auth';
import { useOutletContext } from 'react-router-dom';
import useQuery from '@hybris-software/use-query';

// Images
import Success from '../../../../../assets/images/success.webp';

// Hooks
import useText from '../../../../../hooks/useText';

// Data
import endpoints from '../../../../../data/endpoints';

// Utils
import updateFormError from '../../../../../utils/updateFormError';

// Styles
import Style from './SecurityCode.module.css';

const SecurityCode = ({ modalRef }) => {
	// Hooks
	const texts = useText('securityView')?.securityCode;
	const successTexts = useText('success');
	const inputTexts = useText('inputs');
	const { width } = useWindowSize();
	const { userInfo } = useUser();
	// Refs
	const qrRef = useRef(null);
	// States
	const [showQr, setShowQr] = useState(false);
	const [codeExists, setCodeExists] = useState(
		userInfo?.securityCode ? true : false
	);
	// Contexts
	const { unauthorizedModalRef, errorModalRef } = useOutletContext();

	const form = useForm({
		inputs: {
			security: {
				nature: 'checkbox',
				value: userInfo?.securityCode ? true : false,
			},
			securityCode: {
				required: true,
				value: userInfo?.securityCode || '',
				errorOnEveryChange: true,
				formatter: (value) => {
					if (!userInfo?.securityCode && value === '')
						return value.replace(/[^0-9]/g, '').slice(0, 8);
					else return value.slice(0, 8);
				},
				validator: (value) => {
					if (value.length !== 8) {
						return [false, inputTexts?.securityCode];
					}
					return [true, ''];
				},
			},
		},
	});

	const setSecurityCode = useQuery({
		url: endpoints.securityCode.UPDATESECURITYCODE,
		method: 'POST',
		onSuccess: () => {
			setCodeExists(true);
			modalRef.current.updateBody(
				<StatusResponse
					icon={Success}
					title={successTexts?.securityCodeTitle}
					description={successTexts?.securityCodeSubtitle}
				>
					<Button
						style={{
							borderRadius: '30px',
							width: '100%',
							maxWidth: '200px',
							marginBottom: '10px',
						}}
						onClick={() => {
							modalRef.current.destroy();
						}}
					>
						{successTexts?.buttonClose}
					</Button>
				</StatusResponse>
			);
		},
		onError: (error) => {
			if (error.response.status === 422) {
				updateFormError(error.response.data, form);
			} else
				errorModalRef.current.updateBody(
					<ErrorModal error={error} modalRef={errorModalRef} />
				);
		},
		onUnauthorized: () => {
			unauthorizedModalRef.current.open();
		},
	});

	useEffect(() => {
		setShowQr(form.values['security']);
		//eslint-disable-next-line
	}, [form.values['security']]);

	useEffect(() => {
		qrRef.current.style.maxHeight = showQr
			? `${qrRef?.current?.scrollHeight}px`
			: '0px';
	}, [showQr, codeExists, width]);

	return (
		<div className={Style.security}>
			<div className={Style.box}>
				<div className={Style.header}>
					<div>
						<h5>{texts?.title}</h5>
					</div>
					{codeExists === true && (
						<div
							className={Style.reset}
							onClick={() => {
								form.getInputProps('securityCode').setValue('');
								setCodeExists(false);
							}}
						>
							{texts?.resetButton}
						</div>
					)}
				</div>
				<p className={Style.subtitle}>{texts?.subheading}</p>
				<div className={Style.grid}>
					<p className={Style.info}>{texts?.subtitle}</p>
					<Checkbox
						checked={form.values['security']}
						onChange={(e) => {
							!userInfo?.securityCode &&
								form
									?.getInputProps('security')
									.setValue(e.target.checked);
						}}
					/>
				</div>

				<div ref={qrRef} className={Style.active}>
					<div className={Style.divider} />
					<p className={Style.label}>{texts?.labelCode}</p>

					<div className={Style.codeBox}>
						<InputField
							readOnly={codeExists}
							maxLength={8}
							placeholder={texts?.placeholderCode}
							{...form.getInputProps('securityCode')}
						/>
					</div>
					{codeExists === false && (
						<div className={Style.buttons}>
							<Button
								className={Style.verifyButton}
								disabled={
									form.values['securityCode'].length !== 8
								}
								isLoading={setSecurityCode.isLoading}
								onClick={() => {
									setSecurityCode.executeQuery(
										form.getApiBody()
									);
								}}
							>
								{texts?.saveButton}
							</Button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default SecurityCode;
