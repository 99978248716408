import React, { useEffect, useContext } from 'react';

// Components
import ErrorModal from '../../../components/advanced/errorModal/ErrorModal';
import ErrorBanner from '../../../components/advanced/errorBanner/ErrorBanner';

// Libraries
import useForm from '@hybris-software/use-ful-form';
import { InputField, Button } from '@hybris-software/ui-kit';
import useQuery from '@hybris-software/use-query';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { PermissionRoute } from '@hybris-software/use-auth';

// Hooks
import useText from '../../../hooks/useText';

// Data
import endpoints from '../../../data/endpoints';

// Utils
import updateFormError from '../../../utils/updateFormError';
import { navigateShape } from '../../../utils/utilityFunctions';

// Contexts
import { RoutesContext } from '../../../contexts/RoutesContext';

// Styles
import Style from './ReferralCodeView.module.css';
import config from '../../../data/config';

const ReferralCodeView = () => {
	// Hooks
	const texts = useText('referralCodeView');
	const inputTexts = useText('inputs');
	const navigate = useNavigate();
	// Contexts
	const { errorModalRef, unauthorizedModalRef } = useOutletContext();
	const { paths } = useContext(RoutesContext);

	const form = useForm({
		inputs: {
			referralUpline: {
				validator: (value) => {
					if (
						value === null ||
						value === undefined ||
						value.trim() === '' ||
						value === ''
					)
						return [false, inputTexts?.mandatory];
					return [true, ''];
				},
			},
		},
	});

	const submitReferralApi = useQuery({
		url: endpoints.auth.SUBMITREFERRAL,
		method: 'POST',
		onSuccess: (response) => {
			navigateShape(navigate, paths.auth.authRedirect);
		},
		onError: (error) => {
			if (error.response.status === 422) {
				updateFormError(error.response.data, form);
			} else if (
				error.response?.status !== 400 &&
				error.response?.status !== 422 &&
				error.response?.status !== 401
			) {
				errorModalRef.current.open(
					<ErrorModal error={error} modalRef={errorModalRef} />
				);
			}
		},
		onUnauthorized: () => {
			unauthorizedModalRef.current.open();
		},
	});

	const skipReferralApi = useQuery({
		url: endpoints.auth.SKIPREFERRAL,
		method: 'POST',
		onSuccess: (response) => {
			navigateShape(navigate, paths.auth.authRedirect);
		},
		onError: (error) => {
			if (
				error.response?.status !== 400 &&
				error.response?.status !== 422 &&
				error.response?.status !== 401
			) {
				errorModalRef.current.updateBody(
					<ErrorModal error={error} modalRef={errorModalRef} />
				);
			}
		},
		onUnauthorized: () => {
			unauthorizedModalRef.current.open();
		},
	});

	useEffect(() => {
		document.title = `${config.PLATFORM_NAME} | ${texts?.pageTitle}`;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<PermissionRoute
			// loader={<LoaderAuth />}
			forLoggedUser={true}
			minimumLoadingTime={500}
			unAuthorizedAction={() => {
				navigateShape(navigate, paths.auth.login);
			}}
			permissionController={(response) => {
				if (!response.data.referralSubmitted) return true;
				else {
					navigate(paths.auth.authRedirect);
					return;
				}
			}}
		>
			<div className={Style.card}>
				<h4 className={Style.title}>{texts?.title}</h4>
				<div className={Style.reminder}>{texts?.subtitle}</div>

				<ErrorBanner
					response={submitReferralApi.error?.response}
					call='submitReferral'
				/>

				<InputField
					label={texts?.labelReferral}
					placeholder={texts?.placeholderReferral}
					{...form.getInputProps('referralUpline')}
				/>

				<Button
					className={Style.button}
					disabled={!form.isValid()}
					isLoading={
						submitReferralApi.isLoading || skipReferralApi.isLoading
					}
					onClick={() => {
						submitReferralApi.executeQuery(form.getApiBody());
					}}
				>
					{texts?.button}
				</Button>

				<div className={Style.fillFormLabel}>
					{texts?.doNotHaveReferral}
				</div>
				<Button
					className={Style.buttonFillForm}
					onClick={() => {
						window.open(
							`${config.REQUEST_REFERRAL_CODE_FORM_URL} `,
							'_blank'
						);
					}}
				>
					{texts?.buttonFillForm}
				</Button>
			</div>
		</PermissionRoute>
	);
};

export default ReferralCodeView;
