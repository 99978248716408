import React, { useEffect, useState, useContext } from 'react';

// Components
import ErrorModal from '../../../components/advanced/errorModal/ErrorModal';
import ErrorBanner from '../../../components/advanced/errorBanner/ErrorBanner';

// Libraries
import { Button } from '@hybris-software/ui-kit';
import useQuery from '@hybris-software/use-query';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { PermissionRoute } from '@hybris-software/use-auth';

// Hooks
import useText from '../../../hooks/useText';

// Data
import endpoints from '../../../data/endpoints';
import config from '../../../data/config';

// Utils
import dispatcher from '../../../utils/dispatcher';
import { navigateShape } from '../../../utils/utilityFunctions';

// Contexts
import { RoutesContext } from '../../../contexts/RoutesContext';

// Styles
import Style from './ProfileTypeView.module.css';

const ProfileTypeView = () => {
	// Hooks
	const texts = useText('profileTypeView');
	const navigate = useNavigate();
	// States
	const [type, setType] = useState('');
	// Contexts
	const { errorModalRef, unauthorizedModalRef } = useOutletContext();
	const { paths } = useContext(RoutesContext);

	const accountTypeApi = useQuery({
		url: endpoints.auth.ACCOUNTTYPE,
		method: 'POST',
		onSuccess: (response) => {
			navigateShape(navigate, paths.auth.authRedirect);
		},
		onError: (error) => {
			if (
				error.response?.status !== 400 &&
				error.response?.status !== 422 &&
				error.response?.status !== 401
			) {
				errorModalRef.current.open(
					<ErrorModal error={error} modalRef={errorModalRef} />
				);
			}
		},
		onUnauthorized: () => {
			unauthorizedModalRef.current.open();
		},
	});

	useEffect(() => {
		document.title = `${config.PLATFORM_NAME} | ${texts?.pageTitle}`;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<PermissionRoute
			// loader={<LoaderAuth />}
			forLoggedUser={true}
			minimumLoadingTime={500}
			unAuthorizedAction={() => {
				navigateShape(navigate, paths.auth.login);
			}}
			permissionController={(response) => {
				dispatcher(response.data, navigate, paths, [0]);
				if (!response.data.accountType) return true;
				else {
					navigateShape(navigate, paths.auth.authRedirect);
					return;
				}
			}}
		>
			<div className={Style.card}>
				<h4 className={Style.title}>{texts?.title}</h4>
				<div className={Style.reminder}>{texts?.subtitle}</div>

				<ErrorBanner
					response={accountTypeApi.error?.response}
					call='accountType'
				/>

				{accountTypeApi.isError &&
					accountTypeApi.error?.response?.status === 422 && (
						<div className={Style.errorBox}>
							{accountTypeApi.error?.response?.data?.message ||
								accountTypeApi.error?.response?.data
									?.accountType}
						</div>
					)}

				{/* //todo: description profile personal and company */}
				<div
					className={
						type === 'PERSONAL' ? Style.selected : Style.type
					}
					onClick={() => setType('PERSONAL')}
				>
					<div
						className={
							type === 'PERSONAL' ? Style.radioCheck : Style.radio
						}
					>
						<div className={Style.center}></div>
					</div>
					<div className={Style.information}>
						<h5>{texts?.personalTitle}</h5>
						<div className={Style.text}>
							{texts?.personalSubtitle}
						</div>
					</div>
				</div>

				<div
					className={
						type === 'BUSINESS' ? Style.selected : Style.type
					}
					onClick={() => setType('BUSINESS')}
				>
					<div
						className={
							type === 'BUSINESS' ? Style.radioCheck : Style.radio
						}
					>
						<div className={Style.center}></div>
					</div>
					<div className={Style.information}>
						<h5>{texts?.companyTitle}</h5>
						<div className={Style.text}>
							{texts?.companySubtitle}
						</div>
					</div>
				</div>

				<Button
					className={Style.button}
					disabled={type === ''}
					isLoading={accountTypeApi.isLoading}
					onClick={() => {
						accountTypeApi.executeQuery({
							accountType: type,
						});
					}}
				>
					{texts?.button}
				</Button>
			</div>
		</PermissionRoute>
	);
};

export default ProfileTypeView;
