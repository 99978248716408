import React, { useState } from 'react';

// Components
import ErrorModal from '../../../components/advanced/errorModal/ErrorModal';
import StatusResponse from '../../../components/advanced/statusResponse/StatusResponse';

// Libraries
import useQuery from '@hybris-software/use-query';
import { Button } from '@hybris-software/ui-kit';
import { useUser } from '@hybris-software/use-auth';
import { useOutletContext } from 'react-router-dom';
import Compressor from 'compressorjs';

// Icons
import { GoPencil } from 'react-icons/go';
import { ImBlocked } from 'react-icons/im';
import { IoCheckmarkDoneCircle } from 'react-icons/io5';

// Images
import AvatarPlaceholder from '../../../assets/images/placeholder.jpg';
import Success from '../../../assets/images/success.webp';

// Data
import config from '../../../data/config';
import endpoints from '../../../data/endpoints';

// Hooks
import useText from '../../../hooks/useText';

// Styles
import Style from './ChangeAvatar.module.css';

const ChangeAvatar = ({ modalRef }) => {
	// Hooks
	const texts = useText('changeAvatar');
	const successTexts = useText('success');

	const { userInfo, refreshUserInfo } = useUser();
	const { errorModalRef, unauthorizedModalRef } = useOutletContext();

	// States
	const [image, updateImage] = useState({
		image: null,
		profilePicture: null,
	});
	const [uploaded, setUploaded] = useState({
		progress: '',
		total: '',
		loaded: '',
	});
	const [selectedImage, setSelectedImage] = useState(null);
	const convertedFileSize = (config.MAX_FILE_SIZE / (1024 * 1024))?.toFixed(
		2
	);

	function acceptedImageExtension(file_name) {
		const string = file_name?.name?.toLowerCase();

		const image_extensions = ['gif', 'jpg', 'jpeg', 'png', 'webp', 'heic'];

		const extension = string?.split('.').pop();

		if (image_extensions.includes(extension)) return true;
		else return false;
	}

	const clientSideValidation = acceptedImageExtension(selectedImage);

	const updateProfilePic = useQuery({
		url: endpoints.profile.UPDATEPROFILE,
		method: 'POST',
		executeImmediately: false,
		onSuccess: () => {
			modalRef.current.updateBody(
				<StatusResponse
					icon={Success}
					title={successTexts?.avatarTitle}
					description={successTexts?.avatarSubtitle}
				>
					<Button
						style={{
							borderRadius: '30px',
							width: '100%',
							maxWidth: '200px',
							marginBottom: '10px',
						}}
						onClick={() => {
							refreshUserInfo();
							modalRef.current.destroy();
						}}
					>
						{successTexts?.buttonClose}
					</Button>
				</StatusResponse>,
				{
					onCloseIconClick: () => {
						refreshUserInfo();
					},
				}
			);
		},
		onError: (error) => {
			modalRef.current.destroy();
			errorModalRef.current.updateBody(
				<ErrorModal
					error={error}
					modalRef={errorModalRef}
					description={error?.response?.data?.profilePicture}
				/>
			);
		},
		onUnauthorized: () => {
			unauthorizedModalRef.current.open();
		},
		clientOptions: {
			timeout: 30000,
			headers: {
				'content-type': 'multipart/form-data',
			},
			onUploadProgress: (progressEvent) => {
				const progress =
					(progressEvent.loaded / progressEvent.total) * 100;
				setUploaded({
					progress: progress,
					loaded: progressEvent.loaded,
					total: progressEvent.total,
				});
			},
		},
	});

	return !updateProfilePic.isLoading ? (
		<div className={Style.main}>
			<h5 className={Style.title}>{texts?.title}</h5>
			<div
				className={Style.background}
				style={{
					backgroundImage: image.profilePicture
						? `url(${image.profilePicture})`
						: userInfo.profilePicture
						? `url(${userInfo.profilePicture})`
						: `url(${AvatarPlaceholder})`,
				}}
			>
				<label htmlFor='banner' className={Style.label}>
					<GoPencil />
					<input
						type='file'
						accept='.png, .jpeg, .jpg , .gif , .webp , .heic'
						id='banner'
						className={Style.inputPic}
						onChange={(e) => {
							new Compressor(e.target.files[0], {
								quality: 0.8,
								convertTypes: [
									'image/png',
									'image/jpeg',
									'image/jpg',
									'image/gif',
									'image/webp',
									'image/heic',
								],
								success(result) {
									var file = new File(
										[result],
										e.target.files[0].name
									);
									setSelectedImage(file);
									var formData = new FormData();
									formData.append('profilePicture', file);
									updateImage({
										image: formData,
										profilePicture:
											URL.createObjectURL(file),
									});
								},
								error(err) {},
							});
						}}
					/>
				</label>
			</div>

			<div className={Style.information}>
				<div className={Style.maxUpload}>
					<span>{texts?.maximum}</span>
					<div className={Style.focus}>{convertedFileSize}MB</div>
					<span>{texts?.size}</span>
				</div>

				<div className={Style.maxUpload}>
					{selectedImage ? (
						selectedImage?.size > config.MAX_FILE_SIZE ? (
							<ImBlocked style={{ color: 'var(--error)' }} />
						) : (
							<IoCheckmarkDoneCircle
								style={{ color: 'var(--success)' }}
							/>
						)
					) : null}
					<span>{texts?.uploadedSize}</span>
					<div
						style={{
							color:
								selectedImage?.size > config.MAX_FILE_SIZE
									? 'var(--error)'
									: 'var(--auth-primary)',
						}}
						className={Style.focusNew}
					>
						{selectedImage?.size
							? (selectedImage?.size / (1024 * 1024)).toFixed(2)
							: '--'}
						MB
					</div>
					<span>
						{texts?.onSize} {convertedFileSize}MB
					</span>
				</div>
			</div>

			<div className={Style.allowed}>{texts?.allowedFile}</div>

			<div className={Style.buttonBox}>
				<Button
					className={Style.button}
					disabled={
						updateProfilePic.isLoading ||
						image.profilePicture === null ||
						selectedImage?.size > config.MAX_FILE_SIZE ||
						!clientSideValidation
					}
					isLoading={updateProfilePic.isLoading}
					onClick={() => {
						updateProfilePic.executeQuery(image.image);
					}}
				>
					{texts?.buttonSave}
				</Button>
			</div>
		</div>
	) : (
		<LoaderModal uploaded={uploaded} />
	);
};

const LoaderModal = ({ uploaded }) => {
	const texts = useText('changeAvatar');

	return (
		<div className={Style.header}>
			<h5>{texts?.uploading}</h5>
			<h3>{Number(uploaded.progress).toFixed(0)}%</h3>
			<div className={Style.loaderWrapper}>
				<div className={Style.loaderBackground}>
					<div
						className={Style.loaderPipe}
						style={{ width: `${uploaded.progress || 0}%` }}
					></div>
				</div>
			</div>
			<div className={Style.kb}>
				{Number(uploaded.loaded / 1000).toFixed(0)} KB /{' '}
				{Number(uploaded.total / 1000).toFixed(0)} KB
			</div>
		</div>
	);
};

export default ChangeAvatar;
