import { useState, useEffect } from 'react';

/**
    * @param {number} step // Increase the number by this step
    * @param {number} max // Max number to reach 
    * @param {number} time // Time after each increase
    * 
 */

const useIncreaseNumber = (step, max, time) => {

    const [numberComputed, setNumberComputed] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            if (numberComputed < max) {
                setNumberComputed(numberComputed + step)
            }
        }, time)

        return () => clearInterval(interval)
    }, [numberComputed, max, step, time])

    if (numberComputed < 10) {
        return `0${numberComputed}`
    }

    return numberComputed

}

export default useIncreaseNumber;

