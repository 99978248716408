import React from 'react';
import ReactDOM from 'react-dom/client';

// Libraries
import { generateApiClient, ApiProvider } from "@hybris-software/use-query";
import { AuthProvider } from "@hybris-software/use-auth";
import { ThemeProvider } from "@hybris-software/ui-kit";

// Data
import config from './data/config';
import theme from './data/theme';

import './assets/css/index.css';
import App from './App';

const apiClient = generateApiClient({
  baseUrl: config.API_BASE_URL,
  authorizationHeader: "Authorization",
  authorizationPrefix: "Token ",
  acceptLanguage: localStorage.getItem('language') || "EN"
});

if (config.DEBUG_MODE) {
  console.log(
    `\n%c DEBUG MODE ENABLED \n%c YOU CAN SEE ALL INFORMATION WHEN AN ERROR IS TRIGGER \n\n%cCopyright © 2023 ${config.PLATFORM_NAME}. All rights reserved`,
    'color: red; font-size: 39.3px; background-color: black; font-weight: bold;',
    'color: orange; font-size: 14px;  background-color: black;',
    'color: white; font-size: 15.4px;',
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ApiProvider apiClient={apiClient}>
    <AuthProvider apiClient={apiClient} authUrl={"auth/user/"}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </AuthProvider>
  </ApiProvider>
);