import React, { useRef } from "react";

// Libraries\
import { useUser } from "@hybris-software/use-auth";
import QRCode from "react-qr-code";
import {
  WhatsappShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from "react-share";

// Data
import config from "../../../data/config";

// utils
import { copyTextToClipboard } from "../../../utils/utilityFunctions";

// Icons

import twitter from "../../../assets/logos/Twitter-X-Icon.png";
import whatsapp from "../../../assets/logos/whatsapp.png";
import facebook from "../../../assets/logos/facebook.png";
import messenger from "../../../assets/logos/messenger.png";
import telegram from "../../../assets/logos/telegram.png";

// Images
import AvatarPlaceholder from "../../../assets/images/placeholder.jpg";

// Hooks
import useText from "../../../hooks/useText";

// Styles
import Style from "./BusinessCard.module.css";
import classNames from "../../../utils/classNames";
import CopyIcon from "../../../assets/svgs/CopyIcon";

const BusinessCard = () => {
  // Hooks
  const texts = useText("businessCard");

  const { userInfo } = useUser();
  const divRef = useRef(null);
  const url = `${config.BASE_URL}auth/register?refcode=${userInfo?.referralCode}`;

  return (
    <div className={Style.main}>
      <h5 className={Style.title}>{texts?.title}</h5>
      <div className={Style.card}>
        <div className={Style.glass}>
          <div
            className={Style.profileBadge}
            style={{
              backgroundImage: userInfo.profilePicture
                ? `url(${userInfo.profilePicture})`
                : `url(${AvatarPlaceholder})`,
            }}
          ></div>

          <div className={Style.info}>
            <div className={Style.superTitle}>
              <p>{texts?.invite}</p>{" "}
              <span title={userInfo.username} className={Style.usernameIt}>
                @{userInfo.username}
              </span>
            </div>
            <p>{texts?.invitation}</p>
          </div>
        </div>
        <div className={Style.whitePaper}>
          <div className={Style.qrRow}>
            <div className={Style.qrOuterContainer}>
              <div className={Style.qrInnerContainer} ref={divRef}>
                <div>
                  <QRCode className={Style.qrCode} value={url} />
                </div>
              </div>
            </div>
          </div>

          <div className={Style.social}>
            <div className={Style.socialTitle}>{texts?.social}</div>
            <div className={Style.socialIcons}>
              <div
                className={Style.socialIconsWrapper}
                style={{ background: "rgba(71, 199, 87, 0.1)" }}
              >
                <WhatsappShareButton
                  url={url}
                  title=" Hey friends! I'm excited to share this QR code with you to join
              2access.io. Just a quick scan and you're in for an amazing
              experience!"
                >
                  <img src={whatsapp} alt="" />
                </WhatsappShareButton>
              </div>
              <div
                className={Style.socialIconsWrapper}
                style={{ background: "rgba(24, 119, 242, 0.1)" }}
              >
                <FacebookShareButton url={url}>
                  <img src={facebook} alt="" />
                </FacebookShareButton>
              </div>
              <div
                className={Style.socialIconsWrapper}
                style={{ background: "rgba(238, 76, 151, 0.1)" }}
              >
                <FacebookMessengerShareButton url={url}>
                  <img src={messenger} alt="" />
                </FacebookMessengerShareButton>
              </div>
              <div
                className={Style.socialIconsWrapper}
                style={{ background: "rgba(41, 169, 234, 0.1)" }}
              >
                <TelegramShareButton
                  url={url}
                  title=" Hey friends! I'm excited to share this QR code with you to join
              2access.io. Just a quick scan and you're in for an amazing
              experience!"
                >
                  <img src={telegram} alt="" />
                </TelegramShareButton>
              </div>

              <div
                className={Style.socialIconsWrapper}
                style={{ background: "rgba(238, 76, 151, 0.1)" }}
              >
                <TwitterShareButton
                  url={url}
                  title=" Hey friends! I'm excited to share this QR code with you to join
              2access.io. Just a quick scan and you're in for an amazing
              experience!"
                >
                  <img src={twitter} alt="" />
                </TwitterShareButton>
              </div>
            </div>
          </div>
          <div className={Style.referralLink}>
            <CopyInput label={texts?.referral} value={url} />
          </div>
        </div>
      </div>
    </div>
  );
};

const CopyInput = ({ label, value, textIcon, withCopyIcon = true }) => {
  const copyTooltipRef = useRef(null);

  const handleCopyClick = () => {
    copyTextToClipboard(value)
      .then(() => {
        showCopyTooltip();
      })
      .catch((err) => {});
  };

  const showCopyTooltip = () => {
    if (copyTooltipRef.current) {
      copyTooltipRef.current.classList.add(Style.show);
      setTimeout(() => {
        hideCopyTooltip();
      }, 1500);
    }
  };

  const hideCopyTooltip = () => {
    if (copyTooltipRef.current) {
      copyTooltipRef.current.classList.remove(Style.show);
    }
  };

  return (
    <div className={Style.CopyInput}>
      {label && <div className={Style.label}>{label}</div>}
      <div className={Style.copyInfo}>
        <div className={Style.iconText}>
          {textIcon && <img src={textIcon} alt="textIcon" />}
          <span className={Style.toCopy}>{value}</span>
        </div>
        {withCopyIcon && (
          <div onClick={() => handleCopyClick()} className={Style.copyBtn}>
            <CopyIcon />
            <span
              ref={copyTooltipRef}
              className={classNames(Style.copyTooltip)}
              id="copyTooltip"
              aria-live="assertive"
              role="tooltip"
            >
              Copied!
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
export default BusinessCard;
