import React, { useState } from "react";

// Components
import Card from "../../../../../components/advanced/card/Card";

// Images
import PlatformsImage from "../../../../../assets/images/platforms.svg";
import Heart from "../../../../../assets/images/heart.svg";
import Speaker from "../../../../../assets/images/speaker.svg";

// Hooks
import useText from "../../../../../hooks/useText";

// Styles
import Style from "./Platforms.module.css";
import { useEffect } from "react";

const Platforms = ({ response, modalRef }) => {
  const texts = useText("dashboardView");
  const [data, setData] = useState();

  useEffect(() => {
    setData(response);
    // eslint-disable-next-line
  }, []);

  const requireHasOldBalance = data?.filter(
    (item) => item.requireHasOldBalance === true
  );
  const favorites = data?.filter((item) => item.favorite === true);
  const platforms = data?.filter((item) => item.comingSoon !== true);
  const comingSoon = data?.filter((item) => item.comingSoon === true);

  return (
    <>
      {favorites?.length > 0 && (
        <>
          <div className={Style.list}>
            <h4 className={Style.titleSection}>
              <img as="image" rel="preload" src={Heart} alt="" />
              {texts?.favoritesLabel}
            </h4>
          </div>
          <div className={Style.grid}>
            {favorites?.map((item, i) => {
              return (
                <Card
                  key={i}
                  {...item}
                  update={data}
                  setData={setData}
                  modalRef={modalRef}
                />
              );
            })}
          </div>
        </>
      )}

      {platforms?.length > 0 && (
        <>
          <div className={Style.list}>
            <h4 className={Style.titleSection}>
              <img as="image" rel="preload" src={PlatformsImage} alt="" />
              {texts?.platformLabel}
            </h4>
          </div>
          <div className={Style.grid}>
            {platforms?.map((item, i) => {
              return (
                <Card
                  key={i}
                  {...item}
                  update={data}
                  setData={setData}
                  modalRef={modalRef}
                />
              );
            })}
          </div>
        </>
      )}

      {requireHasOldBalance?.length > 0 && (
        <>
          <div className={Style.list}>
            <h4 className={Style.titleSection}>
              <img as="image" rel="preload" src={PlatformsImage} alt="" />
              {texts?.migrationsLabel}
            </h4>
          </div>
          <div className={Style.grid}>
            {requireHasOldBalance?.map((item, i) => {
              return (
                <Card
                  key={i}
                  {...item}
                  update={data}
                  setData={setData}
                  modalRef={modalRef}
                />
              );
            })}
          </div>
        </>
      )}

      {comingSoon?.length > 0 && (
        <>
          <div className={Style.list}>
            <h4 className={Style.titleSection}>
              <img as="image" rel="preload" src={Speaker} alt="" />
              {texts?.comingSoonLabel}
            </h4>
          </div>
          <div className={Style.grid}>
            {comingSoon?.map((item, i) => {
              return <Card key={i} {...item} update={data} setData={setData} />;
            })}
          </div>
        </>
      )}
    </>
  );
};

export default Platforms;
