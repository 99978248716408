import React, { useEffect, useContext, useState, useRef } from 'react';

// Components
import Hero from '../../../components/advanced/hero/Hero';
import Tooltip from '../../../components/advanced/tooltip/Tooltip';
import Overview from './components/overview/Overview';
import Activities from './components/activities/Activities';
import Platforms from './components/platforms/Platforms';

// Libraries
import { Container, MagicModal } from '@hybris-software/ui-kit';
import { useUser, PermissionRoute } from '@hybris-software/use-auth';
import useQuery from '@hybris-software/use-query';
import { useNavigate } from 'react-router-dom';

// Svgs
import Copy from '../../../assets/svgs/Copy';

// Hooks
import useText from '../../../hooks/useText';

// Data
import endpoints from '../../../data/endpoints';
import config from '../../../data/config';

// Utils
import dispatcher from '../../../utils/dispatcher';

// Contexts
import { RoutesContext } from '../../../contexts/RoutesContext';

// Styles
import Style from './DashboardView.module.css';

const DashboardView = () => {
	// Hooks
	const copy = useText('copyTooltip');
	const texts = useText('dashboardView');
	const navigate = useNavigate();
	const { userInfo } = useUser();
	// Contexts
	const { paths } = useContext(RoutesContext);
	// State
	const [platform, setPlatform] = useState();
	// Refs
	const modalRef = useRef();

	const getStatApi = useQuery({
		url: endpoints.profile.GETSTATS,
		method: 'GET',
		executeImmediately: true,
	});

	const getPlatformApi = useQuery({
		url: endpoints.profile.GETPLATFORMS,
		method: 'GET',
		executeImmediately: true,
		onSuccess: (response) => {
			setPlatform(response.data);
		},
	});

	const getNotificationsApi = useQuery({
		url: endpoints.notifications.GETNOTIFICATIONS,
		method: 'GET',
		executeImmediately: true,
	});

	useEffect(() => {
		document.title = `${config.PLATFORM_NAME} | ${texts?.pageTitle}`;
		//eslint-disable-next-line
	}, []);

	return (
		<PermissionRoute
			// loader={<Loader />}
			minimumLoadingTime={500}
			forLoggedUser={true}
			firstApiLoading={
				getStatApi.isLoading ||
				getPlatformApi.isLoading ||
				getNotificationsApi.isLoading
			}
			unAuthorizedAction={() => {
				navigate(paths.auth.login);
			}}
			permissionController={(response) => {
				dispatcher(response.data, navigate, paths);
				return true;
			}}
		>
			<MagicModal ref={modalRef} />
			<Hero>
				<Container>
					<div className={Style.header}>
						<h3 className={Style.title}>{texts?.title}</h3>
						<div className={Style.referral}>
							<div className={Style.referralText}>
								{texts?.cardReferral}{' '}
								{`${config.BASE_URL}auth/register?refcode=${userInfo?.referralCode}` ||
									'--'}
							</div>

							<Tooltip
								valueToCopy={
									`${config.BASE_URL}auth/register?refcode=${userInfo?.referralCode}` ||
									'--'
								}
								copyIcon={<Copy />}
								textCopy={copy?.copied}
							/>
						</div>
					</div>

					<div className={Style.grid}>
						<Overview
							response={getStatApi?.response?.data}
							modalRef={modalRef}
						/>
						<Activities
							response={getNotificationsApi?.response?.data}
						/>
					</div>
				</Container>
			</Hero>
			<Container>
				<Platforms response={platform} modalRef={modalRef} />
			</Container>
		</PermissionRoute>
	);
};

export default DashboardView;
