import React, { useEffect, useContext, useRef } from 'react';

// Components
import ErrorModal from '../../../components/advanced/errorModal/ErrorModal';

// Libraries
import { InputField, Button, useWindowSize } from '@hybris-software/ui-kit';
import useForm from '@hybris-software/use-ful-form';
import { AuthRoute } from '@hybris-software/use-auth';
import { useNavigate, useOutletContext } from 'react-router-dom';
import useQuery from '@hybris-software/use-query';
import HCaptcha from '@hcaptcha/react-hcaptcha';

// Icons
import { FiAlertTriangle } from 'react-icons/fi';
import { HiChevronLeft } from 'react-icons/hi';

// Images
import Sent from '../../../assets/images/sent.png';

// Utils
import updateFormError from '../../../utils/updateFormError';

// Hooks
import useText from '../../../hooks/useText';
import useCountdown from '../../../hooks/useCountdown';

// Data
import endpoints from '../../../data/endpoints';
import config from '../../../data/config';

// Contexts
import { RoutesContext } from '../../../contexts/RoutesContext';
import { LanguageContext } from '../../../contexts/LanguageContext';

// Styles
import Style from './ForgotPasswordView.module.css';

const ForgotPasswordView = () => {
	// Hooks
	const texts = useText('forgotPasswordView');
	const inputTexts = useText('inputs');
	const navigate = useNavigate();
	const { count, resetCountdown } = useCountdown(300);
	const { width } = useWindowSize();

	// Ref
	const captchaRef = useRef(null);

	// Contexts
	const { paths } = useContext(RoutesContext);
	const { errorModalRef } = useOutletContext();
	const { language } = useContext(LanguageContext);


	const form = useForm({
		inputs: {
			email: {
				nature: 'email',
				validator: (value) => {
					const re =
						//eslint-disable-next-line
						/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					if (!value || value === '')
						return [false, inputTexts?.mandatory];
					if (!re.test(String(value).toLowerCase()))
						return [false, inputTexts?.invalidEmail];
					return [true, ''];
				},
			},
			captchaToken: {
				required: true,
			},

		},
	});

	const forgotPasswordApi = useQuery({
		url: endpoints.auth.RESETPASSWORD,
		method: 'POST',
		onSuccess: (response) => {
			resetCountdown();
		},
		onError: (error) => {
			if (error.response.status === 422) {
				updateFormError(error.response.data, form);
			} else
				errorModalRef.current.updateBody(
					<ErrorModal error={error} modalRef={errorModalRef} />
				);
		},
	});

	const resendEmailAPI = useQuery({
		url: endpoints.auth.RESETPASSWORD,
		method: 'POST',
		onSuccess: (response) => {},
		onError: (error) => {
			if (error.response.status === 422) {
				updateFormError(error.response.data, form);
			} else
				errorModalRef.current.updateBody(
					<ErrorModal error={error} modalRef={errorModalRef} />
				);
		},
	});

	useEffect(() => {
		document.title = `${config.PLATFORM_NAME} | ${texts?.pageTitle}`;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AuthRoute
			// loader={<LoaderAuth />}
			minimumLoadingTime={500}
			forLoggedUser={false}
			action={() => {
				navigate(paths.auth.authRedirect);
			}}
		>
			<div className={Style.card}>
				{!forgotPasswordApi.isSuccess ? (
					<>
						<h4 className={Style.title}>{texts?.title}</h4>
						<div className={Style.reminder}>{texts?.subtitle}</div>

						<InputField
							label={texts?.labelEmail}
							placeholder={texts?.placeholderEmail}
							errorIconVisibility
							errorIcon={<FiAlertTriangle color='var(--error)' />}
							{...form.getInputProps('email')}
						/>

						
				<div className={Style.captcha}>
					<HCaptcha
						size={width > 575 ? 'normal' : 'compact'}
						sitekey={config.CAPTCHA_SITE_KEY}
						languageOverride={language.toLowerCase() || 'en'}
						onVerify={(value) => {
							form.getInputProps('captchaToken').setValue(value);
						}}
						onExpire={() =>
							form.getInputProps('captchaToken').setValue(null)
						}
						ref={captchaRef}
					/>
				</div>


						<Button
							className={Style.button}
							disabled={!form.isValid()}
							isLoading={forgotPasswordApi.isLoading}
							onClick={() => {
								forgotPasswordApi.executeQuery(
									form.getApiBody()
								);
							}}
						>
							{texts?.button}
						</Button>

						<div
							className={Style.goBack}
							onClick={() => {
								navigate(paths?.auth?.login);
							}}
						>
							<HiChevronLeft /> {texts?.goBack}
						</div>
					</>
				) : (
					<div className={Style.sent}>
						<h4 className={Style.titleSent}>
							{texts?.titleSent}
							<img as='image' rel='preload' src={Sent} alt='' />
						</h4>
						<div className={Style.reminder}>
							{texts?.subtitleSent}
						</div>

						{/* <div className={Style.noReceive}>
              {texts?.resendTitle}{" "}
              <span
                className={Style.resend}
                onClick={() => {
                    resendEmailAPI.executeQuery(form.getApiBody());
                }}
              >
                {texts?.resendCta}
              </span>
            </div> */}
						{/* <SendCodeAgain
              counter={15}
              onClick={() => resendEmailAPI.executeQuery(form.getApiBody())}
            /> */}
						{!count ? (
							<div className={Style.haveNotReceived}>
								{texts?.notReceived} &nbsp;
								<span
									onClick={() => {
										resetCountdown();
										resendEmailAPI.executeQuery(
											form.getApiBody()
										);
									}}
								>
									{texts?.resendCta}
								</span>
							</div>
						) : (
							<span className={Style.waitResend}>
								{texts?.waitPre} {count} {texts?.waitPost}
							</span>
						)}
					</div>
				)}
			</div>
		</AuthRoute>
	);
};

export default ForgotPasswordView;
