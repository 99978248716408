import React from 'react'

// Styles
import Style from "./LoaderElementDark.module.css";

const LoaderElementDark = () => {
    return (
        <div className={Style.spinner}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default LoaderElementDark