/**
 * Function return all query params as an object
 */

import config from "../data/config";

function getUrlParams() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const paramsObject = {};

  for (const [key, value] of params.entries()) {
    paramsObject[key] = value;
  }

  return paramsObject;
}

/**
 * @param {Object} navigate - navigate hook
 * @param {String} path - the path you want to visit
 * @param {String} redirect - the redirect URL
 */
function navigateWithRedirect(navigate, path, redirect = window.location.href) {
  redirect = encodeURIComponent(redirect);
  navigate(path + "?" + config.REDIRECT_URL_LABEL + "=" + redirect);
}

function navigateWithQueryParams(navigate, path, queryParams) {
  const queryString = Object.keys(queryParams)
    .map((key) => `${key}=${queryParams[key]}`)
    .join("&");
  const redirectToUrl = `${path}?${queryString}`;
  navigate(redirectToUrl);
}

function navigateShape(navigate, path) {
  const urlParams = getUrlParams();
  if (urlParams[config.LOGIN_URL_PARAMS]) {
    navigateWithQueryParams(navigate, path, urlParams);
  } else {
    navigate(path);
  }
}

async function copyTextToClipboard(str) {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false;
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
}

export {
  getUrlParams,
  navigateWithRedirect,
  navigateWithQueryParams,
  navigateShape,
  copyTextToClipboard,
};
