import React, { useContext, useEffect } from "react";

// Components
import LoaderElementDark from "../../../components/ui/loaderElementDark/LoaderElementDark";

// Libraries
import useQuery from "@hybris-software/use-query";
import { AuthRoute } from "@hybris-software/use-auth";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "@hybris-software/ui-kit";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Data
import endpoints from "../../../data/endpoints";

// Styles
import Style from "./SpyLoginView.module.css";

const SpyLoginView = () => {
  // Hooks
  const navigate = useNavigate();

  // Contexts
  const { paths } = useContext(RoutesContext);
  const { errorModalRef } = useOutletContext();

  const urlParams = new URLSearchParams(window.location.search);
  const paramToken = urlParams.get("token");

  const getTokenSpyApi = useQuery({
    url: endpoints.auth.GET_TOKEN_SPY,
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      sessionStorage.setItem("token", response?.data?.token);
      sessionStorage.setItem("inspectorTool", "true");
      navigate(paths.main.dashboard);
    },
    onError: (error) => {
      if (
        error?.response?.status === 400 &&
        error?.response?.data?.code === "USER_BLOCKED"
      )
      errorModalRef?.current?.updateBody(
          <ErrorSpy errorRef={errorModalRef} title="User Inactive" description="This user is inactive. You can't login with this user" />,
          {
            onCloseIconClick: () => {
              window.close() ?? navigate(paths.auth.authRedirect);
            },
          }          
        );
      else errorModalRef?.current?.updateBody(<ErrorSpy errorRef={errorModalRef} />,{
        onCloseIconClick: () => {
          window.close() ?? navigate(paths.auth.authRedirect);
        },
      });
    },
    onUnauthorized: () => {
      errorModalRef?.current?.updateBody(<ErrorSpy  modalRef={errorModalRef}/>,{
        onCloseIconClick: () => {
          window.close() ?? navigate(paths.auth.authRedirect);
        },
      });
    },
  });

  const platformConfigAPI = useQuery({
    url: endpoints.auth.PLATFORMCONFIG,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      if (response?.data?.maintainanceMode) {
        navigate(paths.generic.maintenance);
      }
    },
    clientOptions: {
      timeout: 120000,
    },
  });

  useEffect(() => {
    if (paramToken) {
      localStorage.clear();
      getTokenSpyApi.executeQuery({
        token: paramToken, 
      });
    } else navigate(paths.auth.authRedirect);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthRoute
      // loader={<LoaderAuth />}
      minimumLoadingTime={500}
      forLoggedUser={false}
           firstApiLoading={platformConfigAPI?.isLoading}

      action={() => {
        navigate(paths.auth.authRedirect);
      }}
    >
      <div className={Style.card}>
        <LoaderElementDark />
       </div>
    </AuthRoute>
  );
};

const ErrorSpy = ({ errorRef, title, description }) => {
  const navigate = useNavigate();

  const { paths } = useContext(RoutesContext);
  return (
    <div className={Style.errorPopup}>
      <h3>{title ?? "Session expired"}</h3>
      <p>
        {description ??
          "The token has expired. Please try again. If the problem persists, please contact the administrator."}
      </p>
      <Button
        className={Style.button}
        onClick={() => {
          window.close() ?? navigate(paths.auth.authRedirect);
          errorRef?.current?.destroy();
        }}
      >
        Close
      </Button>
    </div>
  );
};

export default SpyLoginView;
