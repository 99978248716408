import React, { useEffect, useState, useContext } from "react";

// Components
import ErrorModal from "../../../components/advanced/errorModal/ErrorModal";
import StatusResponse from "../../../components/advanced/statusResponse/StatusResponse";

// Libraries
import { Button } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";
import { useOutletContext } from "react-router-dom";
import { PermissionRoute } from "@hybris-software/use-auth";
import { useNavigate } from "react-router-dom";

// Hooks
import useText from "../../../hooks/useText";

// Data
import endpoints from "../../../data/endpoints";
import { ssoErrorCode, steps } from "../../../data/constants";
import config from "../../../data/config";

// Utils
import dispatcher from "../../../utils/dispatcher";
import { navigateShape } from "../../../utils/utilityFunctions";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Icons
import ErrorIcon from "../../../assets/images/error.webp";

// Styles
import Style from "./EnrollView.module.css";

const EnrollView = () => {
  // Hooks
  const texts = useText("enrollView");
  const errorsTexts = useText("errorCodes");
  const successTexts = useText("success");

  const navigate = useNavigate();
  const lfiId = "407725cd-31b4-46c2-a7fa-5d0f52984643";
  // States
  const [type, setType] = useState([]);

  // Contexts
  const { errorModalRef, unauthorizedModalRef } = useOutletContext();
  const { paths } = useContext(RoutesContext);

  const getPlatformApi = useQuery({
    url: endpoints.profile.GETPLATFORMS,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      const isLfiId = response.data.some((item) => item.id === lfiId);
      if (isLfiId) setType([lfiId]);
    },
  });

  const enrollPlatform = useQuery({
    url: endpoints.profile.ENROLLPLATFORM,
    method: "POST",
    onSuccess: (response) => {
      navigateShape(navigate, paths.auth.authRedirect);
    },
    onError: (error) => {
      if (error.response.status === 400) {
        errorModalRef?.current?.updateBody(
          <StatusResponse
            icon={ErrorIcon}
            title={errorsTexts?.enroll?.ENROLL_ERROR_TITLE}
            description={ssoErrorCode[error?.response?.data?.code].message}
          >
            <Button
              onClick={() => {
                errorModalRef?.current?.destroy();
              }}
              style={{
                borderRadius: "30px",
                width: "100%",
                maxWidth: "200px",
                marginBottom: "10px",
              }}
            >
              {successTexts?.buttonClose}
            </Button>
          </StatusResponse>
        );
      } else {
        errorModalRef.current.open(
          <ErrorModal error={error} modalRef={errorModalRef} />
        );
      }
    },
    onUnauthorized: () => {
      unauthorizedModalRef.current.open(
        <div>You are not authorized to do this action</div>
      );
    },
  });

  const errolablePlatform = getPlatformApi?.response?.data?.filter(
    (platform) =>
      platform.comingSoon === false && platform.isDisabledForUser === false
  );

  useEffect(() => {
    document.title = `${config.PLATFORM_NAME} | ${texts?.pageTitle}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PermissionRoute
      // loader={false}
      minimumLoadingTime={500}
      forLoggedUser={true}
      apiLoading={getPlatformApi.isLoading}
      unAuthorizedAction={() => {
        navigateShape(navigate, paths.auth.login);
      }}
      permissionController={(response) => {
        dispatcher(response.data, navigate, paths, [
          steps.REFERRAL_CODE,
          steps.ACCOUNT_TYPE,
          steps.TERMS,
          steps.CHANGE_OTP,
        ]);
        if (!response?.data?.enrollComplete) return true;
        else {
          navigateShape(navigate, paths.auth.authRedirect);
          return;
        }
      }}
    >
      <div className={Style.card}>
        <h5 className={Style.title}>{texts?.title}</h5>
        <div className={Style.reminder}>{texts?.subtitle}</div>

        <div className={Style.list}>
          {errolablePlatform?.length > 0 ? (
            errolablePlatform?.map(({ id, name, logo }, i) => {
              return (
                <div
                  key={i}
                  className={type.includes(id) ? Style.selected : Style.type}
                  onClick={() =>
                    setType(
                      type.includes(id)
                        ? type.filter((item) => item !== id)
                        : [...type, id]
                    )
                  }
                >
                  {/* <div className={Style.bg}>
                  <img as="image" rel="preload" src={logo} alt="" />
                </div> */}
                  <div className={Style.name}>
                    <img
                      as="image"
                      rel="preload"
                      src={logo}
                      alt=""
                      width={38}
                      height={38}
                    />
                  </div>
                  {name}
                </div>
              );
            })
          ) : (
            <div>No platforms to enroll for now </div>
          )}
        </div>

        <Button
          className={Style.button}
          disabled={type.length === 0}
          isLoading={enrollPlatform.isLoading}
          onClick={() => {
            enrollPlatform.executeQuery({
              ids: type,
            });
          }}
        >
          {texts?.button}
        </Button>

        <Button
          className={Style.skip}
          isLoading={enrollPlatform.isLoading}
          onClick={() => {
            enrollPlatform.executeQuery({ ids: [] });
          }}
        >
          {texts?.skip}
        </Button>
      </div>
    </PermissionRoute>
  );
};

export default EnrollView;
