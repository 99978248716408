import React, { useContext, useEffect } from 'react';

// Components
import LoaderElement from '../../../components/ui/loaderElement/LoaderElement';
import ErrorModal from '../../../components/advanced/errorModal/ErrorModal';
import StatusResponse from '../../../components/advanced/statusResponse/StatusResponse';

// Libraries
import { Button } from '@hybris-software/ui-kit';
import useQuery from '@hybris-software/use-query';
import { useOutletContext, useNavigate } from 'react-router-dom';

// Contexts
import { RoutesContext } from '../../../contexts/RoutesContext';

// Hooks
import useText from '../../../hooks/useText';

// Utils
import {
	getUrlParams,
	navigateShape,
	navigateWithRedirect,
} from '../../../utils/utilityFunctions';

// Icon
import bell from '../../../assets/images/error.svg';

// Data
import endpoints from '../../../data/endpoints';
import { ssoErrorCode } from '../../../data/constants';
import config from '../../../data/config';

// Styles
import Style from './SsoView.module.css';

const SsoView = () => {
	const texts = useText('ssoView');
	const urlParams = getUrlParams();
	const url = new URLSearchParams(urlParams).toString();

	const navigate = useNavigate();
	const { paths } = useContext(RoutesContext);
	const { errorModalRef } = useOutletContext();

	const getInputValidityApi = useQuery({
		url: `${endpoints.oauth.OAUTH}?${url}`,
		method: 'GET',
		executeImmediately: true,
		onError: (error) => {
			if (error.response.status === 422) {
				navigateShape(navigate, paths.auth.authRedirect);
			} else if (error.response.status === 403) {
				errorModalRef.current.updateBody(
					<div className={Style.moreStepsModal}>
						<h5>{texts.moreStepsRequire}</h5>
						<div>
							{ssoErrorCode[error.response.data.code]?.message}
						</div>
						{ssoErrorCode[error.response.data.code]
							?.actionAllowed && (
							<Button
								className={Style.moreStepBtn}
								onClick={() =>
									navigateShape(
										navigate,
										paths.auth.authRedirect
									)
								}
							>
								{texts.proceed}
							</Button>
						)}
					</div>
				);
			} else if (error.response.status === 400) {
				errorModalRef.current.updateBody(
					<StatusResponse
						icon={bell}
						title={'Failed !'}
						description={ssoErrorCode[error.response.data.code]?.message}
					/>
				);
			} else if (error.response.status === 401) {
				navigateWithRedirect(navigate, paths.auth.login);
			} else {
				errorModalRef.current.updateBody(
					<ErrorModal
						error={error}
						modalRef={errorModalRef}
						onClickButton={() => navigate(paths.auth.login)}
					/>,
					{
						onCloseIconClick: () => {
							navigate(paths.auth.login);
						},
					}
				);
			}
		},
	});

	const getRedirectUrlApi = useQuery({
		url: endpoints.oauth.OAUTH,
		method: 'POST',
		onSuccess: (response) => {
			window.location.href = response.data.redirectUri;
		},
		onError: (error) => {
			if (error.response.status === 403) {
				errorModalRef.current.updateBody(
					<div className={Style.moreStepsModal}>
						<h5>{texts.moreStepsRequire}</h5>
						<div>
							{ssoErrorCode[error.response.data.code]?.message}
						</div>
						{ssoErrorCode[error.response.data.code]
							?.actionAllowed && (
							<Button
								className={Style.moreStepBtn}
								onClick={() =>
									navigateShape(
										navigate,
										paths.auth.authRedirect
									)
								}
							>
								{texts.proceed}
							</Button>
						)}
					</div>,
					{
						closeIcon: <></>,
					}
				);
			}
			if (error.response.status === 400) {
				errorModalRef.current.updateBody(
					<StatusResponse
						icon={bell}
						title={'Failed !'}
						description={texts?.badRequestMessage}
					/>
				);
			} else if (error.response.status === 401) {
				navigateWithRedirect(navigate, paths.auth.login);
			} else {
				errorModalRef.current.updateBody(
					<ErrorModal
						error={error}
						modalRef={errorModalRef}
						onClickButton={() => navigate(paths.auth.login)}
					/>,
					{
						onCloseIconClick: () => {
							navigate(paths.auth.login);
						},
					}
				);
			}
		},
	});

	useEffect(() => {
		document.title = `${config.PLATFORM_NAME} | ${texts?.pageTitle}`;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={Style.sso}>
			{getInputValidityApi.isLoading ? (
				<div className={Style.loader}>
					<LoaderElement />
				</div>
			) : (
				<>
					<img
						src={config.LOGO_BLACK}
						rel='preload'
						as='image'
						alt=''
						height={40}
						className={Style.logo}
					/>
					<p>{texts?.cta}</p>
					<h4 className={Style.title}>{texts?.title}</h4>
					{getInputValidityApi?.response && (
						<div className={Style.platform}>
							{getInputValidityApi?.response?.data?.platform
								?.logo && (
								<img
									src={
										getInputValidityApi?.response?.data
											?.platform?.logo
									}
									rel='preload'
									as='image'
									alt=''
									className={Style.logoPlatform}
								/>
							)}
							{getInputValidityApi?.response?.data?.platform
								?.name &&
								getInputValidityApi?.response?.data?.platform
									?.name}
						</div>
					)}

					<div className={Style.buttons}>
						<Button
							className={Style.exit}
							onClick={() => {
								navigate(paths.main.dashboard);
							}}
						>
							{texts?.buttonExit}
						</Button>
						{getInputValidityApi.isSuccess && (
							<Button
								className={Style.proceed}
								isLoading={getRedirectUrlApi.isLoading}
								onClick={() => {
									getRedirectUrlApi.executeQuery(urlParams);
								}}
							>
								{texts?.buttonProceed}
							</Button>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default SsoView;
