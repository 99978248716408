import React, { useEffect, useRef } from "react";

// Components
import ErrorModal from "../errorModal/ErrorModal";
import StatusResponse from "../statusResponse/StatusResponse";

// Libraries
import useQuery from "@hybris-software/use-query";
import { useOutletContext } from "react-router-dom";
import { Button, MagicModal } from "@hybris-software/ui-kit";

// Images
import AvatarPlaceholder from "../../../assets/images/placeholder.jpg";
import Success from "../../../assets/images/success.webp";
import Error from "../../../assets/images/error.webp";

// Svg
import { BsFillHeartFill } from "react-icons/bs";

// Hooks
import useText from "../../../hooks/useText";

// Data
import endpoints from "../../../data/endpoints";

// Styles
import Style from "./Card.module.css";
import { ssoErrorCode } from "../../../data/constants";

// const formatBigNumber = Intl.NumberFormat("en", {
//   notation: "compact",
//   compactDisplay: "short",
//   maximumFractionDigits: 2,
// });

// const roundNumber = (number) => {
//   return Math.ceil(number / 500) * 500;
// };

const Card = ({
  name,
  logo,
  bgImage,
  loginEnabled,
  enrollmentDate,
  favorite,
  id,
  update,
  comingSoon,
  numberOfUsers,
  lastThreeUsers,
  setData,
  modalRef,
}) => {
  // Hooks
  const texts = useText("platformCard");
  const successTexts = useText("success");
  const errorsTexts = useText("errorCodes");
  // Contexts
  const { errorModalRef, unauthorizedModalRef } = useOutletContext();
  const modalRefLogin = useRef();

  const enrollPlatformApi = useQuery({
    url: endpoints.profile.ENROLLPLATFORM,
    method: "POST",
    onSuccess: (response) => {
      const updatedData = update.find((item) => item.id === id);
      updatedData.enrollmentDate = true;
      setData([...update]);
      modalRef?.current?.updateBody(
        <StatusResponse
          icon={Success}
          title={successTexts?.enrollPlatformTitle}
          description={successTexts?.informationSubtitle}
        >
          <Button
            onClick={() => {
              modalRef?.current?.destroy();
            }}
            style={{
              borderRadius: "30px",
              width: "100%",
              maxWidth: "200px",
              marginBottom: "10px",
            }}
          >
            {successTexts?.buttonClose}
          </Button>
        </StatusResponse>
      );
    },
    onError: (error) => {
      if (error.response.status === 400) {
        modalRef?.current?.updateBody(
          <StatusResponse
            icon={Error}
            title={errorsTexts?.enroll?.ENROLL_ERROR_TITLE}
            description={ssoErrorCode[error?.response?.data?.code].message}
          >
            <Button
              onClick={() => {
                modalRef?.current?.destroy();
              }}
              style={{
                borderRadius: "30px",
                width: "100%",
                maxWidth: "200px",
                marginBottom: "10px",
              }}
            >
              {successTexts?.buttonClose}
            </Button>
          </StatusResponse>
        );
      } else {
        errorModalRef.current.open(
          <ErrorModal error={error} modalRef={errorModalRef} />
        );
      }
    },
    onUnauthorized: () => {
      unauthorizedModalRef.current.open();
    },
  });

  const addToFavoriteApi = useQuery({
    url: `platforms/${id}/favorite/`,
    method: "POST",
    onSuccess: () => {
      const updatedData = update.find((item) => item.id === id);
      updatedData.favorite = !favorite;
      setData([...update]);
    },
    onError: (error) => {
      errorModalRef.current.updateBody(
        <ErrorModal error={error} modalRef={errorModalRef} />
      );
    },
    onUnauthorized: () => {
      unauthorizedModalRef.current.open();
    },
  });

  const loginToPlatformAPI = useQuery({
    url: endpoints.profile.PLATFORM_LOGIN.replace(":id", id),
    method: "POST",
    onSuccess: (response) => {
      window.open(response?.data?.redirectUri, "_blank", "noopener noreferrer");
      modalRef.current.updateBody(
        <PlaftformPopup
          texts={texts}
          modalRef={modalRef}
          response={response?.data}
        />
      );
    },
    onError: (error) => {
      if (error.response.status === 400) {
        modalRef?.current?.updateBody(
          <StatusResponse
            icon={Error}
            title={errorsTexts?.enroll?.ENROLL_ERROR_TITLE}
            description={ssoErrorCode[error?.response?.data?.code]?.message}
          >
            <Button
              onClick={() => {
                modalRef?.current?.destroy();
              }}
              style={{
                borderRadius: "30px",
                width: "100%",
                maxWidth: "200px",
                marginBottom: "10px",
              }}
            >
              {successTexts?.buttonClose}
            </Button>
          </StatusResponse>
        );
      } else {
        errorModalRef.current.open(
          <ErrorModal error={error} modalRef={errorModalRef} />
        );
      }
    },
    onUnauthorized: () => {
      unauthorizedModalRef.current.open();
    },
  });

  return (
    <div className={Style.card} style={{ backgroundImage: `url(${bgImage})` }}>
      <MagicModal ref={modalRefLogin} />

      <div className={Style.header}>
        <div className={Style.platform}>
          <img
            as="image"
            rel="preload"
            src={logo}
            alt=""
            height={29}
            width={29}
          />
          <span>{name}</span>
        </div>
        <div
          onClick={() => {
            !addToFavoriteApi.isLoading &&
              addToFavoriteApi.executeQuery({
                favorite: favorite ? false : true,
              });
          }}
          className={!favorite ? Style.favorite : Style.like}
        >
          <BsFillHeartFill />
        </div>
      </div>

      <div>
        {!comingSoon ? (
          <div className={Style.users}>
            <div
              className={Style.circle}
              style={
                lastThreeUsers[0]
                  ? {
                      backgroundImage: `url(${lastThreeUsers[0]})`,
                    }
                  : {
                      backgroundImage: `url(${AvatarPlaceholder})`,
                    }
              }
            />
            <div
              className={Style.circle}
              style={
                lastThreeUsers[1]
                  ? {
                      backgroundImage: `url(${lastThreeUsers[1]})`,
                    }
                  : {
                      backgroundImage: `url(${AvatarPlaceholder})`,
                    }
              }
            />
            <div
              className={Style.circle}
              style={
                lastThreeUsers[2]
                  ? {
                      backgroundImage: `url(${lastThreeUsers[2]})`,
                    }
                  : {
                      backgroundImage: `url(${AvatarPlaceholder})`,
                    }
              }
            />
          </div>
        ) : (
          <div style={{ height: "32px" }} />
        )}

        <div className={Style.footer}>
          {!comingSoon ? (
            <>
              <div className={Style.activeUser}>2M + {texts?.users}</div>
              {enrollmentDate === null ? (
                <Button
                  disabled={sessionStorage.getItem("inspectorTool") === "true"}
                  isLoading={enrollPlatformApi.isLoading}
                  className={Style.button}
                  onClick={() => {
                    !enrollPlatformApi.isLoading &&
                      enrollPlatformApi.executeQuery({
                        ids: [id],
                      });
                  }}
                >
                  {texts?.enrollButton}
                </Button>
              ) : (
                <Button
                  className={Style.button}
                  disabled={sessionStorage.getItem("inspectorTool") === "true"}
                  isLoading={loginToPlatformAPI.isLoading}
                  onClick={() => {
                    loginToPlatformAPI.executeQuery();
                  }}
                >
                  {texts?.loginButton}
                </Button>
              )}
            </>
          ) : (
            <>
              <div />
              <div className={Style.button}>{texts?.comingButton}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const PlaftformPopup = ({ texts, modalRef, response }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (modalRef.current) {
        modalRef.current.destroy();
      }
    }, 60000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={Style.loginPopup}>
      <h5>{texts?.loginTitlePopup}</h5>
      <p>{texts?.loginSubtitlePopup}</p>
      <a
        onClick={() => {
          modalRef.current.destroy();
        }}
        href={response?.backupRedirectUri}
        target="_blank"
        rel="noopener noreferrer"
      >
        {texts?.loginButtonPopup}
      </a>
    </div>
  );
};

export default Card;
