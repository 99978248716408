import React, { useRef } from "react";

// Libraries
import Table from "@hybris-software/use-table";
import styled from "styled-components";

// Hooks
import useText from "../../../../../hooks/useText";

// Data
import endPoints from "../../../../../data/endpoints";

// Styles
import Style from "./Devices.module.css";

const customStyles = styled.div`
  padding: 0;
  transition: all 0.5s ease;

  table {
    width: 100%;
    border-spacing: 0;
    thead {
      tr {
        color: #5e5e5e;
        font-weight: 500;
        font-size: var(--font-size-base);
        th {
          text-align: left;
          min-width: 270px;
          max-width: 800px;
          padding: 0 30px;
          color: var(--text-primary);
          border-left: 1px solid var(--card-border);
          transition: all 0.5s ease;
          :last-child {
            border-right: 1px solid var(--card-border);
          }
        }
      }
    }
    tbody {
      tr {
        height: 65px;
        font-weight: 500;
        font-size: var(--font-size-base);
        color: #757575;
        td {
          min-width: 270px;
          max-width: 800px;
          text-align: left;
          color: var(--text-secondary);
          border-top: 1px solid var(--card-border);
          transition: all 0.5s ease;
          padding: 0 30px;
          border-left: 1px solid var(--card-border);
          :last-child {
            border-right: 1px solid var(--card-border);
          }
        }
      }
    }
  }
`;

const Devices = () => {
  // Hooks
  const texts = useText("devicesView")?.devices;

  const devicesRef = useRef(null);

  const columns = [
    {
      Header: texts?.tableColumnOne,
      field: "deviceFamily",
      searchable: false,
      orderField: "device_family",
      accessor: (row) => {
        return row.deviceFamily || "-";
      },
    },
    {
      Header: texts?.tableColumnTwo,
      field: "browserFamily",
      searchable: false,
      orderField: "browser_family",
      accessor: (row) => {
        return <div className={Style.device}>{row.browserFamily || "-"}</div>;
      },
    },
    {
      Header: texts?.tableColumnThree,
      field: "country",
      searchable: false,
      orderField: "country",
      accessor: (row) => {
        return <div className={Style.device}>{row.country || "-"}</div>;
      },
    },
    {
      Header: texts?.tableColumnFour,
      field: "city",
      searchable: false,
      orderField: "city",
      accessor: (row) => {
        return <div className={Style.device}>{row.city || "-"}</div>;
      },
    },
    {
      Header: texts?.tableColumnFive,
      field: "lastInteraction",
      orderField: "last_interaction",
      searchable: false,
      accessor: (row) => {
        return new Date(row.createdAt).toLocaleDateString("en-US", {
          day: "numeric",
          month: "short",
          year: "numeric",
        });
      },
    },
  ];

  // todo: translate texts - devicesView?.table
  return (
    <div className={Style.notification}>
      <div className={Style.notificationSetting}>
        <h5>{texts?.title}</h5>
        <Table
          ref={devicesRef}
          Styles={customStyles}
          enableAllowedActions={false}
          toPageInputBaseClassName={Style.toPageInput}
          columns={columns}
          endPoint={endPoints.profile.RECENTDEVICES}
          enableSearch={false}
          enableSearchFieldSelect={false}
          enableSelectableRows={false}
          enableSettings={true}
          settingsClassName={Style.select}
        />
      </div>
    </div>
  );
};

export default Devices;
