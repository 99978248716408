import React from 'react'

// Hooks
import useCountdown from '../../../hooks/useCountdown'
import useText from '../../../hooks/useText';

// Styles
import Style from "./SendCodeAgain.module.css";

const SendCodeAgain = ({ counter, onClick }) => {

    const texts = useText("sendCodeAgain");
    const { count, resetCountdown } = useCountdown(counter);

    const handleResend = () => {
        resetCountdown();
        onClick();
    };

    return (
        <div>
            <div>
                {!count
                    ?
                    <div className={Style.haveNotReceived}>
                        {texts?.notReceived} &nbsp;
                        <span onClick={handleResend}>{texts?.resend}</span>
                    </div>
                    :
                    <span className={Style.waitResend}>
                        {texts?.waitPre} {count} {texts?.waitPost}
                    </span>
                }
            </div>
        </div>
    );
};

export default SendCodeAgain;
