import React, { useState } from "react";

// Libraries
import { useUser } from "@hybris-software/use-auth";

// Icons
import { GoInfo } from "react-icons/go";

// Images
import Enroll from "../../../../../assets/images/enroll.svg";
import Device from "../../../../../assets/images/device.svg";
import Lock from "../../../../../assets/images/lock.svg";
import AvatarPlaceholder from "../../../../../assets/images/placeholder.jpg";

// Hooks
import useText from "../../../../../hooks/useText";
import useIncreaseNumber from "../../../../../hooks/useIncreaseNumber";

// Utils
import speedIncrease from "../../../../../utils/speedIncrese";
import profileCompletation from "../../../../../utils/profileCompletation";

// Styles
import Style from "./Overview.module.css";
import { accountType } from "../../../../../data/constants";

const Overview = ({ response, modalRef }) => {
  const texts = useText("dashboardView")?.overview;
  const { userInfo } = useUser();
  const profileProgerss = profileCompletation(userInfo);
  const [profileInfo, setProfileInfo] = useState(false);

  const speed = speedIncrease(
    profileProgerss,
    response?.activeSessions,
    TimeAgo(userInfo?.lastPasswordChange)[0],
    3
  );

  function TimeAgo(timestamp) {
    const currentDate = new Date();
    const lastPasswordChangeDate = new Date(timestamp);

    const timeDifference = currentDate - lastPasswordChangeDate;

    const minutesDifference = Math.floor(timeDifference / (1000 * 60));
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    let timeUnit;
    let unitLabel;

    if (minutesDifference < 60) {
      timeUnit = minutesDifference;
      unitLabel = texts.minute;
    } else if (hoursDifference < 24) {
      timeUnit = hoursDifference;
      unitLabel = texts.hour;
    } else {
      timeUnit = daysDifference;
      unitLabel = texts.day;
    }

    const pluralSuffix = timeUnit === 1 ? "" : "s";
    const passwordUnit = [timeUnit, `${unitLabel}${pluralSuffix} ago`];

    return passwordUnit;
  }

  return (
    <div className={Style.card}>
      <h5 className={Style.title}>{texts?.title}</h5>
      <div className={Style.grid}>
        <div>
          <div className={Style.profileInfo}>
            <div
              className={Style.picture}
              style={{
                backgroundImage: userInfo.profilePicture
                  ? `url(${userInfo.profilePicture})`
                  : `url(${AvatarPlaceholder})`,
              }}
            />
            <div>
              <h5 className={Style.name}>@{userInfo?.username || "_"}</h5>
              <div className={Style.email}>{userInfo?.email || "_"}</div>
              <div className={Style.email}>{texts?.id}{userInfo?.id || "_"}</div>
            </div>
          </div>

          <div className={Style.progress}>
            <div className={Style.completation}>
              <div className={Style.infoBadge}>
                <div
                  className={
                    profileInfo ? Style.infoProfileActive : Style.infoProfile
                  }
                  onMouseLeave={() => {
                    setProfileInfo(false);
                  }}
                >
                  <div className={Style.content}>
                    {profileProgerss !== 100 ? (
                      <>
                        {texts?.profileStep}
                        <br />
                        <br />
                        {texts?.stepsListTitle}
                        <br />
                        {userInfo.securityCode === null && (
                          <>
                            {texts?.securityCode}
                            <br />
                          </>
                        )}
                        {userInfo.profilePicture === null && (
                          <>
                            {texts?.profilePicture}
                            <br />
                          </>
                        )}
                        {!userInfo.kycApproved && (
                          <>
                            {userInfo?.accountType === accountType.PERSONAL
                              ? texts?.kyc
                              : texts?.kyb}
                            <br />
                          </>
                        )}
                        {userInfo.address === null && (
                          <>
                            {texts?.profileInfo}
                            <br />
                          </>
                        )}
                        {userInfo.otpMethod !== "TOTP" && (
                          <>
                            {texts?.changeOtp}
                            <br />
                          </>
                        )}
                      </>
                    ) : (
                      texts?.congrats
                    )}
                  </div>
                </div>
                {texts?.profile}
                <GoInfo
                  onClick={() => {
                    setProfileInfo((old) => !old);
                  }}
                />
              </div>
              {/* {profileProgerss !== 100 && (
                <PiArrowRightBold
                  onClick={() => {
                    if (userInfo.profilePicture !== null) {
                      navigate(goToPath);
                    } else {
                      modalRef.current.updateBody(
                        <ChangeAvatar modalRef={modalRef} />
                      );
                    }
                  }}
                />
              )} */}
            </div>
            <div className={Style.bar}>
              <div
                className={Style.progression}
                style={{
                  width: `${profileProgerss}%`,
                  background:
                    profileProgerss !== 100
                      ? "var(--warning-dark)"
                      : "var(--success)",
                }}
              />
            </div>
            <div className={Style.percentage}>
              {profileProgerss}% {texts?.profileProgerss}
            </div>
          </div>
        </div>

        <div>
          <div className={Style.gridTabs}>
            <div className={Style.element}>
              <img
                as="image"
                rel="preload"
                src={Enroll}
                alt=""
                width={24}
                height={24}
              />
              <div className={Style.data}>
                <h3>
                  {useIncreaseNumber(1, profileProgerss, speed.increseNum1)}%
                </h3>
                <div className={Style.label}>{texts?.profile}</div>
              </div>
            </div>
            <div className={Style.element}>
              <img
                as="image"
                rel="preload"
                src={Device}
                alt=""
                width={24}
                height={24}
              />
              <div className={Style.data}>
                <h3>
                  {useIncreaseNumber(
                    1,
                    response?.activeSessions,
                    speed.increseNum2
                  )}
                </h3>
                <div className={Style.label}>{texts?.device}</div>
              </div>
            </div>
            {/* <div className={Style.element}>
              <img
                as="image"
                rel="preload"
                src={Referral}
                alt=""
                width={24}
                height={24}
              />
              <div className={Style.data}>
                <h3>
                  {useIncreaseNumber(
                    1,
                    response?.referralsCount,
                    speed.increseNum3
                  )}
                </h3>
                <div className={Style.label}>{texts?.referral}</div>
              </div>
            </div> */}
            <div className={Style.element}>
              <img
                as="image"
                rel="preload"
                src={Lock}
                alt=""
                width={24}
                height={24}
              />
              <div className={Style.data}>
                <div className={Style.days}>
                  <h3>
                    {useIncreaseNumber(
                      1,
                      TimeAgo(userInfo?.lastPasswordChange)[0],
                      speed.increseNum3
                    )}
                  </h3>
                  <span>{TimeAgo(userInfo?.lastPasswordChange)[1]}</span>
                </div>
                <div className={Style.label}>{texts?.password}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
