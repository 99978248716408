import React, { useRef, useEffect, useContext } from 'react';

// Components
import Hero from '../../../components/advanced/hero/Hero';
import ProfileBadge from '../../../components/advanced/profileBadge/ProfileBadge';
import Devices from './components/devices/Devices';
import Tooltip from '../../../components/advanced/tooltip/Tooltip';

// Libraries
import { Container, MagicModal } from '@hybris-software/ui-kit';
import { useUser, PermissionRoute } from '@hybris-software/use-auth';
import { useNavigate } from 'react-router-dom';

// Svgs
import Copy from '../../../assets/svgs/Copy';

// Hooks
import useText from '../../../hooks/useText';

// Contexts
import { RoutesContext } from '../../../contexts/RoutesContext';

// Utils
import config from '../../../data/config';

// Data
import dispatcher from '../../../utils/dispatcher';

// Styles
import Style from './RecentDevicesView.module.css';

const RecentDevicesView = () => {
	// Hooks
	const copy = useText('copyTooltip');
	const texts = useText('devicesView');
	const { userInfo } = useUser();
	const navigate = useNavigate();
	// Refs
	const modalRef = useRef();
	// Contexts
	const { paths } = useContext(RoutesContext);

	useEffect(() => {
		document.title = `${config.PLATFORM_NAME} | ${texts?.pageTitle}`;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<PermissionRoute
			// loader={<Loader />}
			minimumLoadingTime={500}
			forLoggedUser={true}
			unAuthorizedAction={() => {
				navigate(paths.auth.login);
			}}
			permissionController={(response) => {
				dispatcher(response.data, navigate, paths);
				return true;
			}}
		>
			<MagicModal ref={modalRef} />

			<Hero>
				<Container>
					<div className={Style.header}>
						<h3 className={Style.title}>{texts?.title}</h3>
						<div className={Style.referral}>
							<div className={Style.referralText}>
								{texts?.cardReferral}{' '}
								{`${config.BASE_URL}auth/register?refcode=${userInfo?.referralCode}` ||
									'--'}
							</div>
							<Tooltip
								valueToCopy={
									`${config.BASE_URL}auth/register?refcode=${userInfo?.referralCode}` ||
									'--'
								}
								copyIcon={<Copy />}
								textCopy={copy?.copied}
							/>
						</div>
					</div>
					<ProfileBadge modalRef={modalRef} />
				</Container>
			</Hero>

			<Container>
				<Devices modalRef={modalRef} />
			</Container>
		</PermissionRoute>
	);
};

export default RecentDevicesView;
