import config from "../data/config";
import { otpMethods, steps } from "../data/constants";
import { getUrlParams, navigateWithQueryParams, navigateShape } from "./utilityFunctions";


function dispatcher(data, navigate, paths, stepList) {
  const urlParams = getUrlParams();

  // Check Referral CODE step "0"
  if (stepList?.includes(steps.REFERRAL_CODE) || stepList === undefined) {
    if (!data.referralSubmitted) {
      // Go to Referral CODE page
      navigateShape(navigate,paths.auth.referralCode);
      return;
    }
  }

  // Check ACCOUNT TYPE status step "1"
  if (stepList?.includes(steps.ACCOUNT_TYPE) || stepList === undefined) {
    if (!data.accountType) {
      // Go to ACCOUNT TYPE page
      navigateShape(navigate,paths.auth.profileType)
      return;
    }
  }

  // Check if the user has already accepted the TERMS step "2"
  if (stepList?.includes(steps.TERMS) || stepList === undefined) {
    if (data.termsAccepted === false) {
      // Go to TERMS page
      if (urlParams[config.LOGIN_URL_PARAMS]) {
        navigateWithQueryParams(
          navigate,
          paths.auth.termsAndConditions,
          urlParams
        );
      } else {
        navigate(paths.auth.termsAndConditions);
      }
      return;
    }
  }

  // Check is the user has already changed his OTP to Google step "3"
  if (stepList?.includes(steps.CHANGE_OTP) || stepList === undefined) {
    if (data.otpMethod === otpMethods.EMAIL) {
      // Go to Change OTP page
      navigateShape(navigate,paths.auth.changeOTPMethod)
      return;
    }
  }

  // Check is the enroll process is completed step "4"
  if (stepList?.includes(steps.ENROLL) || stepList === undefined) {
    if (data.enrollComplete === false) {
      // Go to ENROLL page
      navigateShape(navigate,paths.auth.enroll)
      return;
    }
  }

  if (
    data.enrollComplete === true &&
    data.otpMethod === otpMethods.TOTP &&
    data.termsAccepted === true &&
    data.accountType &&
    data.referralSubmitted
  ) {
    if (urlParams[config.LOGIN_URL_PARAMS]) {
      navigateWithQueryParams(navigate, paths.sso.login, urlParams);
    }
    return;
  }
}

export default dispatcher;
