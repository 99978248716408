import React, { useState, useEffect, useRef, useContext } from 'react';

// Components
import SendCodeAgain from '../../../components/advanced/sendCodeAgain/SendCodeAgain';
import ErrorBanner from '../../../components/advanced/errorBanner/ErrorBanner';
import ErrorModal from '../../../components/advanced/errorModal/ErrorModal';

// Libraries
import AuthCode from 'react-auth-code-input';
import { Button, MagicModal } from '@hybris-software/ui-kit';
import useQuery from '@hybris-software/use-query';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { PermissionRoute } from '@hybris-software/use-auth';

// Hooks
import useText from '../../../hooks/useText';

// Data
import endpoints from '../../../data/endpoints';
import config from '../../../data/config';

// Contexts
import { RoutesContext } from '../../../contexts/RoutesContext';

// Utils
import { getUrlParams, navigateShape } from '../../../utils/utilityFunctions';

// Styles
import Style from './TwoFactorView.module.css';

const TwoFactorView = () => {
	// Hooks
	const texts = useText('twoFactorView');
	const navigate = useNavigate();
	// States
	const [code, setCode] = useState('');
	// Variables
	const otpMethod = localStorage.getItem('otpMethod');
	const urlParams = getUrlParams();

	// Contexts
	const { errorModalRef } = useOutletContext();
	const { paths } = useContext(RoutesContext);
	// Refs
	const sessionExpiredModalRef = useRef();

	useEffect(() => {
		document.title = `${config.PLATFORM_NAME} | ${texts?.pageTitle}`;
		// eslint-disable-next-line
	}, []);

	const confirmLoginApi = useQuery({
		url: endpoints.auth.LOGINCONFIRM,
		method: 'POST',
		onSuccess: (response) => {
			localStorage.setItem('token', response.data.token);
			localStorage.removeItem('loginToken');
			localStorage.removeItem('otpMethod');
			localStorage.removeItem('email');

			if (!urlParams[config.REDIRECT_URL_LABEL]) {
				navigate(paths.auth.authRedirect);
			} else {
				window.location.href = urlParams[config.REDIRECT_URL_LABEL];
			}
		},
		onError: (error) => {
			if (
				error?.response?.status === 422 &&
				error?.response?.data.token
			) {
				sessionExpiredModalRef.current.open();
			} else if (
				error?.response?.status !== 400 &&
				error?.response?.status !== 422 &&
				error?.response?.status !== 401
			) {
				errorModalRef.current.updateBody(
					<ErrorModal error={error} modalRef={errorModalRef} />
				);
			}
		},
	});

	const resendOtpApi = useQuery({
		url: endpoints.auth.RESENDOTP,
		method: 'POST',
		executeImmediately: false,
		onError: (error) => {
			if (
				error?.response?.status === 422 &&
				error?.response?.data.loginToken
			) {
				sessionExpiredModalRef.current.open();
			}
		},
	});

	useEffect(() => {
		if (code.length === 6)
			confirmLoginApi.executeQuery({
				loginToken: localStorage.getItem('loginToken'),
				otp: code,
			});
		//eslint-disable-next-line
	}, [code]);

	return (
		<PermissionRoute
			// loader={false}
			minimumLoadingTime={500}
			forLoggedUser={false}
			unAuthorizedAction={() => {
				navigateShape(navigate, paths.auth.login);
			}}
			permissionController={() => {
				if (localStorage.getItem('loginToken') !== null) return true;
				else navigateShape(navigate, paths.auth.authRedirect);
			}}
		>
			<MagicModal
				ref={sessionExpiredModalRef}
				destroyBodyOnClose={false}
				showCloseIcon={false}
				body={
					<div className={Style.expired}>
						<h4>{texts?.titleExpired}</h4>
						<p>{texts?.subtitleExpired}</p>
						<Button
							className={Style.buttonLogin}
							onClick={() => {
								localStorage.removeItem('loginToken');
								navigateShape(navigate, paths.auth.login);
							}}
						>
							{texts?.expiredButton}
						</Button>
					</div>
				}
			/>

			<div className={Style.card}>
				<h4 className={Style.title}>{texts?.title}</h4>
				<div className={Style.reminder}>
					{otpMethod && otpMethod === 'EMAIL' ? (
						<>
							{texts?.subtitleEmail}{' '}
							{localStorage.getItem('email')}
						</>
					) : (
						<>{texts?.subtitleAuthenticator}</>
					)}
				</div>

				<ErrorBanner
					response={confirmLoginApi.error?.response}
					call='loginConfirm'
				/>

				{confirmLoginApi.isError &&
					confirmLoginApi.error?.response?.status === 422 &&
					!confirmLoginApi.error?.response?.data?.token && (
						<div className={Style.errorBox}>
							{confirmLoginApi.error?.response?.data?.otp ||
								confirmLoginApi.error?.response?.data?.message}
						</div>
					)}

				{confirmLoginApi.isError &&
					confirmLoginApi.error?.response?.status === 401 && (
						<div className={Style.errorBox}>
							{confirmLoginApi.error?.response?.data?.message}
						</div>
					)}

				<div className={Style.form}>
					<AuthCode
						allowedCharacters='numeric'
						onChange={(e) => {
							setCode(e);
						}}
					/>
				</div>

				<Button
					className={Style.button}
					disabled={code?.length !== 6}
					isLoading={confirmLoginApi.isLoading}
					onClick={() => {
						if (code.length === 6)
							confirmLoginApi.executeQuery({
								loginToken: localStorage.getItem('loginToken'),
								otp: code,
							});
					}}
				>
					{texts?.button}
				</Button>

				{otpMethod === 'EMAIL' && (
					<SendCodeAgain
						counter={15}
						onClick={() =>
							resendOtpApi.executeQuery({
								loginToken: localStorage.getItem('loginToken'),
							})
						}
					/>
				)}
			</div>
		</PermissionRoute>
	);
};

export default TwoFactorView;
