import React, { useEffect } from 'react'

// Hooks
import useText from '../../../hooks/useText';

// Styles
import Style from "./PasswordValidator.module.css";

const PasswordValidator = ({ status }) => {

    const texts = useText("validatorPassoword");

    const passed = Object.values(status).reduce((acc, curr) => {
        if (curr === true) {
            acc++;
        }
        return acc;
    }, 0);

    useEffect(() => {
        if (passed === 4) {
            document.querySelectorAll(`.${Style.item}`).forEach((item) => {
                item.classList.add(Style.four);
            });
        }
        else if (passed === 3 || passed === 2) {
            document.querySelectorAll(`.${Style.item}`).forEach((item, index) => {
                if (index < 3) {
                    item.classList.add(Style.three);
                }
            });
        }
        else if (passed === 1) {
            document.querySelectorAll(`.${Style.item}`).forEach((item, index) => {
                if (index < 1) {
                    item.classList.add(Style.one);
                }
            });
        }
    }, [passed])

    return (
        <>
            <div className={Style.texts}>
                {texts?.infomation}
                <div className={Style.grid}>
                    <div className={Style.item}></div>
                    <div className={Style.item}></div>
                    <div className={Style.item}></div>
                    <div className={Style.item}></div>
                </div>
            </div>
        </>
    )
}

export default PasswordValidator