// Images
import NotKyc from '../assets/images/not-kyc.svg';
import NotUpdate from '../assets/images/not-update.svg';
import NotSettings from '../assets/images/not-settings.svg';
import NotReject from '../assets/images/not-reject.svg';
import NotEnroll from '../assets/images/not-enroll.svg';
import NotEmail from '../assets/images/not-email.svg';
import NotPassword from '../assets/images/not-password.svg';
import NotReferral from '../assets/images/not-referral.svg';
import NotLogin from '../assets/images/not-login.svg';
import NotDevice from '../assets/images/not-device.svg';

// Hooks
import useText from './useText';

const useTypeNotification = () => {
	const texts = useText('notificationsList');

	const types = {
		LOGIN: 'LOGIN',
		REFERRAL: 'REFERRAL',
		NEW_DEVICE: 'NEW_DEVICE',
		SECURITY_CODE: 'SECURITY_CODE',
		PASSWORD_CHANGE: 'PASSWORD_CHANGE',
		EMAIL_CHANGE: 'EMAIL_CHANGE',
		PLATFORM_LOGIN: 'PLATFORM_LOGIN',
		PLATFORM_ENROLLMENT: 'PLATFORM_ENROLLMENT',
		KYC_APPROVED: 'KYC_APPROVED',
		KYC_REJECTED: 'KYC_REJECTED',
		TFA_SETTINGS: 'TFA_SETTINGS',
		RESIDENTIAL_INFO_CHANGE: 'RESIDENTIAL_INFO_CHANGE',
	};

	const typeIcon = {
		[types.LOGIN]: NotLogin,
		[types.REFERRAL]: NotReferral,
		[types.NEW_DEVICE]: NotDevice,
		[types.SECURITY_CODE]: NotPassword,
		[types.PASSWORD_CHANGE]: NotPassword,
		[types.EMAIL_CHANGE]: NotEmail,
		[types.PLATFORM_LOGIN]: NotLogin,
		[types.PLATFORM_ENROLLMENT]: NotEnroll,
		[types.KYC_APPROVED]: NotKyc,
		[types.KYC_REJECTED]: NotReject,
		[types.TFA_SETTINGS]: NotSettings,
		[types.RESIDENTIAL_INFO_CHANGE]: NotUpdate,
	};

	const typeTexts = {
		[types.LOGIN]: texts?.login,
		[types.REFERRAL]: texts?.referral,
		[types.NEW_DEVICE]: texts?.device,
		[types.SECURITY_CODE]: texts?.securityCode,
		[types.PASSWORD_CHANGE]: texts?.password,
		[types.EMAIL_CHANGE]: texts?.email,
		[types.PLATFORM_LOGIN]: texts?.platformLogin,
		[types.PLATFORM_ENROLLMENT]: texts?.enroll,
		[types.KYC_APPROVED]: texts?.kycApporved,
		[types.KYC_REJECTED]: texts?.kycRejected,
		[types.TFA_SETTINGS]: texts?.tfa,
		[types.RESIDENTIAL_INFO_CHANGE]: texts?.residentialInfo,
	};

	const notificationTypes = (type) => {
		const theType = typeIcon[type];
		if (theType) return theType;
		else return type;
	};

	const notificationTypesTexts = (type) => {
		const theType = typeTexts[type];
		if (theType) return theType;
		else return type;
	};

	const notificationTime = (time) => {
		const date = new Date(time);
		const now = new Date();
		const diff = now - date;
		const seconds = Math.floor(diff / 1000);
		const minutes = Math.floor(seconds / 60);
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);

		if (days > 0) return `${days} ${texts?.dayAgo}`;
		else if (hours > 0) return `${hours} ${texts?.hourAgo}`;
		else if (minutes > 0) return `${minutes} ${texts?.minuteAgo}`;
		else if (seconds > 0) return `${seconds} ${texts?.secondAgo}`;
		else return texts?.now;
	};

	return { notificationTypes, notificationTypesTexts, notificationTime };
};

export default useTypeNotification;
