import React, { useEffect } from "react";

// Components
import Checkbox from "../../../../../components/advanced/checkbox/Checkbox";
import ErrorModal from "../../../../../components/advanced/errorModal/ErrorModal";

// Libraries
import useForm from "@hybris-software/use-ful-form";
import useQuery from "@hybris-software/use-query";
import { useOutletContext } from "react-router-dom";

// Hooks
import useText from "../../../../../hooks/useText";

// Data
import endpoints from "../../../../../data/endpoints";

// Styles
import Style from "./Notification.module.css";

const Notification = ({ response }) => {
  // Hooks
  const texts = useText("notificationView")?.notify;
  const { errorModalRef, unauthorizedModalRef } = useOutletContext();

  const form = useForm({
    inputs: {
      newsEmail: {
        nature: "checkbox",
        value: response?.newsEmail,
      },
      newsBrowser: {
        nature: "checkbox",
        value: response?.newsBrowser,
      },
      accountActivityEmail: {
        nature: "checkbox",
        value: response?.accountActivityEmail,
      },
      accountActivityBrowser: {
        nature: "checkbox",
        value: response?.accountActivityBrowser,
      },
      loginActivityEmail: {
        nature: "checkbox",
        // value: response?.loginActivityEmail,
        value: true,
        sendToApi: false,
      },
      loginActivityBrowser: {
        nature: "checkbox",
        value: response?.loginActivityBrowser,
      },
    },
  });

  const updateSettingsApi = useQuery({
    url: endpoints.profile.NOTIFICATIONSETTINGS,
    method: "PATCH",
    onError: (error) => {
      errorModalRef.current.updateBody(
        <ErrorModal error={error} modalRef={errorModalRef} />
      );
    },
    onUnauthorized: () => {
      unauthorizedModalRef.current.open();
    },
  });

  useEffect(() => {
    updateSettingsApi.executeQuery(form.getApiBody());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values]);

  return (
    <div className={Style.notification}>
      <div className={Style.notificationSetting}>
        <h5>{texts?.title}</h5>
        <p className={Style.subtitle}>{texts?.subtitle}</p>

        <div className={Style.tableList}>
          <div className={Style.line}>
            <div className={Style.label}>
              <div className={Style.lineTitle}>{texts?.typeNews}</div>
              <p className={Style.subtitle}>
                {texts?.newsForYouSub}
              </p>
            </div>
            <div className={Style.selection}>
              <div className={Style.check}>
                {texts?.labelEmail}
                <Checkbox
                  checked={form.values["newsEmail"]}
                  onChange={(e) =>
                    form?.getInputProps("newsEmail").setValue(e.target.checked)
                  }
                />
              </div>
              <div className={Style.check}>
                {texts?.labelBrowser}
                <Checkbox
                  checked={form.values["newsBrowser"]}
                  onChange={(e) =>
                    form
                      ?.getInputProps("newsBrowser")
                      .setValue(e.target.checked)
                  }
                />
              </div>
            </div>
          </div>

          <div className={Style.line}>
            <div className={Style.label}>
              <div className={Style.lineTitle}>{texts?.typeActiviry}</div>
              <p className={Style.subtitle}>
              {texts?.accountActivity}
              </p>
            </div>

            <div className={Style.selection}>
              <div className={Style.check}>
                {texts?.labelEmail}
                <Checkbox
                  checked={form.values["accountActivityEmail"]}
                  onChange={(e) =>
                    form
                      ?.getInputProps("accountActivityEmail")
                      .setValue(e.target.checked)
                  }
                />
              </div>
              <div className={Style.check}>
                {texts?.labelBrowser}
                <Checkbox
                  checked={form.values["accountActivityBrowser"]}
                  onChange={(e) =>
                    form
                      ?.getInputProps("accountActivityBrowser")
                      .setValue(e.target.checked)
                  }
                />
              </div>
            </div>
          </div>

          <div className={Style.line}>
            <div className={Style.label}>
              <div className={Style.lineTitle}>{texts?.typeDevices}</div>
              <p className={Style.subtitle}>
                {texts?.LoginActivities}
              </p>
            </div>

            <div className={Style.selection}>
              <div className={Style.check} style={{ opacity: 0.4 }}>
                {texts?.labelEmail}
                <Checkbox
                  checked={form.values["loginActivityEmail"]}
                  onChange={() => {}}
                  // onChange={(e) => form?.getInputProps("loginActivityEmail").setValue(e.target.checked)}
                />
              </div>
              <div className={Style.check}>
                {texts?.labelBrowser}
                <Checkbox
                  checked={form.values["loginActivityBrowser"]}
                  onChange={(e) =>
                    form
                      ?.getInputProps("loginActivityBrowser")
                      .setValue(e.target.checked)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
