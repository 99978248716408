import React, { useState } from 'react';

// Components
import DoubleTwoFactor from '../../../../../components/advanced/doubleTwoFactor/DoubleTwoFactor';
import ErrorModal from '../../../../../components/advanced/errorModal/ErrorModal';

// Libraries
import useForm from '@hybris-software/use-ful-form';
import { useUser } from '@hybris-software/use-auth';
import { InputField, Button } from '@hybris-software/ui-kit';
import useQuery from '@hybris-software/use-query';
import { useOutletContext } from 'react-router-dom';

// Icons
import { FiEdit3 } from 'react-icons/fi';

// Hooks
import useText from '../../../../../hooks/useText';

// Data
import endpoints from '../../../../../data/endpoints';

// Utils
import updateFormError from '../../../../../utils/updateFormError';

// Styles
import Style from './Email.module.css';

const Email = ({ modalRef }) => {
	// Hooks
	const texts = useText('emailView')?.changeEmail;
	const inputTexts = useText('inputs');
	const { userInfo } = useUser();
	// Contexts
	const { errorModalRef, unauthorizedModalRef } = useOutletContext();

	const [editable, setEditable] = useState(false);

	const changeEmail = useQuery({
		url: endpoints.profile.CHANGEEMAIL,
		method: 'POST',
		onSuccess: (response) => {
			modalRef?.current?.updateBody(
				<DoubleTwoFactor
					otpMethod={response?.data?.otpMethod}
					errorCall='changeEmail'
					modalRef={modalRef}
					newEmail={form.values['email']}
					setEditable={setEditable}
					onClose={() => {
						form.reset();
					}}
				/>,
				{
					onCloseIconClick: () => {
						form.getInputProps('email').setValue(
							userInfo?.email || '_'
						);
					},
				}
			);
		},
		onError: (error) => {
			if (error.response?.status === 422) {
				updateFormError(error.response?.data, form);
			} else {
				errorModalRef.current.updateBody(
					<ErrorModal error={error} modalRef={errorModalRef} />
				);
			}
		},
		onUnauthorized: () => {
			unauthorizedModalRef.current.open();
		},
	});

	const form = useForm({
		inputs: {
			email: {
				nature: 'email',
				errorOnEveryChange: true,
				validator: (value) => {
					const re =
						//eslint-disable-next-line
						/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					if (!value || value === '')
						return [false, inputTexts?.mandatory];
					if (!re.test(String(value).toLowerCase()))
						return [false, inputTexts?.invalidEmail];
					return [true, ''];
				},
			},
		},
	});

	return (
		<div className={Style.email}>
			<div className={Style.emailChange}>
				<div className={Style.editable}>
					<div className={Style.intro}>
						<h5>{texts?.title}</h5>
					</div>
					{!editable && (
						<div
							className={Style.edit}
							onClick={() => setEditable(!editable)}
						>
							{texts?.editButton} <FiEdit3 />
						</div>
					)}
				</div>
				<p className={Style.subtitle}>{texts?.subtitle}</p>
				<div className={editable ? Style.gridAddress : Style.grid}>
					<InputField
						className={editable ? Style.input : Style.inputEditable}
						label={texts?.labelEmail}
						placeholder={texts?.placeholderEmail}
						readOnly={!editable}
						showError={editable}
						{...form.getInputProps('email')}
					/>
				</div>
				{editable && (
					<div className={Style.buttons}>
						<Button
							className={Style.save}
							disabled={!form.isValid()}
							isLoading={changeEmail.isLoading}
							onClick={() => {
								changeEmail.executeQuery(form.getApiBody());
							}}
						>
							{texts?.saveButton}
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

export default Email;
