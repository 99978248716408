import React, { useContext, useRef, useState, useEffect } from "react";

// Components
import ErrorBanner from "../errorBanner/ErrorBanner";
import ErrorModal from "../errorModal/ErrorModal";
import SendCodeAgain from "../sendCodeAgain/SendCodeAgain";

// Libraries
import { InputField, Button, MagicModal } from "@hybris-software/ui-kit";
import useForm from "@hybris-software/use-ful-form";
import useQuery from "@hybris-software/use-query";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import AuthCode from "react-auth-code-input";
import { useNavigate } from "react-router-dom";

// Icons
import { FiAlertTriangle } from "react-icons/fi";

// Hooks
import useText from "../../../hooks/useText";

// Contexts
import { LanguageContext } from "../../../contexts/LanguageContext";
import { RoutesContext } from "../../../contexts/RoutesContext";

// Data
import endpoints from "../../../data/endpoints";
import config from "../../../data/config";

// Utils
import updateFormError from "../../../utils/updateFormError";

// Styles
import Style from "./UnauthorizedModal.module.css";

const UnauthorizedModal = ({ errorModalRef }) => {
  // Hooks
  const texts = useText("loginView");
  const inputTexts = useText("inputs");
  const textsFactor = useText("twoFactorView");
  const navigate = useNavigate();
  // Ref
  const captchaRef = useRef(null);
  const sessionExpiredModalRef = useRef();
  // Contexts
  const { language } = useContext(LanguageContext);
  const { paths } = useContext(RoutesContext);
  // Variables
  const [twofact, setTwofact] = useState(false);
  const otpMethod = localStorage.getItem("otpMethod");
  const [code, setCode] = useState("");

  // Form
  const form = useForm({
    inputs: {
      username: {
        validator: (value) => {
          if (!value || value === "") return [false, inputTexts?.mandatory];
          return [true, ""];
        },
      },
      password: {
        validator: (value) => {
          if (!value || value === "") return [false, inputTexts?.mandatory];
          return [true, ""];
        },
      },
      captchaToken: {
        required: true,
      },
      rememberMe: {
        value: localStorage.getItem("username") ? true : false,
        nature: "checkbox",
        sendToApi: false,
      },
    },
  });

  const loginApi = useQuery({
    url: endpoints.auth.LOGIN,
    method: "POST",
    onSuccess: (response) => {
      localStorage.setItem("loginToken", response.data.loginToken);
      localStorage.setItem("otpMethod", response.data.otpMethod);
      localStorage.setItem("email", response.data.email);

      form.values["rememberMe"]
        ? localStorage.setItem("username", form.values["username"])
        : localStorage.removeItem("username");

      setTwofact(true);
    },
    onError: (error) => {
      captchaRef.current.resetCaptcha();

      if (error.response.status === 422) {
        updateFormError(error.response.data, form);
      } else if (
        error.response?.status === 400 &&
        error.response?.data?.code === "FEATURE_DISABLED"
      ) {
        navigate(paths.generic.maintenance);
      } else if (
        error.response?.status !== 400 &&
        error.response?.status !== 422 &&
        error.response?.status !== 401
      ) {
        errorModalRef.current.updateBody(
          <ErrorModal error={error} modalRef={errorModalRef} />
        );
      }
    },
    onUnauthorized: () => {
      captchaRef.current.resetCaptcha();
    },
  });

  const confirmLoginApi = useQuery({
    url: endpoints.auth.LOGINCONFIRM,
    method: "POST",
    onSuccess: (response) => {
      localStorage.setItem("token", response.data.token);
      localStorage.removeItem("loginToken");
      localStorage.removeItem("otpMethod");
      localStorage.removeItem("email");
      window.location.reload();
    },
    onError: (error) => {
      if (error.response.status === 422 && error.response.data.token) {
        sessionExpiredModalRef.current.open();
      } else if (
        error.response?.status !== 400 &&
        error.response?.status !== 422 &&
        error.response?.status !== 401
      ) {
        errorModalRef.current.updateBody(
          <ErrorModal error={error} modalRef={errorModalRef} />
        );
      }
    },
  });

  const resendOtpApi = useQuery({
    url: endpoints.auth.RESENDOTP,
    method: "POST",
    executeImmediately: false,
    onError: (error) => {
      if (error.response.status === 422 && error.response.data.loginToken) {
        sessionExpiredModalRef.current.open();
      }
    },
  });

  useEffect(() => {
    if (code.length === 6)
      confirmLoginApi.executeQuery({
        loginToken: localStorage.getItem("loginToken"),
        otp: code,
      });
    //eslint-disable-next-line
  }, [code]);

  return twofact ? (
    <div className={Style.card}>
      <MagicModal
        ref={sessionExpiredModalRef}
        destroyBodyOnClose={false}
        showCloseIcon={false}
        body={
          <div className={Style.expired}>
            <h5>{textsFactor?.titleExpired}</h5>
            <p>{textsFactor?.subtitleExpired}</p>
            <Button
              className={Style.buttonLogin}
              onClick={() => {
                localStorage.removeItem("loginToken");
                navigate(paths.auth.login);
              }}
            >
              {textsFactor?.expiredButton}
            </Button>
          </div>
        }
      />

      <h5 className={Style.title}>{textsFactor?.title}</h5>
      <div className={Style.reminder}>
        {otpMethod && otpMethod === "EMAIL" ? (
          <>
            {textsFactor?.subtitleEmail} {localStorage.getItem("email")}
          </>
        ) : (
          <>{textsFactor?.subtitleAuthenticator}</>
        )}
      </div>

      <ErrorBanner
        response={confirmLoginApi.error?.response}
        call="loginConfirm"
      />

      {confirmLoginApi.isError &&
        confirmLoginApi.error?.response?.status === 422 &&
        !confirmLoginApi.error?.response?.data?.token && (
          <div className={Style.errorBox}>
            {confirmLoginApi.error?.response?.data?.otp ||
              confirmLoginApi.error?.response?.data?.message}
          </div>
        )}

      {confirmLoginApi.isError &&
        confirmLoginApi.error?.response?.status === 401 && (
          <div className={Style.errorBox}>
            {confirmLoginApi.error?.response?.data?.message}
          </div>
        )}

      <div className={Style.form}>
        <AuthCode
          allowedCharacters="numeric"
          onChange={(e) => {
            setCode(e);
          }}
        />
      </div>

      <Button
        className={Style.button}
        disabled={code?.length !== 6}
        isLoading={confirmLoginApi.isLoading}
        onClick={() => {
          if (code.length === 6)
            confirmLoginApi.executeQuery({
              loginToken: localStorage.getItem("loginToken"),
              otp: code,
            });
        }}
      >
        {textsFactor?.button}
      </Button>

      {otpMethod === "EMAIL" && (
        <SendCodeAgain
          counter={15}
          onClick={() =>
            resendOtpApi.executeQuery({
              loginToken: localStorage.getItem("loginToken"),
            })
          }
        />
      )}
    </div>
  ) : (
    <div className={Style.card}>
      <h5 className={Style.title}>{texts?.title}</h5>

      {/* {platformConfigApi.response?.data?.loginEnabled === false &&
                <div className={Style.errorBox}>
                    {texts?.loginDisabled}
                </div>
            } */}
      <p className={Style.smallText}>{texts.sessionExpire}</p>

      <ErrorBanner response={loginApi.error?.response} call="login" />
      <InputField
        label={texts?.labelEmail}
        placeholder={texts?.placeholderEmail}
        errorIconVisibility
        errorIcon={<FiAlertTriangle color="var(--error)" />}
        {...form.getInputProps("username")}
      />

      <InputField
        label={texts?.labelPassword}
        placeholder={texts?.placeholderPassword}
        type="password"
        showPasswordIconVisibility
        {...form.getInputProps("password")}
      />

      <div className={Style.remember}>
        <input
          type="checkbox"
          id="remember"
          checked={form.values["rememberMe"]}
          onChange={(e) =>
            form.getInputProps("rememberMe").setValue(e.target.checked)
          }
        />
        <label htmlFor="remember">{texts?.rememberLabel}</label>
      </div>

      <div className={Style.captcha}>
        <HCaptcha
          sitekey={config.CAPTCHA_SITE_KEY}
          languageOverride={language.toLowerCase() || "en"}
          onVerify={(value) => {
            form.getInputProps("captchaToken").setValue(value);
          }}
          onExpire={() => form.getInputProps("captchaToken").setValue(null)}
          ref={captchaRef}
        />
      </div>

      <Button
        className={Style.button}
        disabled={!form.isValid()}
        isLoading={loginApi.isLoading}
        onClick={() => {
          loginApi.executeQuery(form.getApiBody());
          form.getInputProps("captchaToken").setValue(null);
        }}
      >
        {texts?.button}
      </Button>
    </div>
  );
};

export default UnauthorizedModal;
